import { State } from 'redux/rootReducer'

export const professionOptionsSelector = (state: State) => {
  const { ids, list } = state.userManagement.inviteNewUser.professions

  return ids.map((id) => ({
    value: id,
    label: list[id].name
  }))
}
