import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd'

import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { API, api } from 'services/api'

import * as actionTypes from './adminManagement.actionTypes'
import {
  addAdminRequest,
  addAdminError,
  addAdminSuccess,
  resetAdminFormAction
} from './adminManagement.actions'

function* addAdminSaga({ payload }: ReturnType<typeof addAdminRequest>) {
  try {
    yield call(api.post, API.ADD_ADMIN, payload)

    yield put(addAdminSuccess())
    yield put(resetAdminFormAction({ resetForm: true }))
    yield call(message.success, 'Admin added!')

    yield delay(DEFAULT_THROTTLE_MS)
    yield put(resetAdminFormAction({ resetForm: false }))
  } catch (e: any) {
    yield put(addAdminError(e))
  }
}

export function* adminManagementSaga() {
  yield takeLatest(actionTypes.ADD_ADMIN_REQUEST, addAdminSaga)
}
