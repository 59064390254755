import React, { useCallback } from 'react'

import { IEntityDTO } from 'interfaces/common'

import { TGenerateColumnsProps } from '../utils'

import { View } from './View'
import { Editable } from './Editable'

type TCell = Omit<TGenerateColumnsProps, 'editingTableMode' | 'handleVisibilityChange'> & {
  record: IEntityDTO
}

export type THandleSaveClick = (values: Record<keyof IEntityDTO, string>) => void

export const Cell = ({
  dataSource,
  record,
  editedCellOrderNumber,
  setEditedCellOrderNumber,
  handleDelete,
  handleSave
}: TCell) => {
  const { id, name, isHidden, orderNumber } = record
  const isEditing = editedCellOrderNumber === orderNumber

  const handleEditingButtonClick = () => {
    setEditedCellOrderNumber(orderNumber)
  }

  const handleSaveClick = useCallback<THandleSaveClick>(
    (values) => {
      const newValue = values.name.trim()

      if (newValue) {
        handleSave({ id, orderNumber, isHidden, name: newValue })
      }
    },
    [id, isHidden, handleSave, orderNumber]
  )

  const handleEditCancelButtonState = () => {
    if (!name) {
      handleDelete(orderNumber)
    }

    setEditedCellOrderNumber(null)
  }

  const handleDeleteItem = () => {
    handleDelete(orderNumber)
  }

  return (
    <>
      {isEditing ? (
        <Editable
          name={name}
          order={orderNumber}
          dataSource={dataSource}
          onSave={handleSaveClick}
          onCancel={handleEditCancelButtonState}
        />
      ) : (
        <View
          id={id}
          name={name}
          disable={!!editedCellOrderNumber}
          onDelete={handleDeleteItem}
          onEdit={handleEditingButtonClick}
        />
      )}
    </>
  )
}
