import * as actionTypes from './professionList.actionTypes'
import { TProfessionListState, TProfessionListAction } from './professionList.types'

const initialState: TProfessionListState = {
  list: []
}

export const defaultProfessionListReducer = (
  state = initialState,
  action: TProfessionListAction
) => {
  switch (action.type) {
    case actionTypes.GET_PROFESSION_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.RESET_PROFESSION_LIST: {
      return initialState
    }

    default:
      return state
  }
}
