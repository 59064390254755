import { PAGINATION_DEFAULT } from 'globalConstants'
import { TMDTBalanceAction, TMDTBalanceState } from 'store'

import * as actionTypes from './mdtBalance.actionTypes'

export const initialMDTBalanceState: TMDTBalanceState = {
  airdrop: {
    history: {
      ids: [],
      list: {},
      filters: {
        ...PAGINATION_DEFAULT,
        from: '',
        to: ''
      }
    },
    selectedRowIds: []
  },
  balance: {
    details: {
      platformBalance: 0,
      usersBalance: 0,
      total: 0
    },
    history: {
      ids: [],
      list: {},
      filters: {
        ...PAGINATION_DEFAULT,
        from: '',
        to: ''
      }
    },
    totalExpense: 0,
    totalIncome: 0
  }
}

export const mdtBalanceReducer = (
  state: TMDTBalanceState = initialMDTBalanceState,
  action: TMDTBalanceAction
): TMDTBalanceState => {
  switch (action.type) {
    case actionTypes.GET_MDT_BALANCE_DETAILS_SUCCESS: {
      return {
        ...state,
        balance: {
          ...state.balance,
          details: action.payload
        }
      }
    }

    case actionTypes.GET_MDT_BALANCE_HISTORY_SUCCESS: {
      const { ids, list, filters, totalExpense, totalIncome } = action.payload
      return {
        ...state,
        balance: {
          ...state.balance,
          history: {
            filters,
            ids,
            list
          },
          totalExpense,
          totalIncome
        }
      }
    }

    case actionTypes.GET_MDT_AIRDROP_HISTORY_SUCCESS: {
      const { ids, list, filters } = action.payload
      return {
        ...state,
        airdrop: {
          ...state.airdrop,
          history: {
            filters,
            ids,
            list
          }
        }
      }
    }

    default:
      return state
  }
}
