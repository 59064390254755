// TODO --- ADD i18 lib
export const messages = {
  required: 'Required',
  email: 'Invalid email address',
  password:
    'Latin alphabet (A-z) \nwhole positive numbers (0-9) \nspecial characters <>~!@#$%^&*()+=_-{}',
  confirmPassword: 'Passwords mismatch',
  name: `Incorrect! min 3 max 32 ( - ' )`,
  minText: 'Should be greater or equal to',
  maxText: 'Should be less or equal to',
  min: 'Should be greater or equal to',
  max: 'Should be less or equal to',
  natural: 'Should be natural',
  minDate: 'Should be later than',
  maxDate: 'Should be earlier than',
  twoFACode: 'Code only 6 digits',
  isNumber: 'Must be a number',
  link: 'Invalid link',
  onlyDigits: 'Only digits',
  price: 'Invalid price',
  zip: 'Invalid Zip',
  onlySpaces: 'Required',
  companyName: 'Invalid company name'
}
