import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { EditableEnvConfig } from '@medentee/enums'

import { useAppSelector } from 'App'
import { getIntegerFormValue } from 'utils/form'
import { DefaultFormCard } from 'App/common'
import { UPDATE_DEFAULT_VARIABLES } from 'store'

import { TCommonDefaultVariablesProps } from '../../'

export const DefaultVariablesUserAccounts = ({ onFormSubmit }: TCommonDefaultVariablesProps) => {
  const defaultVariables = useAppSelector((state) => state.defaultVariables)

  return (
    <DefaultFormCard
      title="User Accounts"
      onFormSubmit={onFormSubmit}
      loadingActionType={UPDATE_DEFAULT_VARIABLES}
      initialValues={{
        [EditableEnvConfig.INVITE_EXPIRY]: defaultVariables[EditableEnvConfig.INVITE_EXPIRY]?.value,
        [EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]:
          defaultVariables[EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]?.value,
        [EditableEnvConfig.SESSION_EXPIRY]:
          defaultVariables[EditableEnvConfig.SESSION_EXPIRY]?.value,
        [EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]:
          defaultVariables[EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]?.value
      }}
    >
      {(disabled) => (
        <Row gutter={[20, 0]} align="bottom">
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.INVITE_EXPIRY}
              label="TTL of invitation link to platform (in email), hours"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.INVITE_EXPIRY]?.min,
                  max: defaultVariables[EditableEnvConfig.INVITE_EXPIRY]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.INVITE_EXPIRY]?.min
                  } and ${defaultVariables[EditableEnvConfig.INVITE_EXPIRY]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.CHANGE_PASSWORD_EXPIRY}
              label="TTL of reset password link (in email), hours"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]?.min,
                  max: defaultVariables[EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]?.min
                  } and ${defaultVariables[EditableEnvConfig.CHANGE_PASSWORD_EXPIRY]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.SESSION_EXPIRY}
              label="TTL of user’s session (auto-logout), min"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.SESSION_EXPIRY]?.min,
                  max: defaultVariables[EditableEnvConfig.SESSION_EXPIRY]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.SESSION_EXPIRY]?.min
                  } and ${defaultVariables[EditableEnvConfig.SESSION_EXPIRY]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.MAX_COUNT_OF_CATEGORIES}
              label="Quantity of categories for medical provider, max"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]?.min,
                  max: defaultVariables[EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]?.min
                  } and ${defaultVariables[EditableEnvConfig.MAX_COUNT_OF_CATEGORIES]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
