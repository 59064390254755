import { IMDTAirdropHistoryItemDTO } from 'interfaces/api/mdtBalance'

export const LastOperation: TAnt.TableColumnRender<
  IMDTAirdropHistoryItemDTO['lastOperation'],
  IMDTAirdropHistoryItemDTO
> = (lastOperation, { id }) => {
  if (!lastOperation?.amount) {
    return ''
  }

  const { amount, fromAccountId } = lastOperation
  const sing = fromAccountId === id ? '-' : '+'

  return <>{`${amount > 0 ? sing : ''}${amount} MDT`}</>
}
