import { useCallback, useEffect } from 'react'
import { Steps } from 'antd'
import { Form } from 'react-final-form'
import { AccountTypeIdEnum } from '@medentee/enums'

import { setUserExistsError, inviteNewUserRequest, TInviteNewUserRequestPayload } from 'store'
import { Modal, useAppDispatch, useSteps } from 'App'

import { FirstStep } from './components/FirstStep'
import { SecondStep } from './components/SecondStep'
import { Footer } from './components/Footer'
import styles from './InviteNewUserDIalog.module.scss'

const MAX_STEP = 1
const { Step } = Steps

export type TInviteNewUserDialogValues = TInviteNewUserRequestPayload

export const InviteNewUserDialog = () => {
  const dispatch = useAppDispatch()
  const { step, onNext, onBack } = useSteps(MAX_STEP)

  const handleSubmit = useCallback(
    (values: TInviteNewUserDialogValues) => {
      if (step === 0) {
        onNext()
      }

      if (step === 1) {
        dispatch(inviteNewUserRequest(values))
      }
    },
    [dispatch, onNext, step]
  )

  useEffect(
    () => () => {
      dispatch(setUserExistsError({ userExistsError: null }))
    },
    [dispatch]
  )

  const initialValues: Partial<TInviteNewUserDialogValues> = {
    generation: 1,
    accountTypeId: AccountTypeIdEnum.PROFESSIONAL
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {() => (
        <Modal title="Invite" footer={<Footer onBack={onBack} step={step} maxStep={MAX_STEP} />}>
          <Steps current={step} className={styles.steps}>
            <Step title="Step 1" description="Please, select the type of user." />
            <Step title="Step 2" description="Additional information" />
          </Steps>

          {step === 0 && <FirstStep />}
          {step === 1 && <SecondStep />}
        </Modal>
      )}
    </Form>
  )
}
