import { useEffect, useRef } from 'react'
import { Modal, ModalFuncProps } from 'antd'

import { useEvent } from 'App/hooks'

type ConfigUpdate = ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps)

type TConfirmationModalProps = ModalFuncProps

export const ConfirmationModal = (props: TConfirmationModalProps) => {
  const { okText = 'Yes', cancelText = 'No', ...rest } = props
  const [modal, contextHolder] = Modal.useModal()
  const updateState = useRef<(configUpdate: ConfigUpdate) => void | undefined>()

  const displayModal = useEvent(() => {
    const { update } = modal.confirm({
      okText,
      cancelText,
      ...rest
    })

    updateState.current = update
  })

  useEffect(() => {
    if (updateState.current) {
      updateState.current({
        okText,
        cancelText,
        ...rest
      })
    }
  }, [okText, cancelText, updateState, rest])

  useEffect(() => {
    displayModal()
  }, [displayModal])

  useEffect(
    () => () => {
      Modal.destroyAll()
    },
    []
  )

  return contextHolder
}
