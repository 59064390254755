import React from 'react'
import { Switch, SwitchProps } from 'antd'

import { IEntityDTO } from 'interfaces/common'

type TVisibilitySwitchProps = Pick<SwitchProps, 'disabled'> & {
  record: IEntityDTO
  handleVisibilityChange: (record: IEntityDTO) => void
}

export const VisibilitySwitch = ({
  record,
  disabled,
  handleVisibilityChange
}: TVisibilitySwitchProps) => {
  const handleToggleItemVisibility = () => {
    handleVisibilityChange(record)
  }

  return (
    <Switch disabled={disabled} checked={!record.isHidden} onChange={handleToggleItemVisibility} />
  )
}
