import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { clearBusinessAccountFilter } from 'store'
import { BusinessAccountsFilter, BusinessAccountsFilterDateRange, BusinessAccountsList } from 'App'

import styles from './BusinessAccounts.module.scss'

export const BusinessAccounts = () => {
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(clearBusinessAccountFilter())
    },
    [dispatch]
  )

  return (
    <div className={styles.root}>
      <BusinessAccountsFilter className={styles.filter} />
      <BusinessAccountsFilterDateRange className={styles.filter} />
      <BusinessAccountsList />
    </div>
  )
}
