import React, { PropsWithChildren } from 'react'
import { Modal as OriginalModal } from 'antd'
import { ModalFuncProps, ModalProps } from 'antd/lib/modal'

import { useModal } from 'App'

type TModalProps = PropsWithChildren<ModalProps & ModalFuncProps>

export const Modal = ({ children, visible = true, ...rest }: TModalProps) => {
  const { hideModal } = useModal()

  return (
    <OriginalModal visible={visible} {...rest} onCancel={hideModal} width={572}>
      {children}
    </OriginalModal>
  )
}
