import { useState } from 'react'
import { Button, Col, InputNumber, message, Row, Typography } from 'antd'

import {
  createLoadingSelector,
  getMDTPlatformBalanceSelector,
  GET_MDT_AIRDROP_HISTORY,
  showModalAction,
  TIds
} from 'store'
import { EModalComponents, useAppDispatch, useAppSelector } from 'App'

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY])

type TInputWrapperProps = { selectedIds: TIds }

export const InputWrapper = ({ selectedIds }: TInputWrapperProps) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const platformBalance = useAppSelector(getMDTPlatformBalanceSelector)
  const [amount, setAmount] = useState<number | undefined | null>()

  const handleClick = () => {
    if ((amount ?? 0) > platformBalance) {
      message.error(`You don't have enough MDT balance`)
      return
    }

    dispatch(
      showModalAction({
        modalProps: { amount: amount ?? 0, selectedIds },
        modalType: EModalComponents.CONFIRM_AIRDROP
      })
    )
  }

  return (
    <Row align="middle" justify="start" gutter={[0, 24]}>
      <Col lg={8} md={6} sm={24} xs={24}>
        <Typography.Text strong>MDT Airdrop:</Typography.Text>
      </Col>

      <Col lg={10} md={10} sm={8} xs={14}>
        <Row align="middle" justify="space-around">
          <Typography.Text>Add MDT</Typography.Text>
          <InputNumber
            value={amount}
            onChange={setAmount}
            style={{ width: 80 }}
            min={0}
            placeholder="0"
          />
        </Row>
      </Col>

      <Col lg={6} md={6} sm={24} xs={24}>
        <Row align="middle" justify="center">
          <Button
            type="primary"
            disabled={!selectedIds.length || !amount || loading}
            onClick={handleClick}
          >
            Apply
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
