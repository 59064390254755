import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Row, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'
import { ColumnsType } from 'antd/lib/table/interface'
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import cls from 'classnames'

import { useDialog } from 'App/hooks'
import { EditSwitcher } from 'App/common'
import { IEntityDTO } from 'interfaces/common'

import { generateColumns } from './utils'
import { Row as SortableRow } from './Row'
import styles from './TableList.module.scss'

export type TOnVisibilityChange = (isHidden: boolean, onConfirm: () => void) => void

type TTableList = {
  title: string
  loading: boolean
  list: IEntityDTO[]
  onSave: (data: IEntityDTO[]) => void
  onVisibilityChange: TOnVisibilityChange
}

const TOUCH_SENSOR_DELAY = 350
const TOUCH_SENSOR_TOLERANCE = 200

export const TableList = ({ title, list, loading, onSave, onVisibilityChange }: TTableList) => {
  const [dataSource, setDataSource] = useState<IEntityDTO[]>([])

  const { state: editingTableMode, toggle: toggleEditingTableMode } = useDialog()
  const [editedCellOrderNumber, setEditedCellOrderNumber] = useState<number | null>(null)

  const draggable = editingTableMode && !editedCellOrderNumber

  const disabledSave = useMemo(
    () => isEqual(dataSource, list) || !!editedCellOrderNumber,
    [dataSource, list, editedCellOrderNumber]
  )

  const sortableItems = useMemo(
    () =>
      dataSource.reduce(
        (acc, item) => [...acc, { id: item.orderNumber }],
        [] as {
          id: number
        }[]
      ),
    [dataSource]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: TOUCH_SENSOR_DELAY,
        tolerance: TOUCH_SENSOR_TOLERANCE
      }
    })
  )

  useEffect(() => {
    if (!loading && list.length) {
      setDataSource(list)
    }
  }, [list, loading])

  const handleSave = useCallback(
    (row: IEntityDTO) => {
      const newData = dataSource.map((item) =>
        row.orderNumber === item.orderNumber
          ? {
              ...item,
              ...row
            }
          : item
      )

      setDataSource(newData)
      setEditedCellOrderNumber(null)
    },
    [dataSource]
  )

  const handleDelete = useCallback(
    (orderNumber: number) => {
      const newData = dataSource
        .filter((item) => item.orderNumber !== orderNumber)
        .map((item, index) => ({ ...item, orderNumber: index + 1 }))

      setDataSource(newData)
    },
    [dataSource]
  )

  const handleAddItem = useCallback(() => {
    const newOrder = dataSource[dataSource.length - 1].orderNumber + 1

    setDataSource([
      ...dataSource,
      {
        orderNumber: newOrder,
        name: '',
        isHidden: false
      }
    ])

    setEditedCellOrderNumber(newOrder)
  }, [dataSource])

  const handleEditSwitch = useCallback(() => {
    if (!disabledSave || editedCellOrderNumber) {
      setDataSource(list)
      setEditedCellOrderNumber(null)
    }

    toggleEditingTableMode()
  }, [disabledSave, editedCellOrderNumber, list, toggleEditingTableMode])

  const handleTableSave = useCallback(() => {
    onSave(dataSource)
    toggleEditingTableMode()
  }, [dataSource, onSave, toggleEditingTableMode])

  const handleVisibilityChange = useCallback(
    (record: IEntityDTO) => {
      const callback = () => {
        setDataSource(
          dataSource.map((item) =>
            item.orderNumber === record.orderNumber ? { ...item, isHidden: !record.isHidden } : item
          )
        )
      }

      onVisibilityChange(record.isHidden, callback)
    },
    [dataSource, onVisibilityChange]
  )

  const columns: ColumnsType<IEntityDTO> = generateColumns({
    dataSource,
    editingTableMode,
    editedCellOrderNumber,
    setEditedCellOrderNumber,
    handleDelete,
    handleSave,
    handleVisibilityChange
  })

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = dataSource.findIndex(({ orderNumber }) => orderNumber === active.id)
        const newIndex = dataSource.findIndex(({ orderNumber }) => orderNumber === over?.id)

        const newData = arrayMove(dataSource.slice(), oldIndex, newIndex).map((item, index) => ({
          ...item,
          orderNumber: index + 1
        }))

        setDataSource(newData)
      }
    },
    [dataSource]
  )

  return (
    <Card
      title={title}
      extra={<EditSwitcher editing={editingTableMode} onClick={handleEditSwitch} />}
    >
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          disabled={!draggable}
          items={sortableItems}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 'max-content' }}
            components={{
              body: {
                row: SortableRow
              }
            }}
            pagination={false}
            rowKey={({ orderNumber }) => orderNumber}
            rowClassName={({ isHidden }) =>
              cls(styles.row, {
                [styles.hidden]: isHidden,
                [styles.draggable]: draggable
              })
            }
          />
        </SortableContext>
      </DndContext>
      {editingTableMode && (
        <>
          {!editedCellOrderNumber && (
            <Row className={styles.buttonAdd}>
              <Button size="small" type="primary" onClick={handleAddItem} icon={<PlusOutlined />}>
                Add item
              </Button>
            </Row>
          )}
          <Row className={styles.buttonWrapper}>
            <Button
              className={styles.buttonSubmit}
              size="large"
              type="primary"
              disabled={disabledSave}
              onClick={handleTableSave}
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </Card>
  )
}
