import { AccountTypeIdEnum, SubscriptionIdsEnum } from '@medentee/enums'
import { Col, Row, Select, Typography } from 'antd'
import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { useAppDispatch, useAppSelector, useEvent } from 'App'
import { SUBSCRIPTION_NAME_MAP } from 'globalConstants'
import {
  createLoadingSelector,
  getMDTAirdropAccountTypesSelector,
  getMDTAirdropHistoryRequest,
  getMDTAirdropSubscriptionSelector,
  GET_MDT_AIRDROP_HISTORY
} from 'store'
import { getMapComponent } from 'utils'

const subscriptionsBusinessOptions = [
  {
    label: getMapComponent(SUBSCRIPTION_NAME_MAP, SubscriptionIdsEnum.BASIC_BUSINESS),
    value: SubscriptionIdsEnum.BASIC_BUSINESS
  },
  {
    label: getMapComponent(SUBSCRIPTION_NAME_MAP, SubscriptionIdsEnum.BUSINESS),
    value: SubscriptionIdsEnum.BUSINESS
  }
]

const subscriptionsProfessionalOptions = [
  {
    label: getMapComponent(SUBSCRIPTION_NAME_MAP, SubscriptionIdsEnum.BASIC_PROFESSIONAL),
    value: SubscriptionIdsEnum.BASIC_PROFESSIONAL
  },
  {
    label: getMapComponent(SUBSCRIPTION_NAME_MAP, SubscriptionIdsEnum.PROFESSIONAL),
    value: SubscriptionIdsEnum.PROFESSIONAL
  }
]

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY])

export const SubscriptionsSelect = () => {
  const dispatch = useAppDispatch()
  const accountTypes = useAppSelector(getMDTAirdropAccountTypesSelector, isEqual)
  const subscription = useAppSelector(getMDTAirdropSubscriptionSelector)
  const loading = useAppSelector(loadingSelector)

  const isBusiness = accountTypes.length > 0 && accountTypes.includes(AccountTypeIdEnum.BUSINESS)

  const isBusinessWithProfessional =
    accountTypes.length > 1 &&
    accountTypes.includes(AccountTypeIdEnum.BUSINESS) &&
    accountTypes.includes(AccountTypeIdEnum.PROFESSIONAL)

  const subscriptionsSelectDisabled = !accountTypes.length || isBusinessWithProfessional

  const handleSubscription = useEvent((value = null) => {
    dispatch(getMDTAirdropHistoryRequest({ current: 0, subscription: value }))
  })

  useEffect(() => {
    if (subscription && subscriptionsSelectDisabled) {
      handleSubscription(null)
    }
  }, [handleSubscription, subscription, subscriptionsSelectDisabled])

  return (
    <Row align="middle" justify="start" gutter={[0, 16]}>
      <Col lg={10} md={10} sm={10} xs={24}>
        <Typography.Text>Subscription plan:</Typography.Text>
      </Col>

      <Col lg={14} md={14} sm={14} xs={24}>
        <Select
          style={{ width: '100%' }}
          placeholder="Please select"
          disabled={subscriptionsSelectDisabled || loading}
          value={subscription}
          onChange={handleSubscription}
          options={isBusiness ? subscriptionsBusinessOptions : subscriptionsProfessionalOptions}
          allowClear={true}
        />
      </Col>
    </Row>
  )
}
