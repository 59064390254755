import moment from 'moment'

import { IMDTAirdropHistoryItemDTO } from 'interfaces/api/mdtBalance'
import { longDateShortMonth } from 'utils'

export const DateTime: TAnt.TableColumnRender<IMDTAirdropHistoryItemDTO['lastOperation']> = (
  lastOperation
) => (
  <>
    {lastOperation ? moment(lastOperation.createdAt).format(longDateShortMonth).toLowerCase() : ''}
  </>
)
