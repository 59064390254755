import { call, put, takeLatest } from 'redux-saga/effects'
import { ItemsListNamesEnum } from '@medentee/enums'

import { IEntityDTO } from 'interfaces/common'
import { API, api, APIData } from 'services/api'

import { TGetCompanyTypeList, TPatchCompanyTypeListRequest } from './companyTypeList.types'
import * as actionTypes from './companyTypeList.actionTypes'
import * as actions from './companyTypeList.actions'

function* getCompanyTypeListSaga({ payload }: TGetCompanyTypeList) {
  try {
    const { data }: APIData<IEntityDTO[]> = yield api.get(API.LIST_ITEMS(payload.type))

    yield put(actions.getCompanyTypeListSuccess({ list: data }))
  } catch (e) {
    yield put(actions.getCompanyTypeListError(e))
  }
}

function* patchCompanyTypeListSaga({ payload }: TPatchCompanyTypeListRequest) {
  try {
    yield call(api.patch, API.LIST_ITEMS(ItemsListNamesEnum.COMPANY_TYPES), payload)

    yield put(actions.patchCompanyTypeListSuccess())
    yield put(actions.getCompanyTypeListRequest())
  } catch (e) {
    yield put(actions.patchCompanyTypeListError(e))
  }
}

export function* companyTypeListRootSaga() {
  yield takeLatest(actionTypes.GET_COMPANY_TYPE_LIST_REQUEST, getCompanyTypeListSaga)
  yield takeLatest(actionTypes.PATCH_COMPANY_TYPE_LIST_REQUEST, patchCompanyTypeListSaga)
}
