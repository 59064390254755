import React, { useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Affix } from 'antd'
import { AccountTypeIdEnum } from '@medentee/enums'
import { useDispatch } from 'react-redux'
import { subscriptionPlansNestedRoutes } from 'routes'
import { ROUTES } from 'routes/constants'

import { getKeyForRoute } from 'utils'
import { NavigationMenu, subscriptionNavConfig } from 'App/components'
import {
  createLoadingSelector,
  getSubscriptionPlanRequest,
  GET_SUBSCRIPTION_PLAN,
  resetSubscriptionPlan
} from 'store'
import { Spinner } from 'App/common'
import { useAppSelector } from 'App/hooks'

import styles from './SubscriptionPlanPage.module.scss'

const loadingSelector = createLoadingSelector([GET_SUBSCRIPTION_PLAN])

type TLocationParams = {
  accountTypeId: AccountTypeIdEnum
}

const defaultPath = ROUTES.VARIABLES.SUBSCRIPTION_PLANS.PROFESSIONAL

export const SubscriptionPlanPage = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { state } = useLocation<TLocationParams>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (state?.accountTypeId) {
      dispatch(getSubscriptionPlanRequest({ accountTypeId: state?.accountTypeId }))
    }

    return () => {
      dispatch(resetSubscriptionPlan())
    }
  }, [state?.accountTypeId, dispatch])

  const isLoading = useAppSelector(loadingSelector)

  return (
    <div className={styles.root} ref={ref}>
      <Affix className={styles.nav} offsetTop={-24} target={() => ref.current}>
        <NavigationMenu mode="horizontal" config={subscriptionNavConfig} />
      </Affix>
      <div className={styles.wrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Switch>
            {subscriptionPlansNestedRoutes.map((props, index) => (
              <Route key={getKeyForRoute(props.path, index)} {...props} />
            ))}

            <Redirect
              to={{
                pathname: defaultPath,
                state: { accountTypeId: AccountTypeIdEnum.PROFESSIONAL }
              }}
            />
          </Switch>
        )}
      </div>
    </div>
  )
}
