import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'globalConstants'

import { TUserProfileAction, TUserProfileState } from './userProfile.types'
import {
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_BALANCE_SUCCESS,
  GET_USER_PROFILE_BALANCE_ERROR,
  CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION
} from './userProfile.actionTypes'

const initialState: TUserProfileState = {
  data: null,
  balance: null,
  filters: {
    current: PAGINATION_DEFAULT_PAGE,
    showBy: PAGINATION_DEFAULT_SHOW_BY
  }
}

export const userProfileReducer = (state = initialState, action: TUserProfileAction) => {
  switch (action.type) {
    case GET_USER_PROFILE_ERROR: {
      return {
        ...state,
        data: null
      }
    }

    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        data: action.payload
      }
    }

    case GET_USER_PROFILE_BALANCE_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case GET_USER_PROFILE_BALANCE_ERROR: {
      return {
        ...state,
        balance: null
      }
    }

    case CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION: {
      return {
        ...state,
        filters: initialState.filters
      }
    }

    default: {
      return state
    }
  }
}
