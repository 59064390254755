import { put, takeLatest } from 'redux-saga/effects'

import { API, api, APIData } from 'services/api'
import { normalizeList } from 'store'

import { ESubscriptionPlanName, TDefaultSubscriptionPlan } from './subscriptionPlans.types'
import * as actionTypes from './subscriptionPlan.actionTypes'
import * as actions from './subscriptionPlan.actions'

function* getSubscriptionPlanSaga({
  payload: { accountTypeId }
}: ReturnType<typeof actions.getSubscriptionPlanRequest>) {
  try {
    const { data }: APIData<TDefaultSubscriptionPlan[]> = yield api.get(
      API.SUBSCRIPTION_PLAN(accountTypeId)
    )

    const sortedData = data.sort(
      (a, b) =>
        Number(a.name !== ESubscriptionPlanName.BASIC) -
        Number(b.name !== ESubscriptionPlanName.BASIC)
    )

    yield put(
      actions.setSubscriptionPlanSuccess(normalizeList<TDefaultSubscriptionPlan>(sortedData))
    )
  } catch (e: any) {
    yield put(actions.setSubscriptionPlanError(e))
  }
}

export function* subscriptionPlanRootSaga() {
  yield takeLatest(actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST, getSubscriptionPlanSaga)
}
