import React, { FC } from 'react'

import { Header, NavigationMenu } from 'App'
import { useAdaptiveLayout } from 'App/hooks'

import { asideNavConfig } from '../NavigationMenu'

import styles from './Layout.module.scss'

export const Layout: FC = ({ children }) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <>
      <Header />

      <div className={styles.container}>
        {isDesktop && (
          <div className={styles.aside}>
            <NavigationMenu config={asideNavConfig} />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </>
  )
}
