import { TError } from 'store/store.types'
import { actionCreator } from 'store/store.utils'

import * as actionTypes from './connectorPurposes.actionTypes'
import {
  TGetConnectorPurposesSuccessPayload,
  TPatchConnectorPurposesRequestPayload
} from './connectorPurposes.types'

export const getConnectorPurposesRequest = () =>
  actionCreator(actionTypes.GET_CONNECTOR_PURPOSES_REQUEST)
export const getConnectorPurposesSuccess = (payload: TGetConnectorPurposesSuccessPayload) =>
  actionCreator(actionTypes.GET_CONNECTOR_PURPOSES_SUCCESS, payload)
export const getConnectorPurposesError = (e: TError) =>
  actionCreator(actionTypes.GET_CONNECTOR_PURPOSES_ERROR, e)

export const patchConnectorPurposesRequest = (payload: TPatchConnectorPurposesRequestPayload) =>
  actionCreator(actionTypes.PATCH_CONNECTOR_PURPOSES_REQUEST, payload)
export const patchConnectorPurposesSuccess = () =>
  actionCreator(actionTypes.PATCH_CONNECTOR_PURPOSES_SUCCESS)
export const patchConnectorPurposesError = (e: TError) =>
  actionCreator(actionTypes.PATCH_CONNECTOR_PURPOSES_ERROR, e)

export const resetConnectorPurposes = () => actionCreator(actionTypes.RESET_CONNECTOR_PURPOSES)
