import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { message } from 'antd'
import 'antd/dist/antd.css'
import 'assets/style/index.scss'

import { App } from 'App'
import { store } from 'redux/store'
import history from 'utils/history'

import reportWebVitals from './reportWebVitals'

message.config({ duration: 2 })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
