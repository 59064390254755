import { FinancialOperationTypeEnum } from '@medentee/enums'

import { IMDTBalanceHistoryItemDTO } from 'interfaces'
import { getMapComponent } from 'utils'

const OPERATION_MAP = new Map<
  FinancialOperationTypeEnum,
  (subscriptionName?: string) => JSX.Element
>()
  .set(FinancialOperationTypeEnum.BONUS, () => <>Registration airdrop</>)
  .set(FinancialOperationTypeEnum.GENERATION, () => <>Token generation: superadmin</>)
  .set(FinancialOperationTypeEnum.SUBSCRIPTION, (subscriptionName) => (
    <>Subscription: {subscriptionName}</>
  ))
  .set(FinancialOperationTypeEnum.TRANSFER, () => <>Airdrop</>)
  .set(FinancialOperationTypeEnum.AUTO_GENERATION, () => <>Auto generation</>)

export const Operation: TAnt.TableColumnRender<
  FinancialOperationTypeEnum,
  IMDTBalanceHistoryItemDTO
> = (operation, record) => getMapComponent(OPERATION_MAP, operation, record.subscription?.name)
