import { Input, Select } from 'antd'
import { SearchProps } from 'antd/lib/input/Search'
import { ReactNode, useCallback, useState } from 'react'

export type TSearchByOption = {
  value: string
  label: ReactNode
}

export type TSearchProps = Omit<SearchProps, 'onSearch'> & {
  searchByOptions?: TSearchByOption[]
  searchByDefaultValue?: string
  onSearch?: (query: string, by: string) => void
}

export const Search = ({
  className,
  searchByDefaultValue = '',
  searchByOptions,
  onSearch,
  ...rest
}: PropsWithClassName<TSearchProps>) => {
  const [searchByValue, setSearchByValue] = useState(searchByDefaultValue)

  const handleOnSelect = useCallback((value) => {
    setSearchByValue(value)
  }, [])

  const handleSearch = useCallback(
    (value) => {
      if (onSearch) {
        onSearch(value, searchByValue)
      }
    },
    [onSearch, searchByValue]
  )

  return (
    <Input.Search
      className={className}
      size="middle"
      placeholder="Search..."
      enterButton
      allowClear
      {...rest}
      onSearch={handleSearch}
      addonBefore={
        searchByOptions?.length ? (
          <Select onChange={handleOnSelect} value={searchByValue}>
            {searchByOptions.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        ) : null
      }
    />
  )
}
