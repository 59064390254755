import { ColumnType } from 'antd/lib/table'

import { IEntityDTO } from 'interfaces/common'

import { Cell } from './Cell'
import { VisibilitySwitch } from './Visibility'

export type TGenerateColumnsProps = {
  dataSource: IEntityDTO[]
  editingTableMode: boolean
  editedCellOrderNumber: number | null
  setEditedCellOrderNumber: (value: number | null) => void
  handleDelete: (orderNumber: number) => void
  handleSave: (row: IEntityDTO) => void
  handleVisibilityChange: (record: IEntityDTO) => void
}

const settingColumn = {
  title: 'Visible on the platform',
  dataIndex: 'isHidden',
  width: '185px',
  id: 'id'
}

export const generateColumns = ({
  dataSource,
  editingTableMode,
  editedCellOrderNumber,
  setEditedCellOrderNumber,
  handleDelete,
  handleSave,
  handleVisibilityChange
}: TGenerateColumnsProps): ColumnType<IEntityDTO>[] => {
  const dataColumn = [
    {
      title: '№',
      dataIndex: 'orderNumber',
      width: '100px',
      id: 'id'
    },
    {
      id: 'id',
      title: 'Name',
      dataIndex: 'name',
      render: (value: string, record: IEntityDTO) =>
        editingTableMode ? (
          <Cell
            dataSource={dataSource}
            record={record}
            editedCellOrderNumber={editedCellOrderNumber}
            setEditedCellOrderNumber={setEditedCellOrderNumber}
            handleDelete={handleDelete}
            handleSave={handleSave}
          />
        ) : (
          value
        )
    }
  ]

  if (editingTableMode) {
    return [
      ...dataColumn,
      {
        ...settingColumn,
        render: (_item: string, record: IEntityDTO) =>
          record.orderNumber !== editedCellOrderNumber ? (
            <VisibilitySwitch
              record={record}
              disabled={!!editedCellOrderNumber}
              handleVisibilityChange={handleVisibilityChange}
            />
          ) : null
      }
    ]
  }

  return dataColumn
}
