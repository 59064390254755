import { message } from 'antd'
import { takeLatest, put, call } from 'redux-saga/effects'

import { api, API } from 'services'
import { QueryBuilder } from 'utils'
import { getUsersUrl } from 'utils/urls'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'globalConstants'

import history from '../../utils/history'

import {
  getUserProfileRequest,
  getUserProfileError,
  getUserProfileSuccess,
  getUserProfileBalanceRequest,
  getUserProfileBalanceError,
  getUserProfileBalanceSuccess,
  deleteAccountRequest,
  deleteAccountError,
  deleteAccountSuccess,
  updateUserProfileRequest,
  updateUserProfileSuccess,
  updateUserProfileError
} from './userProfile.actions'
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_BALANCE_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  UPDATE_USER_PROFILE_REQUEST
} from './userProfile.actionTypes'

function* getUserProfileSaga(action: ReturnType<typeof getUserProfileRequest>) {
  try {
    const { data } = yield call(api, API.USER_PROFILE(action.payload))

    yield put(getUserProfileSuccess(data))
  } catch (e: any) {
    yield put(getUserProfileError(e))
  }
}

function* updateUserProfileSaga({
  payload: { id, ...payload }
}: ReturnType<typeof updateUserProfileRequest>) {
  try {
    const { data } = yield call(api.patch, API.USER_PROFILE(id), payload)

    yield put(updateUserProfileSuccess(data))
  } catch (e: any) {
    yield put(updateUserProfileError(e))
  }
}

function* getUserProfileBalanceSaga({
  payload: {
    id,
    current = PAGINATION_DEFAULT_PAGE,
    showBy = PAGINATION_DEFAULT_SHOW_BY,
    from = '',
    to = '',
    types,
    sorting
  }
}: ReturnType<typeof getUserProfileBalanceRequest>) {
  try {
    const url = new QueryBuilder(API.USER_PROFILE_BALANCE(id))
      .page(current)
      .showBy(showBy)
      .custom('from', from)
      .custom('to', to)
      .stringify({ types }, { arrayFormat: 'none' })
      .sort(sorting?.type, sorting?.by)
      .build()
    const { data } = yield call(api.get, url)

    yield put(
      getUserProfileBalanceSuccess({
        balance: data,
        filters: {
          current,
          showBy,
          from,
          to,
          types,
          sorting
        }
      })
    )
  } catch (e: any) {
    yield put(getUserProfileBalanceError(e))
  }
}

function* deleteAccountSaga({
  payload: { id, accountType }
}: ReturnType<typeof deleteAccountRequest>) {
  try {
    const url = API.DELETE_ACCOUNT(id)

    yield call<any>(api.delete, url)

    yield put(deleteAccountSuccess())

    yield call(history.push, getUsersUrl(accountType))
    yield call(message.success, 'Account deleted')
  } catch (e: any) {
    yield put(deleteAccountError(e))
    yield call(message.error, 'Unable to delete account')
  }
}

export function* userProfileSaga() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, getUserProfileSaga)
  yield takeLatest(GET_USER_PROFILE_BALANCE_REQUEST, getUserProfileBalanceSaga)
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccountSaga)
  yield takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserProfileSaga)
}
