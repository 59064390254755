import { useMemo } from 'react'
import { Menu } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import cls from 'classnames'

import { TLayoutNavConfig, TNavLinkTo } from './NavigationMenu.config'
import styles from './NavigationMenu.module.scss'

const isActive = (startsWith: boolean, to: TNavLinkTo, pathname: string) =>
  !!(typeof to === 'string' && (startsWith ? pathname.startsWith(to) : pathname.includes(to)))

type TNavigationMenu = {
  config: TLayoutNavConfig[]
  mode?: 'horizontal' | 'inline'
}

export const NavigationMenu = ({ config, mode = 'inline' }: TNavigationMenu) => {
  const { location } = useHistory()

  const selectedKeys = useMemo(
    () =>
      config
        .map(({ startsWith, to, children, key }) => {
          if (children) {
            const subItem = children.find(
              (item) => item.to && isActive(Boolean(item.startsWith), item.to, location.pathname)
            )

            return subItem?.key as string
          }

          if (to && isActive(Boolean(startsWith), to, location.pathname)) {
            return key as string
          }

          return ''
        })
        .filter(Boolean),

    [config, location.pathname]
  )

  return (
    <Menu mode={mode} selectedKeys={selectedKeys} subMenuOpenDelay={5} className={styles[mode]}>
      {config.map(({ icon, key, label, children, to, exact }) => {
        if (children) {
          return (
            <Menu.SubMenu className={styles.menuItemCollapse} icon={icon} key={key} title={label}>
              {children.map((subItem) =>
                subItem.to ? (
                  <Menu.Item
                    className={styles.menuItemCollapseItem}
                    key={subItem.key}
                    icon={subItem.icon}
                  >
                    <NavLink className={styles.menuLink} to={subItem.to} exact={subItem.exact}>
                      {subItem.label}
                    </NavLink>
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )
        }

        return to ? (
          <Menu.Item className={cls(styles.menuItem, styles[`${mode}Item`])} key={key} icon={icon}>
            <NavLink
              className={cls(styles.menuLink, styles[`${mode}Link`])}
              activeClassName={styles[`${mode}LinkActive`]}
              to={to}
              exact={exact}
            >
              {label}
            </NavLink>
          </Menu.Item>
        ) : null
      })}
    </Menu>
  )
}
