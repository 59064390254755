import { ChangeEvent } from 'react'
import { FieldMetaState } from 'react-final-form'

export const getIntegerFormValue = (e: ChangeEvent<HTMLInputElement>) => {
  const convertedValue = Number(e.target.value)

  if (isNaN(convertedValue)) {
    return 0
  }

  return convertedValue
}

export const hasError = <V extends string>({ error, modified, invalid }: FieldMetaState<V>) =>
  error && modified && invalid
