import {
  TChangePasswordRequestPayload,
  TLoginSuccessPayload,
  CHECK_AUTHORIZATION_REQUEST,
  CHECK_AUTHORIZATION_SUCCESS,
  CHECK_AUTHORIZATION_ERROR,
  GET_INITIALIZE_APP_REQUEST,
  GET_INITIALIZE_APP_SUCCESS,
  GET_INITIALIZE_APP_ERROR,
  GET_FOCUS_IN_BROWSER,
  LOST_FOCUS_IN_BROWSER,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  LOGOUT
} from 'store'

import { actionCreator } from './store.utils'
import { TCheckAuthorizationSuccessPayload, TError, TLoginRequestPayload } from './store.types'

// ------------------ CHECK_AUTHORIZATION ------------------

export const checkAuthorizationRequest = () => actionCreator(CHECK_AUTHORIZATION_REQUEST)
export const checkAuthorizationSuccess = (payload: TCheckAuthorizationSuccessPayload) =>
  actionCreator(CHECK_AUTHORIZATION_SUCCESS, payload)
export const checkAuthorizationError = (error: TError) =>
  actionCreator(CHECK_AUTHORIZATION_ERROR, error)

// ------------------ GET_INITIALIZE_APP ------------------
export const initializeApp = () => actionCreator(GET_INITIALIZE_APP_REQUEST)
export const initializeAppSuccess = () => actionCreator(GET_INITIALIZE_APP_SUCCESS)
export const initializeAppError = () => actionCreator(GET_INITIALIZE_APP_ERROR)

// ------------------ FOCUS_IN_BROWSER ------------------
export const getFocusInBrowserAction = () => actionCreator(GET_FOCUS_IN_BROWSER)
export const lostFocusInBrowserAction = () => actionCreator(LOST_FOCUS_IN_BROWSER)

// ------------------ LOGIN ------------------
export const loginRequest = (payload: TLoginRequestPayload) => actionCreator(LOGIN_REQUEST, payload)
export const loginSuccess = (payload: TLoginSuccessPayload) => actionCreator(LOGIN_SUCCESS, payload)
export const loginError = (error: TError) => actionCreator(LOGIN_ERROR, error)

// ------------------ CHANGE_PASSWORD ------------------
export const changePasswordRequest = (payload: TChangePasswordRequestPayload) =>
  actionCreator(CHANGE_PASSWORD_REQUEST, payload)
export const changePasswordSuccess = () => actionCreator(CHANGE_PASSWORD_SUCCESS)
export const changePasswordError = (error: TError) => actionCreator(CHANGE_PASSWORD_ERROR, error)

export const logout = () => actionCreator(LOGOUT)
