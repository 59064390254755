import { useForm, useFormState } from 'react-final-form'

import { createProcessingSelector, INVITE_NEW_USER } from 'store'
import { StepsFooter, TStepsFooterProps } from 'App/common'
import { useAppSelector } from 'App'

const processingSelector = createProcessingSelector([INVITE_NEW_USER])

type TFooterProps = Pick<TStepsFooterProps, 'step' | 'maxStep'> & {
  onBack: () => void
}

export const Footer = ({ onBack, step, maxStep }: TFooterProps) => {
  const processing = useAppSelector(processingSelector)
  const { submit, getFieldState } = useForm()
  const { invalid } = useFormState()

  return (
    <StepsFooter
      step={step}
      maxStep={maxStep}
      back={{ onClick: onBack }}
      submit={{
        disabled: invalid,
        children: 'Invite',
        loading: processing,
        onClick: submit
      }}
      next={{
        disabled: getFieldState('email')?.invalid,
        loading: processing,
        onClick: submit
      }}
    />
  )
}
