// ------------------ action ------------------

export type TAction = {
  type: string
  payload?: any
  meta?: any
}
export function action(type: string): { type: string }
export function action<P = undefined>(type: string, payload?: P): { type: string; payload: P }
export function action<P = undefined, M = undefined>(
  type: string,
  payload: P,
  meta: M
): { type: string; payload: P; meta: M }

export function action<P = undefined, M = undefined>(type: string, payload?: P, meta?: M) {
  return { type, meta, payload }
}

export function actionCreator<T>(type: keyof T): { type: keyof T }
export function actionCreator<T, P = undefined>(
  type: keyof T,
  payload?: P
): { type: keyof T; payload: P }
export function actionCreator<T, P = undefined, M = undefined>(
  type: keyof T,
  payload: P,
  meta: M
): { type: keyof T; payload: P; meta: M }

export function actionCreator<T, P = undefined, M = undefined>(
  type: keyof T,
  payload?: P,
  meta?: M
) {
  return { type, meta, payload }
}

// ------------------  END  ------------------
