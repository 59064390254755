import React, { useCallback, useEffect, useState } from 'react'
import { Drawer, Layout, Button, Dropdown, Menu, Avatar, Space } from 'antd'
import { LogoutOutlined, DownOutlined } from '@ant-design/icons'
import { blue } from '@ant-design/colors'
import { useDispatch } from 'react-redux'

import { ReactComponent as MenuIcon } from 'assets/icons/Menu.svg'
import { NavigationMenu } from 'App'
import { useAdaptiveLayout, useAppSelector } from 'App/hooks'
import { logout } from 'store'

import { asideNavConfig } from '../NavigationMenu'
import { ReactComponent as Logo } from '../../../assets/images/Logo.svg'

import styles from './Header.module.scss'

export const Header = () => {
  const dispatch = useDispatch()
  const { isDesktop } = useAdaptiveLayout()
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const accountData = useAppSelector((state) => state.global.accountData)

  const handleCloseDrawer = useCallback(() => setVisibleDrawer(false), [])
  const handleOpenDrawer = useCallback(() => setVisibleDrawer(true), [])

  const logoutButton = (
    <Button
      type="text"
      icon={<LogoutOutlined />}
      className={styles.logout}
      onClick={() => dispatch(logout())}
    >
      Log out
    </Button>
  )
  const userName = (
    <span className={styles.user}>
      <Avatar size="small" style={{ backgroundColor: blue.primary }}>
        {accountData?.firstName[0]}
        {accountData?.lastName[0]}
      </Avatar>{' '}
      {accountData?.firstName} {accountData?.lastName}
    </span>
  )
  const menu = (
    <Menu>
      <Menu.Item>{logoutButton}</Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (isDesktop && visibleDrawer) {
      handleCloseDrawer()
    }
  }, [handleCloseDrawer, isDesktop, visibleDrawer])

  return (
    <Layout.Header className={styles.root}>
      <Logo />
      {!isDesktop ? (
        <>
          <MenuIcon className={styles.menuIcon} onClick={handleOpenDrawer} />
          <Drawer
            className={styles.drawer}
            closable={false}
            placement="left"
            onClose={handleCloseDrawer}
            visible={visibleDrawer}
          >
            <Menu selectable={false}>
              <Menu.Item>{userName}</Menu.Item>
            </Menu>
            <NavigationMenu config={asideNavConfig} />
            {logoutButton}
          </Drawer>
        </>
      ) : (
        <Dropdown trigger={['click']} overlay={menu}>
          <Space size={10} className={styles.opener}>
            {userName}
            <DownOutlined />
          </Space>
        </Dropdown>
      )}
    </Layout.Header>
  )
}
