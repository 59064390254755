import { actionCreator, TError } from 'store'

import { TAddAdminRequestPayload, TResetAdminFormActionPayload } from './adminManagement.types'
import * as actionTypes from './adminManagement.actionTypes'

export const addAdminRequest = (payload: TAddAdminRequestPayload) =>
  actionCreator(actionTypes.ADD_ADMIN_REQUEST, payload)
export const addAdminSuccess = () => actionCreator(actionTypes.ADD_ADMIN_SUCCESS)
export const addAdminError = (payload: TError) =>
  actionCreator(actionTypes.ADD_ADMIN_ERROR, payload)

export const resetAdminFormAction = (payload: TResetAdminFormActionPayload) =>
  actionCreator(actionTypes.RESET_ADMIN_FORM_ACTION, payload)
