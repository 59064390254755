import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Tabs } from 'antd'
import { ROUTES } from 'routes/constants'

import { createLoadingSelector, getUserProfileRequest, GET_USER_PROFILE } from 'store'
import { useAppSelector } from 'App'
import { Spinner } from 'App/common'

import { UserProfileHeader, UserProfilePersonal } from '../components'

import styles from './UserProfile.module.scss'

const loadingSelector = createLoadingSelector([GET_USER_PROFILE])

export const PersonalAccountInfo: FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const isLoading = useAppSelector(loadingSelector)
  const data = useAppSelector((state) => state.userManagement.userProfile.data)

  const handleChangeTab = useCallback(
    (newActiveTab: string) => {
      push(`${ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS}/${newActiveTab}`)
    },
    [push]
  )

  useEffect(() => {
    dispatch(getUserProfileRequest(id))
  }, [dispatch, id])

  return (
    <div className={styles.root}>
      <UserProfileHeader
        name={data?.name}
        id={id}
        type={data?.type.id}
        balance={data?.balance}
        breadcrumb={[
          {
            text: 'Home'
          },
          {
            text: 'User management'
          },
          {
            text: 'Personal accounts',
            link: '/user-management/personal-accounts'
          },
          {
            text: data?.name ?? ''
          }
        ]}
      >
        {data && 'personalAccounts' in data && data.personalAccounts.professionalAccountId && (
          <Tabs activeKey={id} onChange={handleChangeTab}>
            <Tabs.TabPane tab="Personal" key={data.personalAccounts.professionalAccountId} />
          </Tabs>
        )}
      </UserProfileHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        data &&
        'personalAccounts' in data && (
          <>
            {data?.personalAccounts.professionalAccountId === id && (
              <UserProfilePersonal data={data} />
            )}
          </>
        )
      )}
    </div>
  )
}
