import { EModalComponents, TModalProps } from 'App'

import { action } from '../store.utils'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export type TShowModalActionPayload<T extends EModalComponents> = {
  modalType: T
  modalProps?: TModalProps<T>
}
export const showModalAction = <T extends EModalComponents>(payload: TShowModalActionPayload<T>) =>
  action(SHOW_MODAL, payload)
export type TShowModalAction = ReturnType<typeof showModalAction>

export const hideModalAction = () => action(HIDE_MODAL)
