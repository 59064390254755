import React from 'react'
import { ROUTES } from 'routes/constants'

import { Help } from 'App/common'

import styles from './LabelHelper.module.scss'

type TLabelHelperProps = {
  title: string
  subText: string
}

export const LabelHelper = ({ title, subText }: TLabelHelperProps) => {
  const helperDowngradePlanName = ROUTES.VARIABLES.SUBSCRIPTION_PLANS.PROFESSIONAL
    ? 'Professional'
    : 'Business'

  return (
    <div className={styles.root}>
      <span className={styles.rootText}>{title}</span>
      <Help
        className={styles.popover}
        content={`when ${helperDowngradePlanName} is downgraded to Basic, ${subText}`}
      />
    </div>
  )
}
