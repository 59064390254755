import React, { useEffect } from 'react'

import {
  createLoadingSelector,
  getProfessionListRequest,
  GET_PROFESSION_LIST,
  patchProfessionListRequest,
  PATCH_PROFESSION_LIST,
  resetProfessionList
} from 'store'
import { EModalComponents, useAppDispatch, useAppSelector, useModal } from 'App'
import { Spinner } from 'App/common'
import { TableList, TOnVisibilityChange } from 'App/components/TableList'
import { IEntityDTO } from 'interfaces/common'
import { EVisibilityDataType } from 'App/components/TableList/VisibilityDialog'

const loadingSelector = createLoadingSelector([GET_PROFESSION_LIST, PATCH_PROFESSION_LIST])

export const ProfessionList = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const professionList = useAppSelector((state) => state.professionList.list)

  const { showModal } = useModal()

  useEffect(() => {
    dispatch(getProfessionListRequest())

    return () => {
      dispatch(resetProfessionList())
    }
  }, [dispatch])

  const handleSave = (data: IEntityDTO[]) => {
    dispatch(patchProfessionListRequest({ items: data }))
  }

  const onVisibilityChange: TOnVisibilityChange = (isHidden, onConfirm) => {
    showModal(EModalComponents.CONFIRM_VISIBILITY_CHANGE, {
      isHidden,
      onConfirm,
      type: EVisibilityDataType.PROFESSION
    })
  }

  if (loading && !professionList.length) {
    return <Spinner />
  }

  return (
    <TableList
      title="Profession list"
      list={professionList}
      loading={loading}
      onSave={handleSave}
      onVisibilityChange={onVisibilityChange}
    />
  )
}
