import { isEmpty } from 'lodash'

import { State } from 'redux/rootReducer'

export const createLoadingSelector = (actions: string[]) => (state: State) => {
  /** At initial start this object is empty */
  if (isEmpty(state.loading.singular)) {
    return true
  }

  return actions.some((action) => action.indexOf('GET_') === 0 && state.loading.singular[action])
}

export const createProcessingSelector = (actions: string[]) => (state: State) =>
  actions.some((action) => action.indexOf('GET_') < 0 && state.loading.singular[action])

export const createProcessingBySelector =
  (actions: string[]) => (processingId: string) => (state: State) =>
    actions.some(
      (action) => action.indexOf('GET_') < 0 && (state.loading.plural[action] || {})[processingId]
    )
