import { SubscriptionIdsEnum } from '@medentee/enums'

export const DEFAULT_THROTTLE_MS = 500

export const PAGINATION_DEFAULT_PAGE = 0
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_SHOW_BY = 10
export const PAGINATION_DEFAULT = {
  current: PAGINATION_DEFAULT_PAGE,
  total: PAGINATION_DEFAULT_TOTAL,
  showBy: PAGINATION_DEFAULT_SHOW_BY
}

export const BASE_STORAGE_UNIT = 1024

export enum ESubscriptionName {
  BASIC = 'Basic',
  PROFESSIONAL = 'Professional',
  BUSINESS = 'Business'
}

export const SUBSCRIPTION_NAME_MAP = new Map<SubscriptionIdsEnum, () => ESubscriptionName>()
  .set(SubscriptionIdsEnum.BASIC_PROFESSIONAL, () => ESubscriptionName.BASIC)
  .set(SubscriptionIdsEnum.BASIC_BUSINESS, () => ESubscriptionName.BASIC)
  .set(SubscriptionIdsEnum.PROFESSIONAL, () => ESubscriptionName.PROFESSIONAL)
  .set(SubscriptionIdsEnum.BUSINESS, () => ESubscriptionName.BUSINESS)
