import {
  actionCreator,
  TMDTBalanceSuccessPayload,
  TError,
  TGenerateMDTRequestPayload,
  TGetMDTBalanceHistoryRequestPayload,
  TGetMDTBalanceHistorySuccessPayload,
  TAirdropToUserRequestPayload,
  TGetMDTAirdropHistoryRequestPayload,
  TGetMDTAirdropHistorySuccessPayload,
  TSendAirdropRequestPayload
} from 'store'

import * as actionTypes from './mdtBalance.actionTypes'

export const getMDTBalanceDetailsRequest = () =>
  actionCreator(actionTypes.GET_MDT_BALANCE_DETAILS_REQUEST)
export const getMDTBalanceDetailsSuccess = (payload: TMDTBalanceSuccessPayload) =>
  actionCreator(actionTypes.GET_MDT_BALANCE_DETAILS_SUCCESS, payload)
export const getMDTBalanceDetailsError = (payload: TError) =>
  actionCreator(actionTypes.GET_MDT_BALANCE_DETAILS_ERROR, payload)

export const generateMDTRequest = (payload: TGenerateMDTRequestPayload) =>
  actionCreator(actionTypes.GENERATE_MDT_REQUEST, payload)
export const generateMDTSuccess = () => actionCreator(actionTypes.GENERATE_MDT_SUCCESS)
export const generateMDTError = (payload: TError) =>
  actionCreator(actionTypes.GENERATE_MDT_ERROR, payload)

export const airdropToUserRequest = (payload: TAirdropToUserRequestPayload) =>
  actionCreator(actionTypes.AIRDROP_TO_USER_REQUEST, payload)
export const airdropToUserSuccess = () => actionCreator(actionTypes.AIRDROP_TO_USER_SUCCESS)
export const airdropToUserError = (payload: TError) =>
  actionCreator(actionTypes.AIRDROP_TO_USER_ERROR, payload)

export const getMDTBalanceHistoryRequest = (payload: TGetMDTBalanceHistoryRequestPayload) =>
  actionCreator(actionTypes.GET_MDT_BALANCE_HISTORY_REQUEST, payload)
export const getMDTBalanceHistorySuccess = (payload: TGetMDTBalanceHistorySuccessPayload) =>
  actionCreator(actionTypes.GET_MDT_BALANCE_HISTORY_SUCCESS, payload)
export const getMDTBalanceHistoryError = (payload: TError) =>
  actionCreator(actionTypes.GET_MDT_BALANCE_HISTORY_ERROR, payload)

export const getMDTAirdropHistoryRequest = (payload: TGetMDTAirdropHistoryRequestPayload) =>
  actionCreator(actionTypes.GET_MDT_AIRDROP_HISTORY_REQUEST, payload)
export const getMDTAirdropHistorySuccess = (payload: TGetMDTAirdropHistorySuccessPayload) =>
  actionCreator(actionTypes.GET_MDT_AIRDROP_HISTORY_SUCCESS, payload)
export const getMDTAirdropHistoryError = (payload: TError) =>
  actionCreator(actionTypes.GET_MDT_AIRDROP_HISTORY_ERROR, payload)

export const sendAirdropRequest = (payload: TSendAirdropRequestPayload) =>
  actionCreator(actionTypes.SEND_AIRDROP_REQUEST, payload)
export const sendAirdropSuccess = () => actionCreator(actionTypes.SEND_AIRDROP_SUCCESS)
export const sendAirdropError = (payload: TError) =>
  actionCreator(actionTypes.SEND_AIRDROP_ERROR, payload)
