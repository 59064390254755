import { useCallback, useLayoutEffect, useRef } from 'react'

export const useEvent = <H extends (...args: any[]) => void>(handler: H) => {
  const handlerRef = useRef<null | H>(null)

  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args) => {
    const fn = handlerRef.current

    if (!fn) {
      return
    }

    return fn(...args)
  }, [])
}
