import {
  actionCreator,
  TError,
  TGetPersonalAccountsDetailsSuccessPayload,
  TGetPersonalAccountsRequestPayload,
  TGetPersonalAccountsSuccessPayload
} from 'store'

import * as actionTypes from './personalAccounts.actionTypes'

export const getPersonalAccountsDetailsRequest = () =>
  actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_DETAILS_REQUEST)
export const getPersonalAccountsDetailsSuccess = (
  payload: TGetPersonalAccountsDetailsSuccessPayload
) => actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_DETAILS_SUCCESS, payload)
export const getPersonalAccountsDetailsError = (payload: TError) =>
  actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_DETAILS_ERROR, payload)

export const getPersonalAccountsRequest = (payload: TGetPersonalAccountsRequestPayload) =>
  actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_REQUEST, payload)
export const getPersonalAccountsSuccess = (payload: TGetPersonalAccountsSuccessPayload) =>
  actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_SUCCESS, payload)
export const getPersonalAccountsError = (payload: TError) =>
  actionCreator(actionTypes.GET_PERSONAL_ACCOUNTS_ERROR, payload)

export const clearPersonalAccountsFilter = () =>
  actionCreator(actionTypes.CLEAR_PERSONAL_ACCOUNTS_FILTER)
