export const INVITE_NEW_USER = 'INVITE_NEW_USER'
export const INVITE_NEW_USER_REQUEST = 'INVITE_NEW_USER_REQUEST'
export const INVITE_NEW_USER_SUCCESS = 'INVITE_NEW_USER_SUCCESS'
export const INVITE_NEW_USER_ERROR = 'INVITE_NEW_USER_ERROR'

export const SET_USER_EXISTS_ERROR = 'SET_USER_EXISTS_ERROR'

export const GET_PROFESSIONS = 'GET_PROFESSIONS'
export const GET_PROFESSIONS_REQUEST = 'GET_PROFESSIONS_REQUEST'
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS'
export const GET_PROFESSIONS_ERROR = 'GET_PROFESSIONS_ERROR'
