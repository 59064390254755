import { useCallback, useState } from 'react'
import { InputNumber } from 'antd'

import { Modal, useAppSelector, useAppDispatch } from 'App'
import { airdropToUserRequest, AIRDROP_TO_USER, createProcessingSelector } from 'store'

import styles from './AirdropDialog.module.scss'

const processingSelector = createProcessingSelector([AIRDROP_TO_USER])

type TAirdropDialogProps = {
  userId: string
}

export const AirdropDialog = ({ userId }: TAirdropDialogProps) => {
  const dispatch = useAppDispatch()
  const [amount, setAmount] = useState<number | undefined | null>()
  const processing = useAppSelector(processingSelector)

  const handleSubmit = useCallback(() => {
    if (amount) {
      dispatch(airdropToUserRequest({ amount, userId }))
    }
  }, [amount, dispatch, userId])

  return (
    <Modal
      title="Airdrop MDT"
      onOk={handleSubmit}
      confirmLoading={processing}
      okButtonProps={{ disabled: !amount }}
    >
      <InputNumber
        className={styles.input}
        size="middle"
        placeholder="MDT amount"
        value={amount}
        onChange={setAmount}
        min={0}
      />
    </Modal>
  )
}
