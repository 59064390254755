import React from 'react'

import { useAppSelector } from 'App'

import { ReactComponent as LoginPageLogo } from '../../../assets/images/LoginPageLogo.svg'

import { LoginForm, ChangePassword } from './components'
import styles from './Login.module.scss'

export const Login = () => {
  const isAuthorized = useAppSelector((state) => !!state?.global?.token)
  const isDefaultPasswordChanged = useAppSelector((state) => state.global.isDefaultPasswordChanged)

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {isAuthorized && !isDefaultPasswordChanged ? (
          <ChangePassword />
        ) : (
          <>
            <LoginPageLogo className={styles.logo} />
            <LoginForm />
          </>
        )}
      </div>
    </div>
  )
}
