import { combineReducers, AnyAction } from 'redux'

import * as store from 'store'

const userManagement = combineReducers({
  personalAccounts: store.personalAccountsReducer,
  businessAccounts: store.businessAccountsReducer,
  inviteNewUser: store.inviteNewUserReducer,
  userProfile: store.userProfileReducer
})

const appReducer = combineReducers({
  global: store.globalReducer,
  modal: store.modalReducer,
  loading: store.loadingReducer,
  errors: store.errorsReducer,
  adminManagement: store.adminManagementReducer,
  mdtBalance: store.mdtBalanceReducer,
  userManagement,
  defaultVariables: store.defaultVariablesReducer,
  subscriptionPlan: store.defaultSubscriptionPlanReducer,
  professionList: store.defaultProfessionListReducer,
  companyTypeList: store.defaultCompanyTypeListReducer,
  connectorPurposes: store.defaultConnectorPurposesReducer
})

export type State = ReturnType<typeof appReducer>

const rootReducer = (state: any, action = {}) => appReducer(state, action as AnyAction)

export default rootReducer
