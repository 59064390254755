import { Button, ButtonProps } from 'antd'

import styles from './StepsFooter.module.scss'

export type TStepsFooterProps = {
  step: number
  maxStep: number
  back: ButtonProps
  next: ButtonProps
  submit: ButtonProps
}

export const StepsFooter = ({ step, maxStep, back, next, submit }: TStepsFooterProps) => (
  <div className={styles.root}>
    {step !== 0 && <Button {...back}>{back.children ?? 'Back'}</Button>}

    {step !== maxStep && (
      <Button type="primary" {...next}>
        {next.children ?? 'Next'}
      </Button>
    )}

    {step === maxStep && (
      <Button type="primary" {...submit}>
        {submit.children ?? 'Submit'}
      </Button>
    )}
  </div>
)
