import React from 'react'

import { AddAdmin } from 'App'

import styles from './AdminManagement.module.scss'

export const AdminManagement = () => (
  <div className={styles.root}>
    <AddAdmin />
  </div>
)
