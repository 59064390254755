import { useCallback, useState } from 'react'

export const useSteps = (maxStep: number) => {
  const [step, setStep] = useState<number>(0)

  const onNext = useCallback(() => {
    if (step < maxStep) {
      setStep((prev) => prev + 1)
    }
  }, [maxStep, step])

  const onBack = useCallback(() => {
    if (step > 0) {
      setStep((prev) => prev - 1)
    }
  }, [step])

  return {
    onNext,
    onBack,
    step
  }
}
