import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Field } from 'react-final-form'
import { Input, Form as AntdForm, Radio } from 'antd'
import { AccountTypeIdEnum, AccountTypeNames } from '@medentee/enums'
import capitalize from 'lodash/capitalize'

import { useAppSelector } from 'App/hooks'
import { setUserExistsError } from 'store'
import { hasError, validation } from 'utils'
import { InputError } from 'App/common'

const { Item } = AntdForm
const MAX_EMAIL_LENGTH = 254

export const FirstStep = () => {
  const dispatch = useDispatch()
  const userExistsError = useAppSelector(
    (state) => state.userManagement.inviteNewUser.errors.userExistsError
  )

  const handleChange = useCallback(() => {
    if (userExistsError) {
      dispatch(setUserExistsError({ userExistsError: null }))
    }
  }, [dispatch, userExistsError])

  return (
    <>
      <Field name="accountTypeId">
        {({ input }) => (
          <Item>
            <Radio.Group
              {...input}
              onChange={(event) => {
                input.onChange(event)
                handleChange()
              }}
            >
              <Radio value={AccountTypeIdEnum.PROFESSIONAL} style={{ marginBottom: 14 }}>
                {capitalize(AccountTypeNames.PROFESSIONAL)}
              </Radio>
            </Radio.Group>
          </Item>
        )}
      </Field>

      <Field
        name="email"
        validate={validation.composeValidators(
          validation.required(),
          validation.isEmail(),
          validation.maxLength(MAX_EMAIL_LENGTH)
        )}
      >
        {({ input, meta }) => (
          <Item
            colon={false}
            labelCol={{ span: 24, offset: 0 }}
            label="Email*"
            validateStatus={userExistsError || hasError(meta) ? 'error' : undefined}
            help={<InputError custom={userExistsError} {...meta} />}
          >
            <Input
              placeholder="Email"
              {...input}
              onChange={(event) => {
                input.onChange(event)
                handleChange()
              }}
            />
          </Item>
        )}
      </Field>
    </>
  )
}
