import { ComponentProps, useEffect } from 'react'
import { useLocation } from 'react-router'

import { useModal } from 'App'

import { InviteNewUserDialog } from '../InviteNewUserDialog'
import { ConfirmAirdropDialog } from '../MDTAirdrop'
import { VisibilityDialog } from '../TableList/VisibilityDialog'
import { GenerateDialog } from '../MDTBalance/GenerateDialog'
import { AirdropDialog } from '../AirdropDialog'
import { DeleteAccountDialog } from '../../features/DeleteAccount/DeleteAccountDialog'

import { EModalComponents } from './ModalRoot.enums'

const MODAL_COMPONENTS_MAP = {
  [EModalComponents.GENERATE_BALANCE_DIALOG]: GenerateDialog,
  [EModalComponents.AIRDROP_TO_USER_DIALOG]: AirdropDialog,
  [EModalComponents.INVITE_NEW_USER]: InviteNewUserDialog,
  [EModalComponents.DELETE_ACCOUNT]: DeleteAccountDialog,
  [EModalComponents.CONFIRM_AIRDROP]: ConfirmAirdropDialog,
  [EModalComponents.CONFIRM_VISIBILITY_CHANGE]: VisibilityDialog
}

export type TModalProps<T extends EModalComponents> = ComponentProps<typeof MODAL_COMPONENTS_MAP[T]>

export type TModalPropsMap = {
  [Key in EModalComponents]: TModalProps<Key>
}

export type TModalPropsUnion = TModalPropsMap[keyof typeof EModalComponents]

const getModalComponent = <T extends EModalComponents>(
  modalType: T,
  modalProps: TModalProps<T> | null
) => {
  const Component = MODAL_COMPONENTS_MAP[modalType]

  return <Component {...(typeof modalProps === 'object' ? modalProps : ([] as any))} />
}

export const ModalRoot = () => {
  const { pathname } = useLocation()
  const { hideModal, modalProps, modalType } = useModal()

  useEffect(() => {
    hideModal()
  }, [hideModal, pathname])

  if (!modalType) {
    return null
  }

  return <>{getModalComponent(modalType, modalProps)}</>
}
