import React, { useCallback } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { REGEXP_RULES } from 'utils/regexps'
import { changePasswordRequest, CHANGE_PASSWORD, createProcessingSelector } from 'store'

import styles from './ChangePassword.module.scss'

const processingSelector = createProcessingSelector([CHANGE_PASSWORD])

export const ChangePassword = () => {
  const dispatch = useDispatch()
  const loading = useSelector(processingSelector)

  const handleFinish = useCallback(
    ({ repeatedPassword, ...data }) => {
      dispatch(changePasswordRequest(data))
    },
    [dispatch]
  )

  return (
    <Form onFinish={handleFinish} className={styles.root}>
      <Typography.Title level={4} className={styles.title}>
        Set new password
      </Typography.Title>
      <Form.Item
        name="oldPassword"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password placeholder="Current password" />
      </Form.Item>
      <Form.Item
        name="newPassword"
        rules={[
          { required: true, message: 'Please input new password!' },
          {
            validator: (_, value) => {
              if (!value || REGEXP_RULES.password.test(value)) {
                return Promise.resolve()
              }

              return Promise.reject(new Error('Password is not strong enough!'))
            }
          }
        ]}
      >
        <Input.Password placeholder="New password" />
      </Form.Item>
      <Form.Item
        name="repeatedPassword"
        rules={[
          { required: true, message: 'Please confirm new password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || value === getFieldValue('newPassword')) {
                return Promise.resolve()
              }

              return Promise.reject(new Error('Passwords do not match!'))
            }
          })
        ]}
      >
        <Input.Password placeholder="New password confirmation" />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} block type="primary" htmlType="submit">
          Set
        </Button>
      </Form.Item>
    </Form>
  )
}
