import { all, call, put, spawn, take, takeLatest } from 'redux-saga/effects'

import { API, api, setupAxiosToken } from 'services/api'
import { errorParsing } from 'utils'
import history from 'utils/history'
import {
  CHECK_AUTHORIZATION_REQUEST,
  CHECK_AUTHORIZATION_SUCCESS,
  checkAuthorizationError,
  checkAuthorizationRequest,
  checkAuthorizationSuccess,
  GET_INITIALIZE_APP_REQUEST,
  initializeAppSuccess,
  LOGIN_REQUEST,
  loginSuccess,
  loginError,
  CHANGE_PASSWORD_REQUEST,
  changePasswordSuccess,
  changePasswordError,
  CHECK_AUTHORIZATION_ERROR,
  loginRequest,
  changePasswordRequest,
  mdtBalanceSaga,
  personalAccountsSaga,
  defaultVariablesSaga,
  userProfileSaga,
  LOGOUT,
  businessAccountsSaga,
  inviteNewUserRootSaga,
  subscriptionPlanRootSaga,
  companyTypeListRootSaga,
  professionListRootSaga,
  connectorPurposesRootSaga
} from 'store'

import { adminManagementSaga } from './adminManagement'

function* authorizationSaga() {
  try {
    const { data } = yield call(api, API.WHO_AMI)

    yield put(checkAuthorizationSuccess({ accountData: data }))
  } catch (e: any) {
    yield call(history.push, '/login')
    // eslint-disable-next-line no-console
    console.error(errorParsing(e), '<=== authorization ===')
    yield put(checkAuthorizationError(e))
  }
}

function* initializeAppSaga() {
  yield all([put(checkAuthorizationRequest())])
  yield all([take([CHECK_AUTHORIZATION_SUCCESS, CHECK_AUTHORIZATION_ERROR])])

  yield all([
    // call/put main requests
    put(initializeAppSuccess()),

    // other sagas run in "rootSaga" function
    spawn(rootSaga)
  ])
}

export function* rootSaga() {
  yield spawn(defaultVariablesSaga)
  yield spawn(adminManagementSaga)
  yield spawn(userProfileSaga)
  yield spawn(mdtBalanceSaga)
  yield spawn(personalAccountsSaga)
  yield spawn(businessAccountsSaga)
  yield spawn(inviteNewUserRootSaga)
  yield spawn(subscriptionPlanRootSaga)
  yield spawn(companyTypeListRootSaga)
  yield spawn(professionListRootSaga)
  yield spawn(connectorPurposesRootSaga)
}

function* loginSaga(action: ReturnType<typeof loginRequest>) {
  try {
    const { data } = yield call(api, API.LOGIN, { data: action.payload, method: 'POST' })

    yield put(loginSuccess(data))
    yield call(setupAxiosToken, data.token)

    localStorage.setItem('token', data.token)
    localStorage.setItem('isDefaultPasswordChanged', data.isDefaultPasswordChanged)

    yield put(checkAuthorizationRequest())
    yield call(history.push, '/')
  } catch (e: any) {
    yield put(loginError(e))
  }
}

function* changePassword(action: ReturnType<typeof changePasswordRequest>) {
  try {
    yield call(api, API.CHANGE_PASSWORD, { data: action.payload, method: 'PATCH' })

    localStorage.setItem('isDefaultPasswordChanged', 'true')
    yield put(changePasswordSuccess())
    yield call(history.push, '/')
  } catch (e: any) {
    yield put(changePasswordError(e))
  }
}

function* logoutSaga() {
  yield window.localStorage.clear()
}

export function* initSaga() {
  yield takeLatest(CHECK_AUTHORIZATION_REQUEST, authorizationSaga)
  yield takeLatest(GET_INITIALIZE_APP_REQUEST, initializeAppSaga)
  yield takeLatest(LOGIN_REQUEST, loginSaga)
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLatest(LOGOUT, logoutSaga)

  // If your want to add basic queries, add them here
}
