import { AxiosError } from 'axios'
import isArray from 'lodash/isArray'

export const errorParsing = (error: AxiosError, defaultMessage = 'Default error message') => {
  if (error) {
    if (error.response && error.response.data) {
      if (error.response.data.payload && error.response.data.payload.message) {
        return error.response.data.payload.message
      }

      if (error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          return error.response.data.message
        }

        if (isArray(error.response.data.message)) {
          return error.response.data.message.join('; ')
        }
      }
    }

    if (error.message) {
      return error.message
    }

    return defaultMessage
  }
  return null
}
