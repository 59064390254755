import { Card, Col, Row, Typography, Checkbox, Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BusinessAccountRoleIdEnum } from '@medentee/enums'
import uniq from 'lodash/uniq'

import { Search, TSearchByOption, useAppSelector } from 'App'
import {
  createLoadingSelector,
  getPersonalAccountsDetailsRequest,
  getPersonalAccountsRequest,
  GET_PERSONAL_ACCOUNTS_DETAILS
} from 'store'

import styles from './PersonalAccountsFilter.module.scss'

const { Text } = Typography

const loadingSelector = createLoadingSelector([GET_PERSONAL_ACCOUNTS_DETAILS])

const searchByOptions: TSearchByOption[] = [
  {
    label: 'Name',
    value: 'name'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Location',
    value: 'location'
  }
]

export const PersonalAccountsFilter = ({ className = '' }: PropsWithClassName) => {
  const dispatch = useDispatch()
  const loading = useAppSelector(loadingSelector)
  const search = useAppSelector((state) => state.userManagement.personalAccounts.filters.search)
  const businessAccountRoles = useAppSelector(
    (state) => state.userManagement.personalAccounts.filters.businessAccountRoles ?? []
  )
  const { professionalCount, total } = useAppSelector(
    (state) => state.userManagement.personalAccounts.details
  )

  useEffect(() => {
    dispatch(getPersonalAccountsDetailsRequest())
  }, [dispatch])

  const handleSearch = useCallback(
    (query: string, by: string) =>
      dispatch(
        getPersonalAccountsRequest({
          current: 0,
          search: { query, by }
        })
      ),
    [dispatch]
  )

  const handleCheckbox = useCallback(
    ({ target }: CheckboxChangeEvent) => {
      const newBusinessAccountRoles = target.checked
        ? uniq([...businessAccountRoles, target.value])
        : businessAccountRoles.filter((value) => value !== target.value)

      dispatch(
        getPersonalAccountsRequest({
          current: 0,
          businessAccountRoles:
            target.name === 'businessAccountRoles' ? newBusinessAccountRoles : businessAccountRoles
        })
      )
    },
    [businessAccountRoles, dispatch]
  )

  return (
    <Card bordered={false} className={className}>
      <Row justify="center" gutter={8} className={styles.info}>
        <Col md={4} sm={24} xs={24}>
          <Skeleton active title={false} loading={loading} paragraph={{ rows: 1, width: '100%' }}>
            <Text>Total uses:</Text>
            <Text strong> {total}</Text>
          </Skeleton>
        </Col>

        <Col md={4} sm={24} xs={24}>
          <Skeleton active title={false} loading={loading} paragraph={{ rows: 1, width: '100%' }}>
            <Text>Professionals:</Text>
            <Text strong> {professionalCount}</Text>
          </Skeleton>
        </Col>
      </Row>

      <Row justify="center" className={styles.info}>
        <Col md={18} sm={24} xs={24}>
          <Search
            onSearch={handleSearch}
            searchByOptions={searchByOptions}
            searchByDefaultValue={search?.by ?? searchByOptions[0].value}
          />
        </Col>
      </Row>

      <Row justify="center" gutter={8}>
        <Col md={2} sm={24} xs={24}>
          <Typography.Text strong>Filter by:</Typography.Text>
        </Col>

        <Col md={6} sm={24} xs={24}>
          <Checkbox
            value={BusinessAccountRoleIdEnum.SUPERADMIN}
            name="businessAccountRoles"
            onChange={handleCheckbox}
            checked={businessAccountRoles.includes(BusinessAccountRoleIdEnum.SUPERADMIN)}
          >
            Business: Superadmin
          </Checkbox>
        </Col>

        <Col md={6} sm={24} xs={24}>
          <Checkbox
            value={BusinessAccountRoleIdEnum.ADMIN}
            name="businessAccountRoles"
            onChange={handleCheckbox}
            checked={businessAccountRoles.includes(BusinessAccountRoleIdEnum.ADMIN)}
          >
            Business: Admin
          </Checkbox>
        </Col>
      </Row>
    </Card>
  )
}
