export const GET_CONNECTOR_PURPOSES = 'GET_CONNECTOR_PURPOSES'
export const GET_CONNECTOR_PURPOSES_REQUEST = 'GET_CONNECTOR_PURPOSES_REQUEST'
export const GET_CONNECTOR_PURPOSES_SUCCESS = 'GET_CONNECTOR_PURPOSES_SUCCESS'
export const GET_CONNECTOR_PURPOSES_ERROR = 'GET_CONNECTOR_PURPOSES_ERROR'

export const PATCH_CONNECTOR_PURPOSES = 'PATCH_CONNECTOR_PURPOSES'
export const PATCH_CONNECTOR_PURPOSES_REQUEST = 'PATCH_CONNECTOR_PURPOSES_REQUEST'
export const PATCH_CONNECTOR_PURPOSES_SUCCESS = 'PATCH_CONNECTOR_PURPOSES_SUCCESS'
export const PATCH_CONNECTOR_PURPOSES_ERROR = 'PATCH_CONNECTOR_PURPOSES_ERROR'

export const RESET_CONNECTOR_PURPOSES = 'RESET_CONNECTOR_PURPOSES'
