import { Card, Col, Row } from 'antd'

import { useAdaptiveLayout } from 'App/hooks'

import {
  Checkboxes,
  CurrentBalance,
  Generation,
  SearchWrapper,
  SubscriptionsSelect,
  TotalNumberContacts
} from './components'

export type TFiltersProps = PropsWithClassName

export const Filters = ({ className }: TFiltersProps) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <Card className={className} bordered={false}>
      <Row align="middle" justify={!isDesktop ? 'start' : 'center'} gutter={[8, 24]}>
        <Col md={24} sm={24} xs={24}>
          <SearchWrapper />
        </Col>

        <Col lg={13} md={24} sm={24} xs={24}>
          <Checkboxes />
        </Col>

        <Col lg={9} md={12} sm={24} xs={24}>
          <SubscriptionsSelect />
        </Col>

        <Col lg={8} md={12} sm={24} xs={24}>
          <Generation />
        </Col>

        <Col lg={8} md={12} sm={24} xs={24}>
          <TotalNumberContacts />
        </Col>

        <Col lg={8} md={12} sm={24} xs={24}>
          <CurrentBalance />
        </Col>
      </Row>
    </Card>
  )
}
