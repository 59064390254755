import { useRef } from 'react'
import { Affix } from 'antd'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTES } from 'routes/constants'
import { balanceNestedRoutes } from 'routes'

import { getKeyForRoute } from 'utils'
import { balanceNavConfig, NavigationMenu } from 'App'

import styles from './MDTBalancePage.module.scss'

const defaultPath = ROUTES.MDT.BALANCE

export const MDTBalancePage = () => {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <div className={styles.root} ref={ref}>
      <Affix className={styles.nav} offsetTop={-24} target={() => ref.current}>
        <NavigationMenu mode="horizontal" config={balanceNavConfig} />
      </Affix>
      <div className={styles.wrapper}>
        <Switch>
          {balanceNestedRoutes.map((props, index) => (
            <Route key={getKeyForRoute(props.path, index)} {...props} />
          ))}

          <Redirect to={defaultPath} />
        </Switch>
      </div>
    </div>
  )
}
