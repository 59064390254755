import { put, takeLatest, call } from 'redux-saga/effects'
import { ItemsListNamesEnum } from '@medentee/enums'

import { API, api, APIData } from 'services/api'
import { IEntityDTO } from 'interfaces/common'

import * as actionTypes from './professionList.actionTypes'
import * as actions from './professionList.actions'
import { TGetProfessionListRequest, TPatchProfessionListRequest } from './professionList.types'

function* getProfessionListSaga({ payload }: TGetProfessionListRequest) {
  try {
    const { data }: APIData<IEntityDTO[]> = yield api.get(API.LIST_ITEMS(payload.type))

    yield put(actions.getProfessionListSuccess({ list: data }))
  } catch (e) {
    yield put(actions.getProfessionListError(e))
  }
}

function* patchProfessionListSaga({ payload }: TPatchProfessionListRequest) {
  try {
    yield call(api.patch, API.LIST_ITEMS(ItemsListNamesEnum.PROFESSIONS_LIST), payload)

    yield put(actions.patchProfessionListSuccess())
    yield put(actions.getProfessionListRequest())
  } catch (e: any) {
    yield put(actions.patchProfessionListError(e))
  }
}

export function* professionListRootSaga() {
  yield takeLatest(actionTypes.GET_PROFESSION_LIST_REQUEST, getProfessionListSaga)
  yield takeLatest(actionTypes.PATCH_PROFESSION_LIST_REQUEST, patchProfessionListSaga)
}
