import { useCallback, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import { AccountTypeIdEnum } from '@medentee/enums'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { getMDTAirdropAccountTypesSelector, getMDTAirdropHistoryRequest } from 'store'
import { useAppDispatch, InputRangeNumber, useAppSelector } from 'App'

export const Generation = () => {
  const dispatch = useAppDispatch()
  const accountTypes = useAppSelector(getMDTAirdropAccountTypesSelector, isEqual)

  const isOnlyBusiness =
    accountTypes.length === 1 && accountTypes.includes(AccountTypeIdEnum.BUSINESS)
  const isBusinessWithProfessional =
    accountTypes.length > 1 &&
    accountTypes.includes(AccountTypeIdEnum.BUSINESS) &&
    accountTypes.includes(AccountTypeIdEnum.PROFESSIONAL)

  const rangeDisabled = accountTypes.length > 0 && (isOnlyBusiness || isBusinessWithProfessional)

  const debouncedHandler = useMemo(
    () =>
      debounce((from?: number | null, to?: number | null) => {
        dispatch(
          getMDTAirdropHistoryRequest({
            current: 0,
            generation: { from, to }
          })
        )
      }, DEFAULT_THROTTLE_MS),
    [dispatch]
  )

  const handleChange = useCallback(debouncedHandler, [debouncedHandler])

  return (
    <Row align="middle" gutter={[0, 16]}>
      <Col lg={8} md={8} sm={10} xs={24}>
        <Typography.Text>Generation</Typography.Text>
      </Col>

      <Col lg={16} md={16} sm={14} xs={24}>
        <InputRangeNumber disabled={rangeDisabled} onChange={handleChange} />
      </Col>
    </Row>
  )
}
