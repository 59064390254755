import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Divider, Typography, Skeleton } from 'antd'
import cls from 'classnames'

import { EModalComponents, useAdaptiveLayout, useAppSelector, useModal } from 'App'
import { createLoadingSelector, getMDTBalanceDetailsRequest, GET_MDT_BALANCE_DETAILS } from 'store'

import styles from './BalanceDetails.module.scss'

const { Text } = Typography

const loadingSelector = createLoadingSelector([GET_MDT_BALANCE_DETAILS])

export const BalanceDetails = ({ className = '' }: PropsWithClassName) => {
  const dispatch = useDispatch()
  const { showModal } = useModal()
  const { isDesktop } = useAdaptiveLayout()
  const { platformBalance, total, usersBalance } = useAppSelector(
    (state) => state.mdtBalance.balance.details
  )
  const loading = useAppSelector(loadingSelector)

  useEffect(() => {
    dispatch(getMDTBalanceDetailsRequest())
  }, [dispatch])

  const handleClick = () => showModal(EModalComponents.GENERATE_BALANCE_DIALOG)

  return (
    <div className={cls(styles.root, className)}>
      <Skeleton
        active
        title={false}
        loading={loading}
        paragraph={{ rows: 3, className: styles.skeletonParagraph, width: '100%' }}
      >
        <div className={styles.row}>
          <div className={styles.col}>
            <Text>Total generated:</Text>
            <Text strong>{total} MDT</Text>
          </div>
          <div className={styles.col}>
            <Text>Platform balance as of now:</Text>
            <Text strong>{platformBalance} MDT</Text>
          </div>
          <div className={styles.col}>
            <Text>Balance of user accounts:</Text>
            <Text strong>{usersBalance} MDT</Text>
          </div>
        </div>
      </Skeleton>

      <Divider />

      <Button className={styles.button} type={isDesktop ? 'link' : 'primary'} onClick={handleClick}>
        Generate MDT
      </Button>
    </div>
  )
}
