import React, { FC } from 'react'
import { Spin } from 'antd'

import styles from './Spinner.module.scss'

export const Spinner: FC = () => (
  <div className={styles.root}>
    <Spin size="large" />
  </div>
)
