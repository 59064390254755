import { TPath } from 'routes'

import { SORTING } from 'utils'

export const getKeyForRoute = (path?: TPath, index: number = Math.random()) => {
  if (!path) {
    return index
  }

  if (typeof path === 'string') {
    return `${path}_${index}`
  }

  const key = path.reduce<string>((init, item) => {
    init += item

    return init
  }, '')

  return `${key}_${index}`
}

export const getMapComponent = <D, P, R = JSX.Element | null>(
  map: Map<D, (props: P) => R>,
  decider: D,
  props?: P
) => {
  const component = map.has(decider) ? map.get(decider) : null

  return component ? component(props as P) : null
}

export const sorterConverter = (type: 'ascend' | 'descend') => {
  const map = {
    ascend: SORTING.ASC,
    descend: SORTING.DESC
  }

  return map[type]
}

export const showPaginationTotal: TAnt.PaginationShowTotal = (_total, range) =>
  `${range[0]}-${range[1]} of ${_total} items`
