import { useCallback } from 'react'
import cls from 'classnames'
import { Typography, Skeleton, Card } from 'antd'

import { DateRange, TDateRangeApply, useAppDispatch, useAppSelector } from 'App'
import { createLoadingSelector, getMDTBalanceHistoryRequest, GET_MDT_BALANCE_HISTORY } from 'store'

import styles from './BalanceHistoryFilter.module.scss'

const { Text } = Typography

const loadingSelector = createLoadingSelector([GET_MDT_BALANCE_HISTORY])

export const BalanceHistoryFilter = ({ className = '' }: PropsWithClassName) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const filters = useAppSelector((state) => state.mdtBalance.balance.history.filters)
  const totalIncome = useAppSelector((state) => state.mdtBalance.balance.totalIncome)
  const totalExpense = useAppSelector((state) => state.mdtBalance.balance.totalExpense)

  const handleApply: TDateRangeApply = useCallback(
    (from, to) => {
      if (to && from) {
        dispatch(
          getMDTBalanceHistoryRequest({
            current: 0,
            from: from.dateString,
            to: to.dateString
          })
        )
      }
    },
    [dispatch]
  )

  return (
    <Card bordered={false} className={cls(styles.root, className)}>
      <DateRange
        loading={loading}
        onApply={handleApply}
        defaultFrom={filters.from}
        defaultTo={filters.to}
      />

      <div className={styles.info}>
        <Skeleton
          active
          title={false}
          loading={loading}
          paragraph={{ rows: 2, width: '200px', className: styles.skeletonParagraph }}
        >
          <div className={styles.infoItem}>
            <Text>Income in period:</Text>
            <Text strong>{totalIncome} MDT</Text>
          </div>
          <div className={styles.infoItem}>
            <Text>Expenses in period:</Text>
            <Text strong>{totalExpense} MDT</Text>
          </div>
        </Skeleton>
      </div>
    </Card>
  )
}
