import { useCallback } from 'react'

import { EModalComponents, TModalProps, useAppDispatch, useAppSelector } from 'App'
import { hideModalAction, showModalAction } from 'store'

export const useModal = () => {
  const dispatch = useAppDispatch()
  const { modalProps, modalType } = useAppSelector((state) => ({
    modalProps: state.modal.modalProps,
    modalType: state.modal.modalType
  }))

  const showModal = useCallback(
    <T extends EModalComponents>(
      ...args: TModalProps<T> extends Record<string, unknown>
        ? [type: T, props: TModalProps<T>]
        : [type: T]
    ) => dispatch(showModalAction({ modalType: args[0], modalProps: args[1] })),
    [dispatch]
  )

  const hideModal = useCallback(() => {
    dispatch(hideModalAction())
  }, [dispatch])

  return {
    showModal,
    hideModal,
    modalProps,
    modalType
  }
}
