import React, { useCallback } from 'react'
import { Row, Col, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { AccountTypeIdEnum } from '@medentee/enums'

import { DefaultFormCard, THandleFinish } from 'App/common'
import { useAppSelector } from 'App/hooks'
import { updateSubscriptionPlan, UPDATE_SUBSCRIPTION_PLAN } from 'store'
import { formatBytes, getIntegerFormValue } from 'utils'
import { ESubscriptionPlanName } from 'store/subscriptionPlans/subscriptionPlans.types'

import { LabelHelper } from '../LabelHelper'

type TItemProps = {
  id: string
}

const BUSINESS_SUPERADMIN_LIMIT = 1
const DEFAULT_DECIMALS = 2

export const Item = ({ id }: TItemProps) => {
  const dispatch = useDispatch()

  const {
    name,
    price,
    businessAdminLimit,
    caseMembersDirectLimit,
    caseMembersGroupLimit,
    medCloudCapacity,
    medCloudMaxUploadFileSize,
    accountTypeId
  } = useAppSelector((state) => state.subscriptionPlan.list[id])

  const isBasic = name === ESubscriptionPlanName.BASIC

  const handleFormSubmit = useCallback<THandleFinish>(
    (values) => dispatch(updateSubscriptionPlan({ variables: values })),
    [dispatch]
  )

  return (
    <DefaultFormCard
      title={name}
      controllable={false}
      onFormSubmit={handleFormSubmit}
      loadingActionType={UPDATE_SUBSCRIPTION_PLAN}
      initialValues={{
        price,
        businessAdminLimit,
        businessSuperadminLimit: BUSINESS_SUPERADMIN_LIMIT,
        caseMembersDirectLimit,
        caseMembersGroupLimit,
        medCloudCapacity: formatBytes(Number(medCloudCapacity), DEFAULT_DECIMALS, false),
        medCloudMaxUploadFileSize: formatBytes(
          Number(medCloudMaxUploadFileSize),
          DEFAULT_DECIMALS,
          false
        )
      }}
    >
      {(disabled) => (
        <Row gutter={[20, 0]} align="bottom">
          <Col xs={24} md={12}>
            <Form.Item
              name="price"
              label="Cost monthly, MDT"
              rules={[
                {
                  type: 'number',
                  required: true
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="medCloudCapacity"
              label={
                <LabelHelper
                  title="MEDCloud size, GB"
                  subText="all files are remained but new file (over default value) cannot be uploaded"
                />
              }
              rules={[
                {
                  type: 'number',
                  required: true
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>

          {accountTypeId === AccountTypeIdEnum.BUSINESS && (
            <>
              <Col xs={24} md={12}>
                <Form.Item
                  name="businessAdminLimit"
                  label={
                    <LabelHelper
                      title="Admin quantity in business acc (active/pending), max"
                      subText="Business admin value remains but new admin cannot be added"
                    />
                  }
                  rules={[
                    {
                      type: 'number',
                      required: true
                    }
                  ]}
                  getValueFromEvent={getIntegerFormValue}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="businessSuperadminLimit"
                  label="Superadmin quantity in business acc (active/pending), max"
                  rules={[
                    {
                      type: 'number',
                      required: true
                    }
                  ]}
                  getValueFromEvent={getIntegerFormValue}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={24} md={12}>
            <Form.Item
              name="caseMembersDirectLimit"
              label="Case members in Direct Case (pending or accepted)"
              rules={[
                {
                  type: 'number',
                  required: true
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="caseMembersGroupLimit"
              label="Case members in Group Case (pending or accepted)"
              rules={[
                {
                  type: 'number',
                  required: true
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="medCloudMaxUploadFileSize"
              label={`File size to upload(${isBasic ? 'MB' : 'GB'}), max`}
              rules={[
                {
                  type: 'number',
                  required: true
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
