import { useMediaQuery } from 'react-responsive'

import media from 'assets/style/_mediaVariables.module.scss'

type TUseAdaptiveLayoutReturnProps = {
  customMaxWidth: boolean
  isPortrait: boolean
  isLandscape: boolean
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
}

export const useAdaptiveLayout = (maxWidth?: string): TUseAdaptiveLayoutReturnProps => {
  const { tabletMaxWidthLandscape, mobileMaxHeight, mobileMaxWidthLandscape, tabletMinWidth } =
    media

  const isDesktop = useMediaQuery({ minWidth: Number(tabletMaxWidthLandscape) + 1 })

  const isMobileDevicePortrait = useMediaQuery({
    maxWidth: Number(tabletMinWidth) - 1,
    orientation: 'portrait'
  })

  const isMobileDeviceLandscape = useMediaQuery({
    maxHeight: Number(mobileMaxHeight),
    maxWidth: Number(mobileMaxWidthLandscape),
    orientation: 'landscape'
  })

  const isMobile = isMobileDevicePortrait || isMobileDeviceLandscape
  const isTablet = !isMobile && !isDesktop

  const customMaxWidth = useMediaQuery({
    maxWidth: Number(maxWidth)
  })

  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isLandscape = useMediaQuery({ orientation: 'landscape' })

  return {
    customMaxWidth,
    isPortrait,
    isLandscape,
    isDesktop,
    isTablet,
    isMobile
  }
}
