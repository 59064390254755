import { State } from 'redux/rootReducer'

export const getMDTAirdropHistoryDataSourceSelector = (state: State) => {
  const { ids, list } = state.mdtBalance.airdrop.history

  return ids.map((id) => ({
    key: id,
    ...list[id]
  }))
}

export const getMDTAirdropHistoryCurrentSelector = (state: State) =>
  state.mdtBalance.airdrop.history.filters.current
export const getMDTAirdropHistoryTotalSelector = (state: State) =>
  state.mdtBalance.airdrop.history.filters.total
export const getMDTAirdropHistoryShowBySelector = (state: State) =>
  state.mdtBalance.airdrop.history.filters.showBy

export const getMDTAirdropHistoryRangeSelector = (state: State) => {
  const { from, to } = state.mdtBalance.airdrop.history.filters

  return { from, to }
}

export const getMDTAirdropAccountTypesSelector = (state: State) =>
  state.mdtBalance.airdrop.history.filters.accountTypes ?? []

export const getMDTAirdropSubscriptionSelector = (state: State) =>
  state.mdtBalance.airdrop.history.filters.subscription ?? undefined

export const getMDTPlatformBalanceSelector = (state: State) =>
  state.mdtBalance.balance.details.platformBalance
