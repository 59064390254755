import { put, takeLatest, call, select } from 'redux-saga/effects'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import {
  IMDTAirdropHistoryItemDTO,
  IMDTBalanceDetailsDTO,
  IMDTBalanceHistoryDTO,
  IMDTBalanceHistoryItemDTO
} from 'interfaces'
import {
  airdropToUserRequest,
  generateMDTRequest,
  generateMDTSuccess,
  getMDTBalanceDetailsRequest,
  getMDTBalanceHistoryError,
  getMDTBalanceHistorySuccess,
  hideModalAction,
  TMDTBalanceHistoryFilters,
  TTUserProfileBalanceFilters,
  getUserProfileBalanceRequest,
  mdtAirdropHistoryNormalize,
  TMDTAirdropHistoryFilters,
  normalizeList
} from 'store'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'globalConstants'
import { QueryBuilder } from 'utils'
import { State } from 'redux/rootReducer'

import {
  getMDTBalanceDetailsSuccess,
  getMDTBalanceDetailsError,
  generateMDTError,
  getMDTBalanceHistoryRequest,
  airdropToUserSuccess,
  airdropToUserError,
  getMDTAirdropHistoryRequest,
  getMDTAirdropHistorySuccess,
  getMDTAirdropHistoryError,
  sendAirdropSuccess,
  sendAirdropRequest,
  sendAirdropError
} from './mdtBalance.actions'
import * as actionTypes from './mdtBalance.actionTypes'

function* getMDTBalanceDetailsSaga() {
  try {
    const { data }: APIData<IMDTBalanceDetailsDTO> = yield call(api.get, API.MDT_BALANCE_DETAILS)

    yield put(getMDTBalanceDetailsSuccess(data))
  } catch (e: any) {
    yield put(getMDTBalanceDetailsError(e))
  }
}

function* generateMDTSaga({ payload }: ReturnType<typeof generateMDTRequest>) {
  try {
    yield call(api.post, API.GENERATE_MDT, payload)

    yield put(generateMDTSuccess())
    yield put(getMDTBalanceDetailsRequest())
    yield put(hideModalAction())

    const filters: TMDTBalanceHistoryFilters = yield select(
      (state: State) => state.mdtBalance.balance.history.filters
    )

    yield put(getMDTBalanceHistoryRequest(filters))
  } catch (e: any) {
    yield put(generateMDTError(e))
  }
}

function* airdropToUserSaga({ payload }: ReturnType<typeof airdropToUserRequest>) {
  try {
    yield call(api.post, API.AIRDROP, payload)

    yield put(airdropToUserSuccess())
    yield put(hideModalAction())

    const filters: TTUserProfileBalanceFilters = yield select(
      (state: State) => state.userManagement.userProfile.filters
    )

    yield put(
      getUserProfileBalanceRequest({
        id: payload.userId,
        ...filters
      })
    )
  } catch (e: any) {
    yield put(airdropToUserError(e))
  }
}

function* getMDTBalanceHistorySaga({ payload }: ReturnType<typeof getMDTBalanceHistoryRequest>) {
  try {
    const filters: TMDTBalanceHistoryFilters = yield select(
      (state: State) => state.mdtBalance.balance.history.filters
    )

    const {
      current = PAGINATION_DEFAULT_PAGE,
      showBy = PAGINATION_DEFAULT_SHOW_BY,
      to = filters.to ?? '',
      from = filters.from ?? '',
      types = filters.types,
      sorting = filters?.sorting
    } = payload

    const url = new QueryBuilder(API.MDT_BALANCE_HISTORY)
      .page(current)
      .showBy(showBy)
      .custom('from', from)
      .custom('to', to)
      .stringify({ types }, { arrayFormat: 'none' })
      .sort(sorting?.type, sorting?.by)
      .build()

    const {
      data: {
        table: { results, total },
        totalExpense,
        totalIncome
      }
    }: APIData<IMDTBalanceHistoryDTO> = yield call(api.get, url)

    const normalizedData = normalizeList<IMDTBalanceHistoryItemDTO>(results)

    yield put(
      getMDTBalanceHistorySuccess({
        ...normalizedData,
        totalIncome,
        totalExpense,
        filters: {
          total,
          current,
          showBy,
          to,
          from,
          types,
          sorting
        }
      })
    )
  } catch (e: any) {
    yield put(getMDTBalanceHistoryError(e))
  }
}

function* getMDTAirdropHistorySaga({ payload }: ReturnType<typeof getMDTAirdropHistoryRequest>) {
  try {
    const filters: TMDTAirdropHistoryFilters = yield select(
      (state: State) => state.mdtBalance.airdrop.history.filters
    )

    const {
      current = PAGINATION_DEFAULT_PAGE,
      showBy = PAGINATION_DEFAULT_SHOW_BY,
      to = filters.to ?? '',
      from = filters.from ?? '',
      search = filters.search,
      accountTypes = filters.accountTypes,
      subscription = filters.subscription,
      generation = filters.generation,
      currentBalance = filters.currentBalance,
      totalNumberOfContacts = filters.totalNumberOfContacts
    } = payload

    const url = new QueryBuilder(API.USERS)
      .page(current)
      .showBy(showBy)
      .custom('transactionsPeriodFrom', from)
      .custom('transactionsPeriodTo', to)
      .custom('generationFrom', generation?.from ?? '')
      .custom('generationTo', generation?.to ?? '')
      .custom('contactsCountFrom', totalNumberOfContacts?.from ?? '')
      .custom('contactsCountTo', totalNumberOfContacts?.to ?? '')
      .custom('balanceFrom', currentBalance?.from ?? '')
      .custom('balanceTo', currentBalance?.to ?? '')
      .stringify({ subscriptionIds: subscription }, { arrayFormat: 'none', skipNull: true })
      .stringify({ typeIds: accountTypes }, { arrayFormat: 'none' })
      .searchQuery(search?.query)
      .searchBy(search?.by)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<IMDTAirdropHistoryItemDTO[]> = yield call(api.get, url)

    const normalizedData = mdtAirdropHistoryNormalize(results)

    yield put(
      getMDTAirdropHistorySuccess({
        ...normalizedData,
        filters: {
          total,
          current,
          showBy,
          to,
          from,
          search,
          accountTypes,
          subscription,
          generation,
          currentBalance,
          totalNumberOfContacts
        }
      })
    )
  } catch (e) {
    yield put(getMDTAirdropHistoryError(e))
  }
}

function* sendAirdropSaga({
  payload: { amount, selectedIds, onSuccess }
}: ReturnType<typeof sendAirdropRequest>) {
  try {
    yield call(api.post, API.AIRDROP, { amount, userId: selectedIds })

    yield put(sendAirdropSuccess())

    yield call(onSuccess)
    yield put(hideModalAction())

    const filters: TMDTAirdropHistoryFilters = yield select(
      (state: State) => state.mdtBalance.airdrop.history.filters
    )

    yield put(getMDTAirdropHistoryRequest(filters))
    yield put(getMDTBalanceDetailsRequest())
  } catch (e) {
    yield put(sendAirdropError(e))
  }
}

export function* mdtBalanceSaga() {
  yield takeLatest(actionTypes.GET_MDT_BALANCE_DETAILS_REQUEST, getMDTBalanceDetailsSaga)
  yield takeLatest(actionTypes.GENERATE_MDT_REQUEST, generateMDTSaga)
  yield takeLatest(actionTypes.GET_MDT_BALANCE_HISTORY_REQUEST, getMDTBalanceHistorySaga)
  yield takeLatest(actionTypes.AIRDROP_TO_USER_REQUEST, airdropToUserSaga)
  yield takeLatest(actionTypes.GET_MDT_AIRDROP_HISTORY_REQUEST, getMDTAirdropHistorySaga)
  yield takeLatest(actionTypes.SEND_AIRDROP_REQUEST, sendAirdropSaga)
}
