import React from 'react'
import { Card, Button, Row } from 'antd'

import { useModal } from 'App'
import { EModalComponents } from 'App/components/ModalRoot'

export type TPersonalAccountsInviteProps = PropsWithClassName

export const PersonalAccountsInvite = ({ className }: TPersonalAccountsInviteProps) => {
  const { showModal } = useModal()

  const handleClick = () => showModal(EModalComponents.INVITE_NEW_USER)

  return (
    <Card bordered={false} bodyStyle={{ paddingTop: 20, paddingBottom: 20 }} className={className}>
      <Row justify="center">
        <Button onClick={handleClick} type="primary">
          Invite new user
        </Button>
      </Row>
    </Card>
  )
}
