import { Button } from 'antd'
import { AccountTypeIdEnum } from '@medentee/enums'
import { UserDeleteOutlined } from '@ant-design/icons'

import { useAppDispatch, useAppSelector } from 'App/hooks/redux'
import { createProcessingSelector, showModalAction, DELETE_ACCOUNT } from 'store'
import { EModalComponents } from 'App/components/ModalRoot'

export type DeleteAccountProps = {
  id: string
  accountType: AccountTypeIdEnum
}

const processingSelector = createProcessingSelector([DELETE_ACCOUNT])

export const DeleteAccountButton = (props: DeleteAccountProps) => {
  const { id, accountType } = props
  const dispatch = useAppDispatch()
  const loading = useAppSelector(processingSelector)

  const handleClick = () => {
    dispatch(
      showModalAction({
        modalProps: { id, accountType },
        modalType: EModalComponents.DELETE_ACCOUNT
      })
    )
  }

  return (
    <Button icon={<UserDeleteOutlined />} type="primary" onClick={handleClick} loading={loading}>
      Delete Account
    </Button>
  )
}
