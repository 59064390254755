import React from 'react'
import { EditFilled, CloseCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import styles from './EditSwitcher.module.scss'

type TEditSwitcherProps = {
  editing: boolean
  onClick: () => void
}

export const EditSwitcher = ({ editing, onClick }: TEditSwitcherProps) => (
  <Button type="link" onClick={onClick}>
    {editing ? (
      <>
        <span className={styles.text}>Cancel</span>
        <CloseCircleOutlined />
      </>
    ) : (
      <>
        <span className={styles.text}>Edit</span>
        <EditFilled />
      </>
    )}
  </Button>
)
