import React, { FC } from 'react'
import { Card } from 'antd'

import { formatBytes } from 'utils'

export type TMedCloudInfoProps = {
  data: { capacityOccupied: number; capacityAvailable: number; filesCount: number }
}

export const MedCloudInfo: FC<TMedCloudInfoProps> = (props) => {
  const { data } = props

  return (
    <Card bordered={false}>
      <dl>
        <dt>
          <b>MedCloud:</b>
        </dt>
        <dd>
          {formatBytes(data.capacityOccupied)} / {formatBytes(data.capacityAvailable)}
        </dd>
        <dt>Quantity of files:</dt>
        <dd>{data.filesCount}</dd>
      </dl>
    </Card>
  )
}
