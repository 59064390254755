import {
  actionCreator,
  GET_DEFAULT_VARIABLES_ERROR,
  GET_DEFAULT_VARIABLES_REQUEST,
  TError,
  TDefaultVariablesPayload,
  GET_DEFAULT_VARIABLES_SUCCESS,
  UPDATE_DEFAULT_VARIABLES_REQUEST,
  UPDATE_DEFAULT_VARIABLES_SUCCESS,
  UPDATE_DEFAULT_VARIABLES_ERROR,
  TUpdateDefaultVariablesPayload
} from 'store'

export const getDefaultVariablesRequest = () => actionCreator(GET_DEFAULT_VARIABLES_REQUEST)
export const getDefaultVariablesSuccess = (payload: TDefaultVariablesPayload) =>
  actionCreator(GET_DEFAULT_VARIABLES_SUCCESS, payload)
export const getDefaultVariablesError = (e: TError) => actionCreator(GET_DEFAULT_VARIABLES_ERROR, e)

export const updateDefaultVariablesRequest = (payload: TUpdateDefaultVariablesPayload) =>
  actionCreator(UPDATE_DEFAULT_VARIABLES_REQUEST, payload)
export const updateDefaultVariablesSuccess = () => actionCreator(UPDATE_DEFAULT_VARIABLES_SUCCESS)
export const updateDefaultVariablesError = (e: TError) =>
  actionCreator(UPDATE_DEFAULT_VARIABLES_ERROR, e)
