import { createProcessingSelector, sendAirdropRequest, SEND_AIRDROP, TIds } from 'store'
import { useAppDispatch, useAppSelector, useModal } from 'App/hooks'
import { ConfirmationModal } from 'App/common/ConfirmationModal'

type TConfirmAirdropDialogProps = {
  amount: number
  selectedIds: TIds
}

const processingSelector = createProcessingSelector([SEND_AIRDROP])

export const ConfirmAirdropDialog = ({ amount, selectedIds }: TConfirmAirdropDialogProps) => {
  const { hideModal } = useModal()
  const dispatch = useAppDispatch()
  const processing = useAppSelector(processingSelector)

  const handleOk = (onSuccess: () => void) => {
    dispatch(sendAirdropRequest({ amount, selectedIds, onSuccess }))
  }

  return (
    <ConfirmationModal
      title={`Are you sure you want to add ${amount} MDT for ${selectedIds.length} user(s)?`}
      onOk={handleOk}
      onCancel={hideModal}
      okButtonProps={{ loading: processing }}
      cancelButtonProps={{ disabled: processing }}
    />
  )
}
