import * as actionTypes from './connectorPurposes.actionTypes'
import { TConnectorPurposesState, TConnectorPurposesAction } from './connectorPurposes.types'

const initialState: TConnectorPurposesState = {
  list: []
}

export const defaultConnectorPurposesReducer = (
  state = initialState,
  action: TConnectorPurposesAction
) => {
  switch (action.type) {
    case actionTypes.GET_CONNECTOR_PURPOSES_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.RESET_CONNECTOR_PURPOSES: {
      return initialState
    }

    default:
      return state
  }
}
