import { useState } from 'react'

import { TIds } from 'store'

import { Airdrop } from './Airdrop'
import { Filters } from './Filters'
import { History } from './History'
import { Range } from './Range'
import styles from './MDTAirdrop.module.scss'

export const MDTAirdrop = () => {
  const [selectedIds, setSelectedIds] = useState<TIds>([])

  return (
    <div className={styles.root}>
      <Filters className={styles.indent} />
      <Range className={styles.indent} setSelectedIds={setSelectedIds} />
      <Airdrop className={styles.indent} selectedIds={selectedIds} />
      <History selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
    </div>
  )
}
