import { Key, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import isEqual from 'lodash/isEqual'

import {
  createLoadingSelector,
  getMDTAirdropHistoryRequest,
  getMDTBalanceDetailsRequest,
  getMDTAirdropHistoryCurrentSelector,
  getMDTAirdropHistoryDataSourceSelector,
  getMDTAirdropHistoryShowBySelector,
  getMDTAirdropHistoryTotalSelector,
  GET_MDT_AIRDROP_HISTORY,
  GET_MDT_BALANCE_DETAILS,
  TIds
} from 'store'
import { showPaginationTotal } from 'utils'
import { IMDTAirdropHistoryItemDTO } from 'interfaces'
import { useAdaptiveLayout, useAppSelector } from 'App'
import { useEvent } from 'App/hooks'

import { AccountType, Balance, DateTime, FullName, LastOperation, Subscription } from './components'

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY, GET_MDT_BALANCE_DETAILS])

const columns: ColumnsType<IMDTAirdropHistoryItemDTO> = [
  {
    title: 'First, Last name',
    dataIndex: 'displayUserName',
    key: 'displayUserName',
    render: FullName
  },
  {
    title: 'Account type',
    dataIndex: 'type',
    key: 'type',
    render: AccountType
  },
  {
    title: 'Subscription plan',
    dataIndex: 'subscription',
    key: 'subscription',
    render: Subscription
  },
  {
    title: 'Last operation',
    dataIndex: 'lastOperation',
    key: 'lastOperation',
    align: 'right',
    render: LastOperation
  },
  {
    title: 'Date',
    dataIndex: 'lastOperation',
    key: 'date',
    align: 'right',
    render: DateTime
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    align: 'right',
    render: Balance
  }
]

type THistoryProps = {
  selectedIds: TIds
  setSelectedIds: (ids: TIds) => void
}

export const History = ({ selectedIds, setSelectedIds }: THistoryProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useAdaptiveLayout()
  const loading = useAppSelector(loadingSelector)
  const current = useAppSelector(getMDTAirdropHistoryCurrentSelector)
  const total = useAppSelector(getMDTAirdropHistoryTotalSelector)
  const showBy = useAppSelector(getMDTAirdropHistoryShowBySelector)
  const dataSource = useAppSelector(getMDTAirdropHistoryDataSourceSelector, isEqual)

  const onSelectChange = useEvent((newSelectedRowIds: Key[]) => {
    setSelectedIds(newSelectedRowIds as TIds)
  })

  const handleTableChange: TAnt.TableOnChange<IMDTAirdropHistoryItemDTO> = useEvent(
    ({ current: currentPage, pageSize }) => {
      const page = currentPage && currentPage > 0 ? currentPage - 1 : 0

      dispatch(
        getMDTAirdropHistoryRequest({
          current: page,
          showBy: pageSize
        })
      )
    }
  )

  useEffect(() => {
    dispatch(
      getMDTAirdropHistoryRequest({
        current: 0
      })
    )

    dispatch(getMDTBalanceDetailsRequest())
  }, [dispatch])

  useEffect(
    () => () => {
      setSelectedIds([])
    },
    [setSelectedIds]
  )

  return (
    <Card bordered={false}>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
        pagination={{
          responsive: true,
          total,
          defaultPageSize: showBy,
          current: current + 1,
          showTotal: isMobile ? undefined : showPaginationTotal
        }}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: onSelectChange,
          preserveSelectedRowKeys: true
        }}
      />
    </Card>
  )
}
