import React, { FC } from 'react'
import { Row, Col, Card } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import { ROUTES } from 'routes/constants'

import { TUserProfileBusiness } from 'store'

import { MedCloudInfo, CasesInfo, UsersList } from '../index'

export type TUserProfileBusinessProps = {
  data: TUserProfileBusiness
}

export const UserProfileBusiness: FC<TUserProfileBusinessProps> = (props) => {
  const { data } = props
  const { id } = useParams<{ id: string }>()

  if (id !== data.userId) {
    return null
  }

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={12}>
        <Card bordered={false}>
          <dl>
            <dt>Business email:</dt>
            <dd>{data.email}</dd>
            <dt>Company type:</dt>
            <dd>{data.companyInfo.name}</dd>
            <dt>Company specialization(s):</dt>
            <dd>
              {data.companyInfo.specializations.length
                ? data.companyInfo.specializations.join(', ')
                : '-'}
            </dd>
            <dt>Location:</dt>
            <dd>
              {data.location.cityName}, {data.location.countryName}
            </dd>
          </dl>
        </Card>
      </Col>
      <Col xs={24} sm={6}>
        <MedCloudInfo data={data.medCloud} />
      </Col>
      <Col xs={24} sm={6}>
        <CasesInfo data={data.cases} />
      </Col>
      <Col xs={24} sm={12}>
        <Card bordered={false}>
          <dl>
            <dt>Current Superadmin:</dt>
            <dd>
              <NavLink to={`${ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS}/${data.superadmin.id}`}>
                {data.superadmin.name}
              </NavLink>
            </dd>
            <dt>Current Admin:</dt>
            <dd>&nbsp;</dd>
          </dl>
          <UsersList data={data.admin} />
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card bordered={true}>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12}>
              <dl>
                <dt>
                  <b>Associates:</b>
                </dt>
                <dd>{data.partners.business + data.partners.professional}</dd>
                <dt>professionals:</dt>
                <dd>{data.partners.professional}</dd>
                <dt>business:</dt>
                <dd>{data.partners.business}</dd>
              </dl>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}
