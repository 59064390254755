import { Button, Col, Row } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import cls from 'classnames'

import styles from './View.module.scss'

type TViewProps = {
  name: string
  disable: boolean
  onEdit: () => void
  onDelete: () => void

  id?: string
}

const ICON_COLOR = {
  INITIAL: '#1890FF',
  DISABLE: '#D9D9D9'
}

export const View = ({ id, name, disable, onEdit, onDelete }: TViewProps) => (
  <Row align="middle" justify="space-between" gutter={[16, 0]}>
    <Col className={styles.item}>{name}</Col>
    {!id && (
      <Col className={styles.item}>
        <Button
          type="text"
          className={styles.button}
          icon={
            <EditTwoTone
              twoToneColor={disable ? ICON_COLOR.DISABLE : ICON_COLOR.INITIAL}
              className={styles.buttonIcon}
            />
          }
          onClick={onEdit}
          disabled={disable}
        />
        <Button
          type="text"
          className={cls(styles.button, styles.buttonLast)}
          icon={
            <DeleteTwoTone
              twoToneColor={disable ? ICON_COLOR.DISABLE : ICON_COLOR.INITIAL}
              className={styles.buttonIcon}
            />
          }
          onClick={onDelete}
          disabled={disable}
        />
      </Col>
    )}
  </Row>
)
