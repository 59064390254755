import { AccountTypeIdEnum } from '@medentee/enums'
import { Checkbox, Col, Row, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'

import {
  createLoadingSelector,
  getMDTAirdropAccountTypesSelector,
  getMDTAirdropHistoryRequest,
  GET_MDT_AIRDROP_HISTORY
} from 'store'
import { useAppDispatch, useAppSelector, useEvent } from 'App/hooks'

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY])

export const Checkboxes = () => {
  const dispatch = useAppDispatch()
  const accountTypes = useAppSelector(getMDTAirdropAccountTypesSelector, isEqual)
  const loading = useAppSelector(loadingSelector)

  const handleCheckbox = useEvent(({ target }: CheckboxChangeEvent) => {
    const newAccountType = target.checked
      ? uniq([...accountTypes, target.value])
      : accountTypes.filter((value) => value !== target.value)

    dispatch(
      getMDTAirdropHistoryRequest({
        current: 0,
        accountTypes: newAccountType
      })
    )
  })
  return (
    <Row align="middle" gutter={[0, 22]}>
      <Col lg={4} md={3} sm={4} xs={24}>
        <Typography.Text strong>Filter by:</Typography.Text>
      </Col>

      <Col lg={7} md={4} sm={6} xs={24}>
        <Checkbox
          disabled={loading}
          value={AccountTypeIdEnum.PROFESSIONAL}
          checked={accountTypes.includes(AccountTypeIdEnum.PROFESSIONAL)}
          name="accountTypes"
          onChange={handleCheckbox}
        >
          Professionals
        </Checkbox>
      </Col>

      <Col lg={6} md={4} sm={6} xs={24}>
        <Checkbox
          disabled={loading}
          value={AccountTypeIdEnum.BUSINESS}
          name="accountTypes"
          onChange={handleCheckbox}
          checked={accountTypes.includes(AccountTypeIdEnum.BUSINESS)}
        >
          Business
        </Checkbox>
      </Col>
    </Row>
  )
}
