import { useCallback, useState } from 'react'
import { InputNumber } from 'antd'
import { useDispatch } from 'react-redux'

import { createProcessingSelector, generateMDTRequest, GENERATE_MDT } from 'store'
import { Modal, useAppSelector } from 'App'

import styles from './GenerateDialog.module.scss'

const MIN_AMOUNT = 10
const MAX_AMOUNT = 20000

const processingSelector = createProcessingSelector([GENERATE_MDT])

export const GenerateDialog = () => {
  const dispatch = useDispatch()
  const [amount, setAmount] = useState<number | undefined | null>()
  const processing = useAppSelector(processingSelector)

  const handleSubmit = useCallback(() => {
    if (amount) {
      dispatch(generateMDTRequest({ tokenCount: amount }))
    }
  }, [amount, dispatch])

  return (
    <Modal
      title="Generate MDT"
      onOk={handleSubmit}
      confirmLoading={processing}
      okButtonProps={{ disabled: !amount }}
    >
      <InputNumber
        className={styles.input}
        size="middle"
        placeholder="MDT amount"
        value={amount}
        onChange={setAmount}
        min={MIN_AMOUNT}
        max={MAX_AMOUNT}
      />
    </Modal>
  )
}
