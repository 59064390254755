import React, { useMemo } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Input from 'antd/lib/input'
import { Rule } from 'antd/lib/form'

import { IEntityDTO } from 'interfaces/common'
import { REGEXP_RULES } from 'utils'

import { THandleSaveClick } from '../Cell'

import styles from './Editable.module.scss'

type TEditableProps = {
  dataSource: IEntityDTO[]
  name: string
  order: number
  onSave: THandleSaveClick
  onCancel: () => void
}

const TEXT_MAX_LENGTH = 50

export const Editable = ({ name, dataSource, order, onSave, onCancel }: TEditableProps) => {
  const validation: Rule[] = useMemo(
    () => [
      { required: true, message: 'Required' },
      {
        pattern: REGEXP_RULES.specialSymbolsWithLatin,
        message:
          "Only Latin characters can be used and special characters ' - / , AND 1 space consecutively"
      },
      {
        max: TEXT_MAX_LENGTH,
        message: `Field shouldn’t exceed ${TEXT_MAX_LENGTH} characters`
      },
      {
        validator: (_, value) => {
          const trimmedValue = value.trim()

          const isExist = dataSource.some(
            (item) =>
              item.name.toLocaleLowerCase() === trimmedValue.toLocaleLowerCase() &&
              order !== item.orderNumber
          )

          return isExist
            ? Promise.reject(new Error('Name already exists in system'))
            : Promise.resolve()
        }
      }
    ],
    [dataSource, order]
  )

  return (
    <Form initialValues={{ name }} onFinish={onSave} layout="horizontal">
      {(_, form) => (
        <Row gutter={[16, 0]} wrap={false} justify="start">
          <Col className={styles.formItem}>
            <Form.Item name="name" className={styles.formInput} rules={validation}>
              <Input name="name" autoFocus={true} />
            </Form.Item>
          </Col>
          <Col>
            <Row gutter={[16, 0]} wrap={false}>
              <Button
                htmlType="submit"
                type="primary"
                disabled={!form.isFieldTouched('name') || !!form.getFieldError('name').length}
              >
                OK
              </Button>

              <Button type="link" onClick={onCancel}>
                Cancel
                <CloseCircleOutlined />
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  )
}
