import React from 'react'
import { Col, Row } from 'antd'

import { useAppSelector } from 'App/hooks'

import { Item } from '../Item'

export const SubscriptionPlanList = () => {
  const { ids } = useAppSelector((state) => state.subscriptionPlan)

  return (
    <Row gutter={[0, 24]}>
      {ids.map((id) => (
        <Col xs={24} key={id}>
          <Item id={id} />
        </Col>
      ))}
    </Row>
  )
}
