export const GET_PROFESSION_LIST = 'GET_PROFESSION_LIST'
export const GET_PROFESSION_LIST_REQUEST = 'GET_PROFESSION_LIST_REQUEST'
export const GET_PROFESSION_LIST_SUCCESS = 'GET_PROFESSION_LIST_SUCCESS'
export const GET_PROFESSION_LIST_ERROR = 'GET_PROFESSION_LIST_ERROR'

export const PATCH_PROFESSION_LIST = 'PATCH_PROFESSION_LIST'
export const PATCH_PROFESSION_LIST_REQUEST = 'PATCH_PROFESSION_LIST_REQUEST'
export const PATCH_PROFESSION_LIST_SUCCESS = 'PATCH_PROFESSION_LIST_SUCCESS'
export const PATCH_PROFESSION_LIST_ERROR = 'PATCH_PROFESSION_LIST_ERROR'

export const RESET_PROFESSION_LIST = 'RESET_PROFESSION_LIST'
