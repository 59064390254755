import { Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import { blue } from '@ant-design/colors'
import { ROUTES } from 'routes/constants'

import { IBusinessAccountsItemSuperadmin } from 'interfaces'

export const FullName: TAnt.TableColumnRender<IBusinessAccountsItemSuperadmin> = (superadmin) => (
  <NavLink to={`${ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS}/${superadmin?.id ?? ''}`}>
    <Typography.Text
      ellipsis={{ tooltip: superadmin?.name }}
      style={{ width: 180, color: blue.primary, textDecoration: 'underline' }}
    >
      {superadmin?.name}
    </Typography.Text>
  </NavLink>
)
