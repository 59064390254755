import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { EditableEnvConfig } from '@medentee/enums'

import { useAppSelector } from 'App'
import { getIntegerFormValue } from 'utils/form'
import { DefaultFormCard } from 'App/common'
import { UPDATE_DEFAULT_VARIABLES } from 'store'

import { TCommonDefaultVariablesProps } from '../../'

export const DefaultVariablesMDT = ({ onFormSubmit }: TCommonDefaultVariablesProps) => {
  const defaultVariables = useAppSelector((state) => state.defaultVariables)

  return (
    <DefaultFormCard
      title="Auto-generate MDT"
      onFormSubmit={onFormSubmit}
      loadingActionType={UPDATE_DEFAULT_VARIABLES}
      initialValues={{
        [EditableEnvConfig.AUTO_GENERATE_THRESHOLD]:
          defaultVariables[EditableEnvConfig.AUTO_GENERATE_THRESHOLD]?.value,
        [EditableEnvConfig.AUTO_GENERATE_AMOUNT]:
          defaultVariables[EditableEnvConfig.AUTO_GENERATE_AMOUNT]?.value
      }}
    >
      {(disabled) => (
        <Row gutter={[20, 0]} align="bottom">
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.AUTO_GENERATE_THRESHOLD}
              label="Auto-generate MDT: Threshold, MDT"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.AUTO_GENERATE_THRESHOLD]?.min,
                  max: defaultVariables[EditableEnvConfig.AUTO_GENERATE_THRESHOLD]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.AUTO_GENERATE_THRESHOLD]?.min
                  } and ${defaultVariables[EditableEnvConfig.AUTO_GENERATE_THRESHOLD]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.AUTO_GENERATE_AMOUNT}
              label="Auto-generate MDT: Amount, MDT"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.AUTO_GENERATE_AMOUNT]?.min,
                  max: defaultVariables[EditableEnvConfig.AUTO_GENERATE_AMOUNT]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.AUTO_GENERATE_AMOUNT]?.min
                  } and ${defaultVariables[EditableEnvConfig.AUTO_GENERATE_AMOUNT]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
