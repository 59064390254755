import { useEffect } from 'react'
import { Col, Row, Skeleton, Typography } from 'antd'

import { useAppDispatch, useAppSelector } from 'App/hooks'
import {
  createLoadingSelector,
  getMDTBalanceDetailsRequest,
  getMDTPlatformBalanceSelector,
  GET_MDT_BALANCE_DETAILS
} from 'store'

const loadingSelector = createLoadingSelector([GET_MDT_BALANCE_DETAILS])

export const PlatformBalance = () => {
  const dispatch = useAppDispatch()
  const platformBalance = useAppSelector(getMDTPlatformBalanceSelector)
  const loading = useAppSelector(loadingSelector)

  useEffect(() => {
    dispatch(getMDTBalanceDetailsRequest())
  }, [dispatch])

  return (
    <Row align="middle">
      <Col flex={2}>
        <Typography.Text>Platform balance as of now:</Typography.Text>
      </Col>
      <Col flex={3}>
        <Skeleton active title={{ style: { margin: 0 } }} loading={loading} paragraph={false}>
          <Typography.Text strong>{platformBalance} MDT</Typography.Text>
        </Skeleton>
      </Col>
    </Row>
  )
}
