import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ROUTES } from 'routes/constants'

import {
  createLoadingSelector,
  getUserProfileRequest,
  GET_USER_PROFILE,
  TUserProfileBusiness
} from 'store'
import { useAppSelector } from 'App'
import { Spinner } from 'App/common'

import { UserProfileHeader, UserProfileBusiness } from '../components'

import styles from './UserProfile.module.scss'

const loadingSelector = createLoadingSelector([GET_USER_PROFILE])

export const BusinessAccountInfo: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const isLoading = useSelector(loadingSelector)
  const data = useAppSelector((state) => state.userManagement.userProfile.data)

  useEffect(() => {
    dispatch(getUserProfileRequest(id))
  }, [dispatch, id])

  return (
    <div className={styles.root}>
      <UserProfileHeader
        name={data?.name}
        balance={data?.balance}
        id={id}
        type={data?.type.id}
        breadcrumb={[
          {
            text: 'Home'
          },
          {
            text: 'User management'
          },
          {
            text: 'Business accounts',
            link: ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS
          },
          {
            text: data?.name ?? ''
          }
        ]}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        data && <UserProfileBusiness data={data as TUserProfileBusiness} />
      )}
    </div>
  )
}
