import { Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'routes/constants'

export const Professional: TAnt.TableColumnRender<string> = (professionalId) =>
  professionalId ? (
    <NavLink to={`${ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS}/${professionalId}`}>Yes</NavLink>
  ) : (
    <Typography.Text disabled={true}>No</Typography.Text>
  )
