import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Breadcrumb as OriginalBreadcrumb } from 'antd'

import styles from './Breadcrumb.module.scss'

export type TBreadcrumbProps = {
  items: { text: string; link?: string }[]
}

export const Breadcrumb: FC<TBreadcrumbProps> = (props) => {
  const { items } = props

  return (
    <OriginalBreadcrumb className={styles.root}>
      {items.map(({ text, link }, index) => (
        <OriginalBreadcrumb.Item key={index}>
          {link ? <NavLink to={link}>{text}</NavLink> : text}
        </OriginalBreadcrumb.Item>
      ))}
    </OriginalBreadcrumb>
  )
}
