import { ItemsListNamesEnum } from '@medentee/enums'

import { TError } from 'store/store.types'
import { actionCreator } from 'store/store.utils'

import * as actionTypes from './professionList.actionTypes'
import {
  TGetProfessionListSuccessPayload,
  TPatchProfessionListRequestPayload
} from './professionList.types'

export const getProfessionListRequest = () =>
  actionCreator(actionTypes.GET_PROFESSION_LIST_REQUEST, {
    type: ItemsListNamesEnum.PROFESSIONS_LIST
  })
export const getProfessionListSuccess = (payload: TGetProfessionListSuccessPayload) =>
  actionCreator(actionTypes.GET_PROFESSION_LIST_SUCCESS, payload)
export const getProfessionListError = (e: TError) =>
  actionCreator(actionTypes.GET_PROFESSION_LIST_ERROR, e)

export const patchProfessionListRequest = (payload: TPatchProfessionListRequestPayload) =>
  actionCreator(actionTypes.PATCH_PROFESSION_LIST_REQUEST, payload)
export const patchProfessionListSuccess = () =>
  actionCreator(actionTypes.PATCH_PROFESSION_LIST_SUCCESS)
export const patchProfessionListError = (e: TError) =>
  actionCreator(actionTypes.PATCH_PROFESSION_LIST_ERROR, e)

export const resetProfessionList = () => actionCreator(actionTypes.RESET_PROFESSION_LIST)
