import { TError } from 'store/store.types'
import { actionCreator } from 'store/store.utils'

import {
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_ERROR,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  RESET_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN
} from './subscriptionPlan.actionTypes'
import {
  TSetSubscriptionPlanRequestPayload,
  TSetSubscriptionPlanSuccessPayload,
  TUpdateSubscriptionPlanPayload
} from './subscriptionPlans.types'

export const getSubscriptionPlanRequest = (payload: TSetSubscriptionPlanRequestPayload) =>
  actionCreator(GET_SUBSCRIPTION_PLAN_REQUEST, payload)
export const setSubscriptionPlanSuccess = (payload: TSetSubscriptionPlanSuccessPayload) =>
  actionCreator(GET_SUBSCRIPTION_PLAN_SUCCESS, payload)
export const setSubscriptionPlanError = (e: TError) => actionCreator(GET_SUBSCRIPTION_PLAN_ERROR, e)

export const updateSubscriptionPlan = (payload: TUpdateSubscriptionPlanPayload) =>
  actionCreator(UPDATE_SUBSCRIPTION_PLAN, payload)

export const resetSubscriptionPlan = () => actionCreator(RESET_SUBSCRIPTION_PLAN)
