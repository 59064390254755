import React, { useCallback, useEffect } from 'react'
import { Button, Card, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'

import { REGEXP_RULES } from 'utils'
import { useAppSelector } from 'App'
import { createProcessingSelector, addAdminRequest, ADD_ADMIN } from 'store'

import styles from './AddAdmin.module.scss'

const processingSelector = createProcessingSelector([ADD_ADMIN])

export const AddAdmin = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const processing = useAppSelector(processingSelector)
  const resetForm = useAppSelector((state) => state.adminManagement.addAdmin.resetForm)

  const handleSubmit = useCallback((values) => dispatch(addAdminRequest(values)), [dispatch])

  useEffect(() => {
    if (resetForm) {
      form.resetFields()
    }
  }, [form, resetForm])

  return (
    <Card title="Add Medentee admin">
      <Form onFinish={handleSubmit} className={styles.form} form={form}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Required' },
            { pattern: REGEXP_RULES.email, message: 'Invalid email!' },
            { max: 254, message: 'Max 254 characters' }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="firstName"
          rules={[
            { required: true, message: 'Required' },
            { pattern: REGEXP_RULES.personName, message: 'Invalid first name!' },
            { max: 50, message: 'Max 50 characters' }
          ]}
        >
          <Input placeholder="First name" />
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[
            { required: true, message: 'Required' },
            { pattern: REGEXP_RULES.personName, message: 'Invalid last name!' },
            { max: 50, message: 'Max 50 characters' }
          ]}
        >
          <Input placeholder="Last name" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Required' },
            { pattern: REGEXP_RULES.password, message: 'Invalid password!' }
          ]}
        >
          <Input placeholder="Set password" />
        </Form.Item>

        <Form.Item className={styles.buttonItem}>
          <Button loading={processing} type="primary" htmlType="submit">
            Add Admin
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}
