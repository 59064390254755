import * as actionTypes from './companyTypeList.actionTypes'
import { TCompanyTypeListState, TCompanyTypeListAction } from './companyTypeList.types'

const initialState: TCompanyTypeListState = {
  list: []
}

export const defaultCompanyTypeListReducer = (
  state = initialState,
  action: TCompanyTypeListAction
) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.RESET_COMPANY_TYPE_LIST: {
      return initialState
    }

    default:
      return state
  }
}
