import { Card, Col, Row } from 'antd'

import { TIds } from 'store'

import { InputWrapper, PlatformBalance } from './components'

export type TAirdropProps = PropsWithClassName<{
  selectedIds: TIds
}>

export const Airdrop = ({ className, selectedIds }: TAirdropProps) => (
  <Card className={className} bordered={false}>
    <Row align="middle" justify="center">
      <Col xxl={16} xl={22} lg={24} md={24} sm={24} xs={24}>
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col xl={12} lg={12} md={14} sm={24} xs={24}>
            <InputWrapper selectedIds={selectedIds} />
          </Col>
          <Col lg={8} md={10} sm={24} xs={24}>
            <PlatformBalance />
          </Col>
        </Row>
      </Col>
    </Row>
  </Card>
)
