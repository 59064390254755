export const REGEXP_RULES = {
  personName: /^[a-zA-Z'\-\s]+$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[<>~`!@#$%^&*()+=_\-{}]).{8,16}$/,
  digits: /^\d+$/,
  price: /^(?:[1-9][0-9]*|0)$/,
  attachmentLink:
    // eslint-disable-next-line no-useless-escape
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  zip: /^[0-9a-zA-Z-]+$/,
  companyName: /^[0-9a-zA-Z'"-]+(\s[0-9a-zA-Z'"-]+)?$/,
  onlySpaces: /^\s+$/,
  //latin characters +  " / - , "  + no double space
  // eslint-disable-next-line no-useless-escape
  specialSymbolsWithLatin: /^(?!.*\s{2,})(?!^ {2})[A-Za-z'\-\/\,\s]+$/
}
