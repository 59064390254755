import { useCallback } from 'react'
import { Card } from 'antd'

import { DateRange, TDateRangeApply, useAppDispatch, useAppSelector } from 'App'
import { createLoadingSelector, getPersonalAccountsRequest, GET_PERSONAL_ACCOUNTS } from 'store'

const loadingSelector = createLoadingSelector([GET_PERSONAL_ACCOUNTS])

export const PersonalAccountsFilterDateRange = ({ className = '' }: PropsWithClassName) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)

  const handleApply: TDateRangeApply = useCallback(
    (from, to) => {
      if (to && from) {
        dispatch(
          getPersonalAccountsRequest({
            current: 0,
            from: from.dateString,
            to: to.dateString
          })
        )
      }
    },
    [dispatch]
  )

  return (
    <Card className={className}>
      <DateRange onApply={handleApply} loading={loading} />
    </Card>
  )
}
