import { put, takeLatest, call, select } from 'redux-saga/effects'

import { API, api, APIData } from 'services/api'
import { IPersonalAccountsDetailsDTO, IPersonalAccountsDTO } from 'interfaces'
import { getPersonalAccountsDetailsSuccess, TPersonalAccountsFilters } from 'store'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'globalConstants'
import { QueryBuilder } from 'utils'
import { State } from 'redux/rootReducer'

import {
  getPersonalAccountsDetailsError,
  getPersonalAccountsError,
  getPersonalAccountsRequest,
  getPersonalAccountsSuccess
} from './personalAccounts.actions'
import * as actionTypes from './personalAccounts.actionTypes'

function* getPersonalAccountsDetailsSaga() {
  try {
    const { data }: APIData<IPersonalAccountsDetailsDTO> = yield call(
      api.get,
      API.PERSONAL_ACCOUNTS_DETAILS
    )

    yield put(getPersonalAccountsDetailsSuccess(data))
  } catch (e: any) {
    yield put(getPersonalAccountsDetailsError(e))
  }
}

function* getPersonalAccountsSaga({ payload }: ReturnType<typeof getPersonalAccountsRequest>) {
  try {
    const filters: TPersonalAccountsFilters = yield select(
      (state: State) => state.userManagement.personalAccounts.filters
    )

    const {
      current = PAGINATION_DEFAULT_PAGE,
      showBy = PAGINATION_DEFAULT_SHOW_BY,
      from = filters.from ?? '',
      to = filters.to ?? '',
      sorting = filters?.sorting,
      search = filters.search,
      businessAccountRoles = filters.businessAccountRoles ?? []
    } = payload

    const url = new QueryBuilder(API.PERSONAL_ACCOUNTS)
      .page(current)
      .showBy(showBy)
      .custom('from', from)
      .custom('to', to)
      .sort(sorting?.type, sorting?.by)
      .searchQuery(search?.query)
      .searchBy(search?.by)
      .stringify({ businessAccountRoles }, { arrayFormat: 'none' })
      .build()

    const {
      data: { results, total }
    }: APIData<IPersonalAccountsDTO> = yield call(api.get, url)

    yield put(
      getPersonalAccountsSuccess({
        data: results,
        filters: {
          total,
          current,
          showBy,
          to,
          from,
          search,
          sorting,
          businessAccountRoles
        }
      })
    )
  } catch (e: any) {
    yield put(getPersonalAccountsError(e))
  }
}

export function* personalAccountsSaga() {
  yield takeLatest(
    actionTypes.GET_PERSONAL_ACCOUNTS_DETAILS_REQUEST,
    getPersonalAccountsDetailsSaga
  )
  yield takeLatest(actionTypes.GET_PERSONAL_ACCOUNTS_REQUEST, getPersonalAccountsSaga)
}
