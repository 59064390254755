import { useEffect } from 'react'

import {
  createLoadingSelector,
  getConnectorPurposesRequest,
  GET_CONNECTOR_PURPOSES,
  patchConnectorPurposesRequest,
  resetConnectorPurposes
} from 'store'
import { EModalComponents, useAppDispatch, useAppSelector, useModal } from 'App'
import { Spinner } from 'App/common'
import { TableList, TOnVisibilityChange } from 'App/components/TableList'
import { IEntityDTO } from 'interfaces/common'
import { EVisibilityDataType } from 'App/components/TableList/VisibilityDialog'

const loadingSelector = createLoadingSelector([GET_CONNECTOR_PURPOSES])

export const ConnectorPurposesList = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const purposesList = useAppSelector((state) => state.connectorPurposes.list)

  const { showModal } = useModal()

  useEffect(() => {
    dispatch(getConnectorPurposesRequest())

    return () => {
      dispatch(resetConnectorPurposes())
    }
  }, [dispatch])

  const handleSave = (data: IEntityDTO[]) => {
    dispatch(patchConnectorPurposesRequest({ items: data }))
  }

  const onVisibilityChange: TOnVisibilityChange = (isHidden, onConfirm) => {
    showModal(EModalComponents.CONFIRM_VISIBILITY_CHANGE, {
      isHidden,
      onConfirm,
      type: EVisibilityDataType.CONNECTOR_PURPOSE
    })
  }

  if (loading && !purposesList.length) {
    return <Spinner />
  }

  return (
    <TableList
      title="Connector`s purposes"
      loading={loading}
      list={purposesList}
      onSave={handleSave}
      onVisibilityChange={onVisibilityChange}
    />
  )
}
