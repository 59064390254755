import { call, put, takeLatest } from '@redux-saga/core/effects'

import { api, API } from 'services'
import {
  GET_DEFAULT_VARIABLES_REQUEST,
  UPDATE_DEFAULT_VARIABLES_REQUEST,
  getDefaultVariablesError,
  getDefaultVariablesSuccess,
  updateDefaultVariablesSuccess,
  updateDefaultVariablesError,
  updateDefaultVariablesRequest
} from 'store'

export function* getDefaultVariablesSaga() {
  try {
    const { data } = yield call(api, API.DEFAULT_VARIABLES)

    yield put(getDefaultVariablesSuccess(data))
  } catch (e: any) {
    yield put(getDefaultVariablesError(e))
  }
}

export function* updateDefaultVariablesSaga(
  action: ReturnType<typeof updateDefaultVariablesRequest>
) {
  try {
    yield call(api, API.UPDATE_DEFAULT_VARIABLES, {
      method: 'PATCH',
      data: action.payload
    })

    yield put(updateDefaultVariablesSuccess())
  } catch (e: any) {
    yield put(updateDefaultVariablesError(e))
  }
}

export function* defaultVariablesSaga() {
  yield takeLatest(GET_DEFAULT_VARIABLES_REQUEST, getDefaultVariablesSaga)
  yield takeLatest(UPDATE_DEFAULT_VARIABLES_REQUEST, updateDefaultVariablesSaga)
}
