export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION'
export const CHECK_AUTHORIZATION_REQUEST = 'CHECK_AUTHORIZATION_REQUEST'
export const CHECK_AUTHORIZATION_SUCCESS = 'CHECK_AUTHORIZATION_SUCCESS'
export const CHECK_AUTHORIZATION_ERROR = 'CHECK_AUTHORIZATION_ERROR'

export const GET_INITIALIZE_APP = 'GET_INITIALIZE_APP'
export const GET_INITIALIZE_APP_REQUEST = 'GET_INITIALIZE_APP_REQUEST'
export const GET_INITIALIZE_APP_SUCCESS = 'GET_INITIALIZE_APP_SUCCESS'
export const GET_INITIALIZE_APP_ERROR = 'GET_INITIALIZE_APP_ERROR'

export const GET_FOCUS_IN_BROWSER = 'GET_FOCUS_IN_BROWSER'
export const LOST_FOCUS_IN_BROWSER = 'LOST_FOCUS_IN_BROWSER'

export const LOGIN = 'LOGIN'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

export const LOGOUT = 'LOGOUT'
