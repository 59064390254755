import { useCallback, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FinancialOperationTypeEnum } from '@medentee/enums'
import isArray from 'lodash/isArray'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

import { IMDTBalanceHistoryItemDTO } from 'interfaces'
import { useAdaptiveLayout, useAppSelector } from 'App'
import { createLoadingSelector, GET_MDT_BALANCE_HISTORY, getMDTBalanceHistoryRequest } from 'store'
import { showPaginationTotal, sorterConverter } from 'utils'

import { DateTime, MDTAmount, Operation } from './components'

const loadingSelector = createLoadingSelector([GET_MDT_BALANCE_HISTORY])

const getDateRangeDefaultValue = () => ({
  to: moment().endOf('day').toISOString(),
  from: moment().startOf('month').toISOString()
})

const columns: ColumnsType<IMDTBalanceHistoryItemDTO> = [
  {
    title: 'Date and time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: DateTime
  },
  {
    title: 'Operation ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    render: Operation,
    filters: Object.values(FinancialOperationTypeEnum).map((item) => ({
      text: capitalize(item.replace('_', ' ')),
      value: item
    }))
  },
  {
    title: 'Account ID',
    dataIndex: 'userId',
    key: 'userId',
    align: 'right',
    sorter: true
  },
  {
    title: 'MDT Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    sorter: true,
    render: MDTAmount
  }
]

export const BalanceHistory = () => {
  const dispatch = useDispatch()
  const { isMobile } = useAdaptiveLayout()
  const loading = useAppSelector(loadingSelector)
  const { ids, list } = useAppSelector((state) => state.mdtBalance.balance.history)
  const { current, showBy, total } = useAppSelector(
    (state) => state.mdtBalance.balance.history.filters
  )
  const showTotal = useCallback(showPaginationTotal, [])

  const handleTableChange: TAnt.TableOnChange<IMDTBalanceHistoryItemDTO> = useCallback(
    ({ current: currentPage, pageSize }, filters, sorter, { action }) => {
      const page = currentPage && currentPage > 0 ? currentPage - 1 : 0
      let sorting

      if (!isArray(sorter) && sorter.columnKey && sorter.order) {
        sorting = {
          by: String(sorter.columnKey),
          type: sorterConverter(sorter.order)
        }
      }

      dispatch(
        getMDTBalanceHistoryRequest({
          current: action !== 'paginate' ? 0 : page,
          showBy: pageSize,
          types: filters?.operation ?? [],
          sorting
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    const { from, to } = getDateRangeDefaultValue()

    dispatch(
      getMDTBalanceHistoryRequest({
        current: 0,
        to,
        from
      })
    )
  }, [dispatch])

  const dataSource = useMemo(
    () =>
      ids.map((id) => ({
        key: id,
        ...list[id]
      })),
    [ids, list]
  )

  return (
    <Card bordered={false}>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          responsive: true,
          total,
          defaultPageSize: showBy,
          current: current + 1,
          showTotal: isMobile ? undefined : showTotal
        }}
      />
    </Card>
  )
}
