import React, { HTMLAttributes } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export const Row = (props: HTMLAttributes<HTMLTableRowElement>) => {
  const id = props.hasOwnProperty('data-row-key') ? (props['data-row-key'] as string) : ''

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
}
