import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { clearPersonalAccountsFilter } from 'store'
import {
  PersonalAccountsFilter,
  PersonalAccountsList,
  PersonalAccountsFilterDateRange,
  PersonalAccountsInvite
} from 'App'

import styles from './PersonalAccounts.module.scss'

export const PersonalAccounts = () => {
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(clearPersonalAccountsFilter())
    },
    [dispatch]
  )

  return (
    <div className={styles.root}>
      <PersonalAccountsFilter className={styles.indent} />
      <PersonalAccountsInvite className={styles.indent} />
      <PersonalAccountsFilterDateRange className={styles.indent} />
      <PersonalAccountsList />
    </div>
  )
}
