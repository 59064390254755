const config = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',

  apiUrl: process.env.REACT_APP_API_URL || '',
  generalApiUrl: process.env.REACT_APP_GENERAL_API_URL || ''
}

export default config
