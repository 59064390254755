import React, { FC } from 'react'
import { Card } from 'antd'

export type TCasesInfoProps = {
  data: {
    totalCasesCount: number
    ownedCasesCount: number
    involvedCasesCount: number
  }
}

export const CasesInfo: FC<TCasesInfoProps> = (props) => {
  const { data } = props

  return (
    <Card bordered={false}>
      <dl>
        <dt>
          <b>Quantity of cases:</b>
        </dt>
        <dd>{data.totalCasesCount}</dd>
        <dt>as owner:</dt>
        <dd>{data.ownedCasesCount}</dd>
        <dt>as member:</dt>
        <dd>{data.involvedCasesCount}</dd>
      </dl>
    </Card>
  )
}
