import { Typography } from 'antd'
import { blue } from '@ant-design/colors'
import { OperationHistoricalTypeEnum } from '@medentee/enums'

import { IMDTBalanceHistoryItemDTO } from 'interfaces'

export const MDTAmount: TAnt.TableColumnRender<number, IMDTBalanceHistoryItemDTO> = (
  amount,
  record
) => (
  <Typography.Text style={{ color: blue.primary }}>
    {record.type === OperationHistoricalTypeEnum.INCOME ? '+' : '-'}
    {amount}
  </Typography.Text>
)
