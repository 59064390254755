import { put, takeLatest, call, select } from 'redux-saga/effects'

import { API, api, APIData } from 'services/api'
import { IBusinessAccountsDetailsDTO, IBusinessAccountsDTO } from 'interfaces'
import { getBusinessAccountsDetailsSuccess, TPersonalAccountsFilters } from 'store'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'globalConstants'
import { QueryBuilder } from 'utils'
import { State } from 'redux/rootReducer'

import * as actionTypes from './businessAccounts.actionTypes'
import {
  getBusinessAccountsDetailsError,
  getBusinessAccountsError,
  getBusinessAccountsRequest,
  getBusinessAccountsSuccess
} from './businessAccounts.actions'

function* getBusinessAccountsDetailsSaga() {
  try {
    const { data }: APIData<IBusinessAccountsDetailsDTO> = yield call(
      api.get,
      API.BUSINESS_ACCOUNTS_DETAILS
    )

    yield put(getBusinessAccountsDetailsSuccess(data))
  } catch (e: any) {
    yield put(getBusinessAccountsDetailsError(e))
  }
}

function* getBusinessAccountsSaga({ payload }: ReturnType<typeof getBusinessAccountsRequest>) {
  try {
    const filters: TPersonalAccountsFilters = yield select(
      (state: State) => state.userManagement.businessAccounts.filters
    )

    const {
      current = PAGINATION_DEFAULT_PAGE,
      showBy = PAGINATION_DEFAULT_SHOW_BY,
      from = filters.from ?? '',
      to = filters.to ?? '',
      sorting = filters?.sorting,
      search = filters.search
    } = payload

    const url = new QueryBuilder(API.BUSINESS_ACCOUNTS)
      .page(current)
      .showBy(showBy)
      .custom('from', from)
      .custom('to', to)
      .custom('orderDirection', sorting?.type)
      .searchQuery(search?.query)
      .searchBy(search?.by)
      .build()

    const {
      data: { results, total }
    }: APIData<IBusinessAccountsDTO> = yield call(api.get, url)

    yield put(
      getBusinessAccountsSuccess({
        data: results,
        filters: {
          total,
          current,
          showBy,
          to,
          from,
          search,
          sorting
        }
      })
    )
  } catch (e: any) {
    yield put(getBusinessAccountsError(e))
  }
}

export function* businessAccountsSaga() {
  yield takeLatest(
    actionTypes.GET_BUSINESS_ACCOUNTS_DETAILS_REQUEST,
    getBusinessAccountsDetailsSaga
  )
  yield takeLatest(actionTypes.GET_BUSINESS_ACCOUNTS_REQUEST, getBusinessAccountsSaga)
}
