import { AccountTypeIdEnum } from '@medentee/enums'

import { TIds } from 'store/store.types'

import * as actionCreators from './subscriptionPlan.actions'

export enum ESubscriptionPlanName {
  BASIC = 'Basic',
  PROFESSIONAL = 'Professional',
  BUSINESS = 'Business'
}

export type TDefaultSubscriptionPlan = {
  id: string
  name: ESubscriptionPlanName
  price: number
  businessAdminLimit: number
  caseMembersDirectLimit: number
  caseMembersGroupLimit: number
  medCloudCapacity: string
  medCloudMaxUploadFileSize: string
  accountTypeId: AccountTypeIdEnum
}

export type TSubscriptionPlanList = Record<string, TDefaultSubscriptionPlan>

export type TSubscriptionPlanNormalizeResult = {
  entities: {
    list: TSubscriptionPlanList
  }
  result: TIds
}

export type TDefaultSubscriptionPlanAction = ReturnType<TInferValueTypes<typeof actionCreators>>

export type TDefaultSubscriptionPlanState = {
  list: TSubscriptionPlanList
  ids: TIds
}

export type TSetSubscriptionPlanRequestPayload = { accountTypeId: AccountTypeIdEnum }
export type TSetSubscriptionPlanSuccessPayload = TDefaultSubscriptionPlanState
export type TUpdateSubscriptionPlanPayload = { variables: Record<string, string | undefined> }
