export type TSetPageVisibilityFocusCallbacks = (
  getFocus: () => void,
  lostFocus: () => void
) => boolean

export const setPageVisibilityFocusCallbacks: TSetPageVisibilityFocusCallbacks = (
  getFocus,
  lostFocus
) => {
  let paramName: null | string = null
  let eventName: null | string = null

  // Generic handler
  if (typeof document.hidden !== 'undefined') {
    paramName = 'hidden'
    eventName = 'visibilitychange'
  }

  // FALLBACK
  // Mozilla-based
  else if (typeof (document as any).mozHidden !== 'undefined') {
    paramName = 'mozHidden'
    eventName = 'mozvisibilitychange'
  }
  // IE-based
  else if (typeof (document as any).msHidden !== 'undefined') {
    paramName = 'msHidden'
    eventName = 'msvisibilitychange'
  }
  // WebKit-based
  else if (typeof (document as any).webkitHidden !== 'undefined') {
    paramName = 'webkitHidden'
    eventName = 'webkitvisibilitychange'
  }
  // Not Supported Page Visibility API
  else {
    paramName = null
    window.addEventListener('focus', getFocus, false)
    window.addEventListener('blur', lostFocus, false)
  }

  const listener = () => {
    if (paramName && (document as any)[paramName]) {
      lostFocus()
    } else {
      getFocus()
    }
  }

  // Add Callback if Supported Page Visibility API
  if (paramName && eventName) {
    listener()
    document.addEventListener(eventName, listener, false)

    return true // Reports that a handler has been installed
  }

  return false // Reports that a handler has not been installed
}
