import React, { FC } from 'react'
import { Card, Typography, Row, Col } from 'antd'
import { NavLink } from 'react-router-dom'
import { AccountTypeIdEnum } from '@medentee/enums'
import { ROUTES } from 'routes/constants'

import { DeleteAccountButton } from 'App/features/DeleteAccount'

import { Breadcrumb } from '../Breadcrumb'

import styles from './UserProfileHeader.module.scss'

export type TUserProfileHeaderProps = {
  name?: string
  balance?: number
  id?: string
  type?: AccountTypeIdEnum
  breadcrumb: { text: string; link?: string }[]
}

export const UserProfileHeader: FC<TUserProfileHeaderProps> = (props) => {
  const { breadcrumb, name, balance, children, id, type } = props

  return (
    <Card bordered={false} className={styles.root}>
      <Row gutter={[0, 16]} wrap>
        <Col xs={24}>
          <Breadcrumb items={breadcrumb} />
        </Col>
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Typography.Title level={4}>{name}</Typography.Title>
            <Row gutter={[16, 0]} align="middle">
              <Col>
                <NavLink
                  to={`${
                    type === AccountTypeIdEnum.BUSINESS
                      ? ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS
                      : ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS
                  }/${id}/balance`}
                >
                  {balance} MDT
                </NavLink>
              </Col>
              {id && type && (
                <Col>
                  <DeleteAccountButton id={id} accountType={type} />
                </Col>
              )}
            </Row>
          </Row>
        </Col>
      </Row>
      {children && <div className={styles.extraContent}>{children}</div>}
    </Card>
  )
}
