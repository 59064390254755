import { useCallback } from 'react'
import { Col, Row } from 'antd'

import { Search, TSearchByOption, useAppDispatch, useAppSelector } from 'App'
import { createLoadingSelector, getMDTAirdropHistoryRequest, GET_MDT_AIRDROP_HISTORY } from 'store'

const searchByOptions: TSearchByOption[] = [
  {
    label: 'Name',
    value: 'name'
  },
  {
    label: 'Location',
    value: 'location'
  }
]

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY])

export const SearchWrapper = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)

  const handleSearch = useCallback(
    (query: string, by: string) =>
      dispatch(
        getMDTAirdropHistoryRequest({
          current: 0,
          search: { query, by }
        })
      ),
    [dispatch]
  )

  return (
    <Row align="middle" justify="center">
      <Col lg={16} md={24} sm={24} xs={24}>
        <Search
          onSearch={handleSearch}
          searchByOptions={searchByOptions}
          searchByDefaultValue={searchByOptions[0].value}
          loading={loading}
        />
      </Col>
    </Row>
  )
}
