import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'

import { useModal } from 'App'
import { EModalComponents } from 'App/components/ModalRoot'

export const UserProfileBalanceAirdrop = () => {
  const { id: userId } = useParams<{ id: string }>()
  const { showModal } = useModal()

  const handleClick = () => showModal(EModalComponents.AIRDROP_TO_USER_DIALOG, { userId })

  return (
    <Button onClick={handleClick} size="large" type="primary">
      Airdrop
    </Button>
  )
}
