import { useCallback, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import debounce from 'lodash/debounce'

import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { getMDTAirdropHistoryRequest } from 'store'
import { useAppDispatch, InputRangeNumber } from 'App'

export const TotalNumberContacts = () => {
  const dispatch = useAppDispatch()

  const debouncedHandler = useMemo(
    () =>
      debounce((from?: number | null, to?: number | null) => {
        dispatch(
          getMDTAirdropHistoryRequest({
            current: 0,
            totalNumberOfContacts: { from, to }
          })
        )
      }, DEFAULT_THROTTLE_MS),
    [dispatch]
  )

  const handleChange = useCallback(debouncedHandler, [debouncedHandler])

  return (
    <Row align="middle" gutter={[0, 16]}>
      <Col lg={12} md={10} sm={10} xs={24}>
        <Typography.Text>Total number of contacts</Typography.Text>
      </Col>

      <Col lg={12} md={14} sm={14} xs={24}>
        <InputRangeNumber onChange={handleChange} />
      </Col>
    </Row>
  )
}
