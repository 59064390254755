export const GET_BUSINESS_ACCOUNTS_DETAILS = 'GET_BUSINESS_ACCOUNTS_DETAILS'
export const GET_BUSINESS_ACCOUNTS_DETAILS_REQUEST = 'GET_BUSINESS_ACCOUNTS_DETAILS_REQUEST'
export const GET_BUSINESS_ACCOUNTS_DETAILS_SUCCESS = 'GET_BUSINESS_ACCOUNTS_DETAILS_SUCCESS'
export const GET_BUSINESS_ACCOUNTS_DETAILS_ERROR = 'GET_BUSINESS_ACCOUNTS_DETAILS_ERROR'

export const GET_BUSINESS_ACCOUNTS = 'GET_BUSINESS_ACCOUNTS'
export const GET_BUSINESS_ACCOUNTS_REQUEST = 'GET_BUSINESS_ACCOUNTS_REQUEST'
export const GET_BUSINESS_ACCOUNTS_SUCCESS = 'GET_BUSINESS_ACCOUNTS_SUCCESS'
export const GET_BUSINESS_ACCOUNTS_ERROR = 'GET_BUSINESS_ACCOUNTS_ERROR'

export const CLEAR_BUSINESS_ACCOUNTS_FILTER = 'CLEAR_BUSINESS_ACCOUNTS_FILTER'
