import * as actionTypes from './inviteNewUser.actionTypes'
import { TInviteNewUserAction, TInviteNewUserState } from './inviteNewUser.types'

export const inviteNewUserState: TInviteNewUserState = {
  errors: {},
  professions: {
    ids: [],
    list: {}
  }
}

export const inviteNewUserReducer = (
  state: TInviteNewUserState = inviteNewUserState,
  action: TInviteNewUserAction
): TInviteNewUserState => {
  switch (action.type) {
    case actionTypes.SET_USER_EXISTS_ERROR: {
      return {
        ...state,
        errors: action.payload
      }
    }
    case actionTypes.GET_PROFESSIONS_SUCCESS: {
      return {
        ...state,
        professions: action.payload
      }
    }

    default:
      return state
  }
}
