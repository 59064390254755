export const GET_COMPANY_TYPE_LIST = 'GET_COMPANY_TYPES_LIST'
export const GET_COMPANY_TYPE_LIST_REQUEST = 'GET_COMPANY_TYPES_LIST_REQUEST'
export const GET_COMPANY_TYPE_LIST_SUCCESS = 'GET_COMPANY_TYPES_LIST_SUCCESS'
export const GET_COMPANY_TYPE_LIST_ERROR = 'GET_COMPANY_TYPES_LIST_ERROR'

export const PATCH_COMPANY_TYPE_LIST = 'PATCH_COMPANY_TYPES_LIST'
export const PATCH_COMPANY_TYPE_LIST_REQUEST = 'PATCH_COMPANY_TYPES_LIST_REQUEST'
export const PATCH_COMPANY_TYPE_LIST_SUCCESS = 'PATCH_COMPANY_TYPES_LIST_SUCCESS'
export const PATCH_COMPANY_TYPE_LIST_ERROR = 'PATCH_COMPANY_TYPES_LIST_ERROR'

export const RESET_COMPANY_TYPE_LIST = 'RESET_COMPANY_TYPE_LIST'
