import { normalize, schema } from 'normalizr'

import { TIds } from 'store/store.types'

export function normalizeList<T>(data: T[]): {
  list: Record<string, T>
  ids: TIds
} {
  const listSchema = new schema.Array(new schema.Entity('list'))

  const {
    entities: { list },
    result: ids
  } = normalize(data, listSchema)

  return {
    list: list || {},
    ids: ids || []
  }
}
