import React, { FC } from 'react'
import { Card, Col, Row } from 'antd'

import { UserProfileBalanceAirdrop } from '../UserProfileBalanceAirdrop'

import styles from './UserProfileBalance.module.scss'

export type TUserProfileBalanceProps = {
  data?: { totalIncome: number; totalExpense: number } | null
}

export const UserProfileBalance: FC<TUserProfileBalanceProps> = (props) => {
  const { data } = props

  return (
    <Card bordered={false}>
      <Row>
        <Col md={12} sm={24} xs={24}>
          <dl>
            <dt>
              <b>Balance:</b>
            </dt>
            <dd>{(data?.totalIncome ?? 0) - (data?.totalExpense ?? 0)}&nbsp;MDT</dd>
            <dt>Income in period:</dt>
            <dd>{data?.totalIncome ?? 0}&nbsp;MDT</dd>
            <dt>Expenses in period:</dt>
            <dd>{data?.totalExpense ?? 0}&nbsp;MDT</dd>
          </dl>
        </Col>

        <Col md={12} sm={24} xs={24} className={styles.airdrop}>
          <UserProfileBalanceAirdrop />
        </Col>
      </Row>
    </Card>
  )
}
