import React, { useEffect } from 'react'

import {
  createLoadingSelector,
  getCompanyTypeListRequest,
  GET_COMPANY_TYPE_LIST,
  patchCompanyTypeListRequest,
  resetCompanyTypeList
} from 'store'
import { EModalComponents, useAppDispatch, useAppSelector, useModal } from 'App'
import { Spinner } from 'App/common'
import { TableList, TOnVisibilityChange } from 'App/components/TableList'
import { IEntityDTO } from 'interfaces/common'
import { EVisibilityDataType } from 'App/components/TableList/VisibilityDialog'

const loadingSelector = createLoadingSelector([GET_COMPANY_TYPE_LIST])

export const CompanyTypeList = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const companyTypeList = useAppSelector((state) => state.companyTypeList.list)

  const { showModal } = useModal()

  useEffect(() => {
    dispatch(getCompanyTypeListRequest())
    return () => {
      dispatch(resetCompanyTypeList())
    }
  }, [dispatch])

  const handleSave = (data: IEntityDTO[]) => {
    dispatch(patchCompanyTypeListRequest({ items: data }))
  }

  const onVisibilityChange: TOnVisibilityChange = (isHidden, onConfirm) => {
    showModal(EModalComponents.CONFIRM_VISIBILITY_CHANGE, {
      isHidden,
      onConfirm,
      type: EVisibilityDataType.COMPANY_TYPE
    })
  }

  if (loading && !companyTypeList.length) {
    return <Spinner />
  }

  return (
    <TableList
      title="Company types"
      loading={loading}
      list={companyTypeList}
      onSave={handleSave}
      onVisibilityChange={onVisibilityChange}
    />
  )
}
