import { useEffect, useState } from 'react'
import { Col, InputNumber, Row } from 'antd'

import { useEvent } from 'App/hooks'

export type TInputRangeNumberProps = {
  onChange: (from?: number | null, to?: number | null) => void

  disabled?: boolean
}

export const InputRangeNumber = ({ disabled, onChange }: TInputRangeNumberProps) => {
  const [from, setFrom] = useState<number | undefined>()
  const [to, setTo] = useState<number | undefined>()

  const handleFromChange = useEvent((value: number) => {
    setFrom(value)
    onChange(value, to)
  })

  const handleToChange = useEvent((value: number) => {
    setTo(value)
    onChange(from, value)
  })

  useEffect(() => {
    if (disabled && to && from) {
      setTo(undefined)
      setFrom(undefined)

      onChange(null, null)
    }
  }, [disabled, from, onChange, to])

  return (
    <Row align="middle" wrap={false}>
      <Col xs={11}>
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          name="from"
          placeholder="1"
          value={from}
          disabled={disabled}
          onChange={handleFromChange}
        />
      </Col>
      <Col xs={2}>
        <Row align="middle" justify="center">
          -
        </Row>
      </Col>
      <Col xs={11}>
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          name="to"
          placeholder="10000"
          value={to}
          disabled={disabled}
          onChange={handleToChange}
        />
      </Col>
    </Row>
  )
}
