import { CHECK_AUTHORIZATION_SUCCESS, LOGIN_SUCCESS, TGlobalState, TStoreAction } from 'store'

import {
  CHECK_AUTHORIZATION_ERROR,
  GET_FOCUS_IN_BROWSER,
  LOGOUT,
  CHANGE_PASSWORD_SUCCESS,
  LOST_FOCUS_IN_BROWSER
} from './store.actionTypes'

export const initialGlobalState: TGlobalState = {
  browserFocus: false,
  accountData: null,
  token: localStorage.getItem('token') || null,
  isDefaultPasswordChanged: JSON.parse(localStorage.getItem('isDefaultPasswordChanged') || 'false')
}

const emptyState: TGlobalState = {
  browserFocus: false,
  accountData: null,
  token: null,
  isDefaultPasswordChanged: false
}

export const globalReducer = (state = initialGlobalState, action: TStoreAction) => {
  switch (action.type) {
    case CHECK_AUTHORIZATION_SUCCESS: {
      return {
        ...state,
        accountData: action.payload.accountData
      }
    }

    case CHECK_AUTHORIZATION_ERROR:
    case LOGOUT: {
      return emptyState
    }

    case GET_FOCUS_IN_BROWSER: {
      return {
        ...state,
        browserFocus: true
      }
    }

    case LOST_FOCUS_IN_BROWSER: {
      return {
        ...state,
        browserFocus: false
      }
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isDefaultPasswordChanged: true
      }
    }

    default:
      return state
  }
}
