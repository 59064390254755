import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { EditableEnvConfig } from '@medentee/enums'

import { useAppSelector } from 'App'
import { getIntegerFormValue } from 'utils/form'
import { DefaultFormCard } from 'App/common'
import { UPDATE_DEFAULT_VARIABLES } from 'store'

import { TCommonDefaultVariablesProps } from '../../'

export const DefaultVariablesInitialTopUp = ({ onFormSubmit }: TCommonDefaultVariablesProps) => {
  const defaultVariables = useAppSelector((state) => state.defaultVariables)

  return (
    <DefaultFormCard
      title="Initial Top up for new users account"
      loadingActionType={UPDATE_DEFAULT_VARIABLES}
      onFormSubmit={onFormSubmit}
      initialValues={{
        [EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]:
          defaultVariables[EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]?.value,
        [EditableEnvConfig.BUSINESS_INITIAL_BONUS]:
          defaultVariables[EditableEnvConfig.BUSINESS_INITIAL_BONUS]?.value
      }}
    >
      {(disabled) => (
        <Row gutter={[20, 0]} align="bottom">
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS}
              label="Initial Top up for new Professional, MDT"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]?.min,
                  max: defaultVariables[EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]?.min
                  } and ${defaultVariables[EditableEnvConfig.PROFESSIONAL_INITIAL_BONUS]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.BUSINESS_INITIAL_BONUS}
              label="Initial Top up for new Business, MDT"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.BUSINESS_INITIAL_BONUS]?.min,
                  max: defaultVariables[EditableEnvConfig.BUSINESS_INITIAL_BONUS]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.BUSINESS_INITIAL_BONUS]?.min
                  } and ${defaultVariables[EditableEnvConfig.BUSINESS_INITIAL_BONUS]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
