import React, { useEffect } from 'react'
import { Field, useFormState } from 'react-final-form'
import { Form as AntdForm, Input, InputNumber, Select, Typography } from 'antd'
import { useDispatch } from 'react-redux'
import { AccountTypeIdEnum } from '@medentee/enums'

import { InputError } from 'App/common'
import { useAppSelector } from 'App/hooks'
import {
  createLoadingSelector,
  getProfessionsRequest,
  GET_PROFESSIONS,
  professionOptionsSelector
} from 'store'
import { hasError, validation } from 'utils'

import { TInviteNewUserDialogValues } from '../../InviteNewUserDialog'

const MAX_GENERATION = 100
const MAX_LENGTH_NAME = 50

const MIN_GENERATION = 1
const MIN_LENGTH_NAME = 2

const GENERATION_ERROR_MESSAGE = `Must be between ${MIN_GENERATION} and ${MAX_GENERATION}`
const { Item } = AntdForm
const loadingSelector = createLoadingSelector([GET_PROFESSIONS])
const labelCol = { span: 24, offset: 0 }
const nameValidation = validation.composeValidators(
  validation.required(),
  validation.minLength(MIN_LENGTH_NAME),
  validation.maxLength(MAX_LENGTH_NAME),
  validation.isPersonName()
)

export const SecondStep = () => {
  const dispatch = useDispatch()
  const {
    values: { email, accountTypeId }
  } = useFormState<TInviteNewUserDialogValues>()
  const professions = useAppSelector(professionOptionsSelector)
  const professionsLoading = useAppSelector(loadingSelector)
  const isProfessional = accountTypeId === AccountTypeIdEnum.PROFESSIONAL

  useEffect(() => {
    if (!professions.length) {
      dispatch(getProfessionsRequest())
    }
  }, [dispatch, professions.length])

  return (
    <>
      <Item>
        <Typography.Text>Email*</Typography.Text>
        <br />
        <Typography.Text disabled>{email}</Typography.Text>
      </Item>

      <Field name="firstName" validate={nameValidation}>
        {({ input, meta }) => (
          <Item
            colon={false}
            labelCol={labelCol}
            label="First Name*"
            validateStatus={hasError(meta) ? 'error' : undefined}
            help={<InputError {...meta} />}
          >
            <Input placeholder="First Name" {...input} />
          </Item>
        )}
      </Field>

      <Field name="lastName" validate={nameValidation}>
        {({ input, meta }) => (
          <Item
            colon={false}
            labelCol={labelCol}
            label="Last Name*"
            validateStatus={hasError(meta) ? 'error' : undefined}
            help={<InputError {...meta} />}
          >
            <Input placeholder="Last Name" {...input} />
          </Item>
        )}
      </Field>

      {isProfessional && (
        <Field
          name="professionId"
          type="select"
          validate={validation.composeValidators(validation.required())}
        >
          {({ input, meta }) => (
            <Item
              colon={false}
              labelCol={labelCol}
              label="Profession*"
              validateStatus={hasError(meta) ? 'error' : undefined}
              help={<InputError {...meta} />}
            >
              <Select
                options={professions}
                showSearch
                optionFilterProp="label"
                {...input}
                loading={professionsLoading}
              />
            </Item>
          )}
        </Field>
      )}

      {isProfessional && (
        <Field
          name="generation"
          validate={validation.composeValidators(
            validation.required(GENERATION_ERROR_MESSAGE),
            validation.min(MIN_GENERATION, GENERATION_ERROR_MESSAGE),
            validation.max(MAX_GENERATION, GENERATION_ERROR_MESSAGE)
          )}
        >
          {({ input, meta }) => (
            <Item
              colon={false}
              labelCol={labelCol}
              label="Generation*"
              validateStatus={hasError(meta) ? 'error' : undefined}
              help={<InputError {...meta} />}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={MIN_GENERATION}
                max={MAX_GENERATION}
                placeholder="Generation"
                {...input}
              />
            </Item>
          )}
        </Field>
      )}
    </>
  )
}
