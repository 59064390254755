import { FC, ReactElement } from 'react'
import { List } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'

import { TBasicUserInfo } from 'store'
import { getUserProfileUrl } from 'utils/urls'

import styles from './UsersList.module.scss'

export type TUsersListProps = {
  data: TBasicUserInfo[]
}

export const UsersList: FC<TUsersListProps> = (props) => {
  const { data } = props

  const renderItem = (item: TBasicUserInfo) => {
    const infoIcon = <InfoCircleOutlined disabled={item.isSuperadmin} />
    const actions: ReactElement[] = []

    if (item.isSuperadmin) {
      actions.push(infoIcon)
    } else if (!item.isDeleted) {
      actions.push(<NavLink to={getUserProfileUrl(item)}>{infoIcon}</NavLink>)
    }

    return (
      <List.Item actions={actions}>
        <span>
          {`${item.name}${item.isSuperadmin ? ' (superadmin)' : ''}`}{' '}
          {item.isDeleted && <span className={styles.label}>(deleted account)</span>}
        </span>
      </List.Item>
    )
  }

  return <List className={styles.root} dataSource={data} renderItem={renderItem} />
}
