import React, { ReactNode } from 'react'
import { TooltipPlacement } from 'antd/lib/tooltip'
import cls from 'classnames'
import { Popover } from 'antd'

import type { ActionType } from 'rc-trigger/lib/interface'
import { ReactComponent as HelpCircleOutlineIcon } from 'assets/icons/HelpCircleOutline.svg'

import styles from './Help.module.scss'

type THelpProps = PropsWithClassName<{
  content: ReactNode

  placement?: TooltipPlacement
  trigger?: ActionType | ActionType[]
}>

export const Help = ({
  content,
  className,
  placement = 'topRight',
  trigger = 'hover'
}: THelpProps) => (
  <Popover
    align={{ offset: [16, -16] }}
    trigger={trigger}
    content={content}
    overlayClassName={cls(styles.root, className)}
    placement={placement}
  >
    <HelpCircleOutlineIcon className={styles.icon} />
  </Popover>
)
