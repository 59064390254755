import { Action } from 'store/store.types'

import { HIDE_MODAL, SHOW_MODAL, TShowModalAction } from './modal.actions'
import { TModalState } from './modal.types'

export const initialModalState: TModalState = {
  modalType: null,
  modalProps: null
}

export const modalReducer = (state: TModalState = initialModalState, action: Action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        ...(action as TShowModalAction).payload
      }
    }

    case HIDE_MODAL: {
      return {
        ...state,
        ...initialModalState
      }
    }

    default:
      return state
  }
}
