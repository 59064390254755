import { PAGINATION_DEFAULT } from 'globalConstants'
import { TBusinessAccountsState, TBusinessAccountsAction } from 'store'

import * as actionTypes from './businessAccounts.actionTypes'

export const initialBusinessAccountsState: TBusinessAccountsState = {
  details: { businessCount: 0 },
  filters: {
    ...PAGINATION_DEFAULT,
    from: '',
    to: ''
  },
  data: []
}

export const businessAccountsReducer = (
  state: TBusinessAccountsState = initialBusinessAccountsState,
  action: TBusinessAccountsAction
): TBusinessAccountsState => {
  switch (action.type) {
    case actionTypes.GET_BUSINESS_ACCOUNTS_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload
      }
    }

    case actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.CLEAR_BUSINESS_ACCOUNTS_FILTER: {
      return {
        ...state,
        filters: initialBusinessAccountsState.filters
      }
    }

    default:
      return state
  }
}
