import axios from 'axios'
import { message } from 'antd'

import config from 'config'
import { unAuthorizedRedirect, errorParsing } from 'utils'

let token = localStorage.getItem('token')

// Set config defaults when creating the instance
export const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      unAuthorizedRedirect()
    } else {
      message.error(errorParsing(error))
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use((requestConfig) => {
  if (requestConfig.headers) {
    requestConfig.headers.authorization = `Bearer ${token}`
  }

  return requestConfig
})

export const setupAxiosToken = (newToken: string) => {
  token = newToken
}
