import { ItemsListNamesEnum } from '@medentee/enums'

import { TError } from 'store/store.types'
import { actionCreator } from 'store/store.utils'

import * as actionTypes from './companyTypeList.actionTypes'
import {
  TGetCompanyTypeSuccessPayload,
  TPatchCompanyTypeListRequestPayload
} from './companyTypeList.types'

export const getCompanyTypeListRequest = () =>
  actionCreator(actionTypes.GET_COMPANY_TYPE_LIST_REQUEST, {
    type: ItemsListNamesEnum.COMPANY_TYPES
  })
export const getCompanyTypeListSuccess = (payload: TGetCompanyTypeSuccessPayload) =>
  actionCreator(actionTypes.GET_COMPANY_TYPE_LIST_SUCCESS, payload)
export const getCompanyTypeListError = (e: TError) =>
  actionCreator(actionTypes.GET_COMPANY_TYPE_LIST_ERROR, e)

export const patchCompanyTypeListRequest = (payload: TPatchCompanyTypeListRequestPayload) =>
  actionCreator(actionTypes.PATCH_COMPANY_TYPE_LIST_REQUEST, payload)
export const patchCompanyTypeListSuccess = () =>
  actionCreator(actionTypes.PATCH_COMPANY_TYPE_LIST_SUCCESS)
export const patchCompanyTypeListError = (e: TError) =>
  actionCreator(actionTypes.PATCH_COMPANY_TYPE_LIST_ERROR, e)

export const resetCompanyTypeList = () => actionCreator(actionTypes.RESET_COMPANY_TYPE_LIST)
