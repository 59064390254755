import { ReactNode } from 'react'
import { LocationDescriptor, Location } from 'history'
import { AccountTypeIdEnum } from '@medentee/enums'
import { ROUTES } from 'routes/constants'

import { ReactComponent as AppstoreIcon } from 'assets/icons/Appstore.svg'
import { ReactComponent as DashboardIcon } from 'assets/icons/Dashboard.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg'
import { ReactComponent as UserIcon } from 'assets/icons/User.svg'
import { ReactComponent as PoundIcon } from 'assets/icons/Pound.svg'

export enum ELayoutNavKey {
  DASHBOARD = 'DASHBOARD',
  MDT_BALANCE = 'MDT_BALANCE',
  VARIABLES = 'VARIABLES',
  ADMIN_MANAGEMENT = 'ADMIN_MANAGEMENT',
  USER_MANAGEMENT = 'USER_MANAGEMENT'
}

enum ELayoutNavVariablesKey {
  DEFAULT_VARIABLES = 'DEFAULT_VARIABLES',
  SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS',
  COMPANY_TYPES = 'COMPANY_TYPES',
  CONNECTOR_PURPOSES = 'CONNECTOR_PURPOSES',
  PROFESSION_LIST = 'PROFESSION_LIST'
}

enum ELayoutNavUserManagementKey {
  PERSONAL_ACCOUNTS = 'PERSONAL_ACCOUNTS',
  BUSINESS_ACCOUNTS = 'BUSINESS_ACCOUNTS'
}

enum ESubscriptionPlansNavKey {
  PROFESSIONAL = 'PROFESSIONAL',
  BUSINESS = 'BUSINESS'
}

enum EBalanceNavKey {
  BALANCE = 'BALANCE',
  AIRDROP = 'AIRDROP'
}

type TLayoutNavChildren = ELayoutNavUserManagementKey | ELayoutNavVariablesKey

type TLayoutNavConfigBaseProps = ELayoutNavKey | ESubscriptionPlansNavKey | EBalanceNavKey

export type TNavLinkTo =
  | LocationDescriptor<any>
  | ((location: Location<any>) => LocationDescriptor<any>)

export type TLayoutNavConfigBase<K = TLayoutNavConfigBaseProps> = {
  key: K
  exact: boolean
  label: string

  to?: TNavLinkTo
  icon?: ReactNode
  startsWith?: boolean
}

export type TLayoutNavConfig = TLayoutNavConfigBase & {
  children?: TLayoutNavConfigBase<TLayoutNavChildren>[]
}

export const asideNavConfig: TLayoutNavConfig[] = [
  {
    exact: true,
    startsWith: true,
    to: ROUTES.DASHBOARD,
    label: 'Dashboard',
    icon: <HomeIcon />,
    key: ELayoutNavKey.DASHBOARD
  },

  {
    exact: true,
    startsWith: true,
    to: ROUTES.MDT.DEFAULT,
    label: 'MDT balance',
    icon: <PoundIcon />,
    key: ELayoutNavKey.MDT_BALANCE
  },
  {
    exact: true,
    startsWith: true,
    label: 'User management',
    icon: <UserIcon />,
    key: ELayoutNavKey.USER_MANAGEMENT,
    children: [
      {
        exact: true,
        startsWith: true,
        label: 'Personal accounts',
        to: ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS,
        key: ELayoutNavUserManagementKey.PERSONAL_ACCOUNTS
      },
      {
        exact: true,
        startsWith: true,
        label: 'Business accounts',
        to: ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS,
        key: ELayoutNavUserManagementKey.BUSINESS_ACCOUNTS
      }
    ]
  },
  {
    exact: true,
    startsWith: true,
    label: 'Variables',
    icon: <AppstoreIcon />,
    key: ELayoutNavKey.VARIABLES,
    children: [
      {
        exact: true,
        startsWith: true,
        label: 'Default variables',
        to: ROUTES.VARIABLES.DEFAULT_VARIABLES,
        key: ELayoutNavVariablesKey.DEFAULT_VARIABLES
      },
      {
        exact: true,
        startsWith: true,
        label: 'Subscription plans',
        to: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.DEFAULT,
        key: ELayoutNavVariablesKey.SUBSCRIPTION_PLANS
      },
      {
        exact: true,
        startsWith: true,
        label: 'Company types',
        to: ROUTES.VARIABLES.COMPANY_TYPES,
        key: ELayoutNavVariablesKey.COMPANY_TYPES
      },
      {
        exact: true,
        startsWith: true,
        label: 'Connector’s purposes',
        to: ROUTES.VARIABLES.CONNECTOR_PURPOSES,
        key: ELayoutNavVariablesKey.CONNECTOR_PURPOSES
      },
      {
        exact: true,
        startsWith: true,
        label: 'Profession list',
        to: ROUTES.VARIABLES.PROFESSION_LIST,
        key: ELayoutNavVariablesKey.PROFESSION_LIST
      }
    ]
  },
  {
    exact: true,
    to: ROUTES.ADMIN_MANAGEMENT,
    label: 'Admin management',
    icon: <DashboardIcon />,
    key: ELayoutNavKey.ADMIN_MANAGEMENT
  }
]

export const subscriptionNavConfig: TLayoutNavConfig[] = [
  {
    to: {
      pathname: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.PROFESSIONAL,
      state: { accountTypeId: AccountTypeIdEnum.PROFESSIONAL }
    },
    label: 'Professional',
    exact: true,
    key: ESubscriptionPlansNavKey.PROFESSIONAL
  },
  {
    to: {
      pathname: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.BUSINESS,
      state: {
        accountTypeId: AccountTypeIdEnum.BUSINESS
      }
    },
    label: 'Business',
    exact: true,
    key: ESubscriptionPlansNavKey.BUSINESS
  }
]

export const balanceNavConfig: TLayoutNavConfig[] = [
  {
    to: ROUTES.MDT.BALANCE,
    label: 'Balance',
    exact: true,
    key: EBalanceNavKey.BALANCE
  },
  {
    to: ROUTES.MDT.AIRDROP,
    label: 'MDT Airdrop',
    exact: true,
    key: EBalanceNavKey.AIRDROP
  }
]
