import moment from 'moment'

import { BASE_STORAGE_UNIT } from 'globalConstants'

export const timeFormat = 'HH:mm'
export const yearFormat = 'YYYY'
export const dayFormat = 'DD'
export const stringMonthFormat = 'MMM'
export const datepickerFormat = 'dd/MM/yyyy'
export const dateRangeFormat = 'YYYY-MM-DD'
export const longDate = 'DD-MM-YYYY'
export const shortDate = 'DD-MM-YY'
export const shortDefaultDate = 'MM-DD-YY'
export const minuteFormat = 'mm:ss'
export const shortyDate = 'MMM D'
export const longDateTime = `${longDate}, ${timeFormat}`
export const longTimeDate = `${timeFormat}, ${longDate}`
export const longDateShortMonthTime = `${dayFormat} ${stringMonthFormat} ${yearFormat} ${timeFormat}`
export const longDateShortMonth = `${dayFormat} ${stringMonthFormat} ${yearFormat}`
export const dateAndFullMonth = 'DD MMMM'

export const formatTimezone = (timezone: number = moment().utcOffset()) =>
  `(GMT${moment.utc().utcOffset(timezone).format('Z')})`

/**
 * @Function formatLongDate for return value in long date format
 * @param value { string | number | Date } - date in different format
 * @return moment - date in long format
 */
export const formatLongDate = (value: string | number | Date) => moment(value).format(longDate)

/**
 * @Function formatShortDate for return value in short date format
 * @param value { string | number | Date } - date in different format
 * @return moment - date in short format
 */
export const formatShortDate = (value: string | number | Date) => moment(value).format(shortDate)

export const formatLongDateTimeTimezone = (value: string | number | Date) =>
  `${moment(value).format(longDateTime)} ${formatTimezone()}`

export const formatLongDateTime = (value: string | number | Date) =>
  `${moment(value).format(longDateTime)}`

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatFileSize = (
  bytes: number,
  showDimension = true,
  decimalPoint?: number
): string => {
  if (bytes === 0) {
    return '0 Bytes'
  }
  const k = 1000
  const dm = decimalPoint || 2
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return showDimension ? size + ' ' + sizes[i] : String(size)
}

export const getDuration = (to: string, from: string, experience?: boolean) => {
  const diffTime = moment(to).diff(from)
  const duration = moment.duration(diffTime)

  if (experience) {
    const years = duration.years()
    const months = duration.months()

    let result = ''

    if (years) {
      if (years === 1) {
        result += `${years} year `
      } else {
        result += `${years} years `
      }
    }

    if (months) {
      if (months === 1) {
        result += `${months} month `
      } else {
        result += `${months} months `
      }
    }

    if (result === '') {
      result += '1 month'
    }

    return result
  } else {
    const secs = duration.seconds()
    const mins = duration.minutes() + (secs > 0 ? 1 : 0)
    const hrs = duration.hours() + (mins === 60 ? 1 : 0)
    const days = duration.days() + (hrs === 24 ? 1 : 0)

    return `${days}d:${hrs}h:${mins}m`
  }
}

export const timerFormatted = (hours: number, minutes: number, seconds: number) => {
  const hoursFormatted = `${hours < 10 ? '0' : ''}${hours}`
  const minutesFormatted = `${minutes < 10 ? '0' : ''}${minutes}`
  const secondsFormatted = `${seconds < 10 ? '0' : ''}${seconds}`
  return `${hours > 0 ? `${hoursFormatted}:` : ''}${minutesFormatted}:${secondsFormatted}`
}

export const formatBytes = (bytes: number, decimals = 2, measurement = true) => {
  if (bytes === 0) {
    return measurement ? '0 Bytes' : '0'
  }

  const dm = decimals < 0 ? 0 : decimals

  const i = Math.floor(Math.log(bytes) / Math.log(BASE_STORAGE_UNIT))

  const calculatedBytes = parseFloat((bytes / Math.pow(BASE_STORAGE_UNIT, i)).toFixed(dm))

  return measurement ? `${calculatedBytes} ${sizes[i]}` : String(calculatedBytes)
}
