import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Col, Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useDispatch } from 'react-redux'
import { FinancialOperationTypeEnum } from '@medentee/enums'
import moment from 'moment'

import { useAppSelector } from 'App'
import {
  clearUserProfileBalanceFilterAction,
  getUserProfileBalanceRequest,
  TUserProfileBalanceRequestPayload
} from 'store'
import { DateRange, TDateRangeApply } from 'App/components/DateRange'
import { useAdaptiveLayout } from 'App/hooks'
import { sorterConverter, longDateShortMonthTime } from 'utils'
import { IMDTBalanceHistoryItemDTO } from 'interfaces'

import styles from './UserProfileBalanceHistory.module.scss'

export type TUserProfileBalanceHistoryProps = {
  id: string
  loading: boolean
}

const columns: ColumnsType<IMDTBalanceHistoryItemDTO> = [
  {
    title: 'Date and time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) => moment(value).format(longDateShortMonthTime).toLowerCase()
  },
  {
    title: 'Operation ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    filters: Object.values(FinancialOperationTypeEnum).map((item) => ({
      text: item,
      value: item
    }))
  },
  {
    title: 'MDT Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    sorter: true
  }
]

export const UserProfileBalanceHistory: FC<TUserProfileBalanceHistoryProps> = (props) => {
  const { id, loading } = props
  const dispatch = useDispatch()
  const { isMobile } = useAdaptiveLayout()
  const data = useAppSelector((state) => state.userManagement.userProfile.balance)
  const { current, showBy } = useAppSelector((state) => state.userManagement.userProfile.filters)

  const dataSource = useMemo(
    () =>
      (data?.table.results ?? []).map((item) => ({
        key: item.id,
        ...item
      })),
    [data]
  )
  const showTotal = useCallback((_total, range) => `${range[0]}-${range[1]} of ${_total} items`, [])

  const getBalance = useCallback(
    (payload: Omit<TUserProfileBalanceRequestPayload, 'id'>) => {
      dispatch(getUserProfileBalanceRequest({ id, ...payload }))
    },
    [dispatch, id]
  )

  const handleTableChange: TAnt.TableOnChange<any> = useCallback(
    ({ current: currentPage, pageSize }, filters, sorter, { action }) => {
      const page = currentPage && currentPage > 0 ? currentPage - 1 : 0
      let sorting

      if (!Array.isArray(sorter) && sorter.columnKey && sorter.order) {
        sorting = {
          by: String(sorter.columnKey),
          type: sorterConverter(sorter.order)
        }
      }

      getBalance({
        current: action !== 'paginate' ? 0 : page,
        showBy: pageSize as number,
        types: filters?.operation ?? [],
        sorting
      })
    },
    [getBalance]
  )

  const handleApply: TDateRangeApply = useCallback(
    (from, to) => {
      if (to && from) {
        getBalance({
          current: 0,
          from: from.dateString,
          to: to.dateString
        })
      }
    },
    [getBalance]
  )

  useEffect(() => {
    getBalance({ current: 0 })
  }, [getBalance])

  useEffect(
    () => () => {
      dispatch(clearUserProfileBalanceFilterAction())
    },
    [dispatch]
  )

  return (
    <>
      <Col xs={24}>
        <Card bordered={false}>
          <DateRange className={styles.dateRange} onApply={handleApply} />
        </Card>
      </Col>
      <Col xs={24}>
        <Card bordered={false}>
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
            pagination={{
              responsive: true,
              total: data?.table.total,
              defaultPageSize: showBy,
              current: current + 1,
              showTotal: isMobile ? undefined : showTotal
            }}
          />
        </Card>
      </Col>
    </>
  )
}
