import {
  actionCreator,
  TError,
  TGetBusinessAccountsDetailsSuccessPayload,
  TGetBusinessAccountsRequestPayload,
  TGetBusinessAccountsSuccessPayload
} from 'store'

import * as actionTypes from './businessAccounts.actionTypes'

export const getBusinessAccountsDetailsRequest = () =>
  actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_DETAILS_REQUEST)
export const getBusinessAccountsDetailsSuccess = (
  payload: TGetBusinessAccountsDetailsSuccessPayload
) => actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_DETAILS_SUCCESS, payload)
export const getBusinessAccountsDetailsError = (payload: TError) =>
  actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_DETAILS_ERROR, payload)

export const getBusinessAccountsRequest = (payload: TGetBusinessAccountsRequestPayload) =>
  actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_REQUEST, payload)
export const getBusinessAccountsSuccess = (payload: TGetBusinessAccountsSuccessPayload) =>
  actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS, payload)
export const getBusinessAccountsError = (payload: TError) =>
  actionCreator(actionTypes.GET_BUSINESS_ACCOUNTS_ERROR, payload)

export const clearBusinessAccountFilter = () =>
  actionCreator(actionTypes.CLEAR_BUSINESS_ACCOUNTS_FILTER)
