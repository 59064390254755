import { Card, Col, Row, Typography } from 'antd'
import { isEqual } from 'lodash'
import { useCallback, useEffect } from 'react'

import { DateRange, TDateRangeApply, useAppDispatch, useAppSelector } from 'App'
import {
  createLoadingSelector,
  getMDTAirdropHistoryRangeSelector,
  getMDTAirdropHistoryRequest,
  GET_MDT_AIRDROP_HISTORY,
  TIds
} from 'store'

export type TRangeProps = PropsWithClassName<{
  setSelectedIds: (ids: TIds) => void
}>

const loadingSelector = createLoadingSelector([GET_MDT_AIRDROP_HISTORY])

export const Range = ({ className, setSelectedIds }: TRangeProps) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const range = useAppSelector(getMDTAirdropHistoryRangeSelector, isEqual)

  const handleApply: TDateRangeApply = useCallback(
    (from, to) => {
      if (to && from) {
        dispatch(
          getMDTAirdropHistoryRequest({
            current: 0,
            from: from.dateString,
            to: to.dateString
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(() => {
    setSelectedIds([])
  }, [range.from, range.to, setSelectedIds])

  return (
    <Card className={className} bordered={false}>
      <Row align="middle" justify="center">
        <Col xl={20} lg={22} md={24} sm={24} xs={24}>
          <Row align="middle" justify="center" gutter={[0, 16]}>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Typography.Text strong>Available transactions in period:</Typography.Text>
            </Col>
            <Col lg={16} md={24} sm={24} xs={24}>
              <DateRange
                loading={loading}
                onApply={handleApply}
                defaultFrom={range.from}
                defaultTo={range.to}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
