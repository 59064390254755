import { normalize, schema } from 'normalizr'

import { IMDTAirdropHistoryItemDTO, IMDTBalanceHistoryItemDTO } from 'interfaces'
import { TIds } from 'store/store.types'

import { TMDTBalanceHistoryNormalized, TMDTAirdropHistoryNormalized } from './mdtBalance.types'

const listSchema = new schema.Array(new schema.Entity('list'))

export const mdtBalanceHistoryNormalize = (
  originalData: IMDTBalanceHistoryItemDTO[]
): TMDTBalanceHistoryNormalized => {
  const {
    entities: { list },
    result: ids
  } = normalize<IMDTBalanceHistoryItemDTO, TMDTBalanceHistoryNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: list || {},
    ids: ids || []
  }
}

export const mdtAirdropHistoryNormalize = (
  originalData: IMDTAirdropHistoryItemDTO[]
): TMDTAirdropHistoryNormalized => {
  const {
    entities: { list },
    result: ids
  } = normalize<IMDTAirdropHistoryItemDTO, TMDTAirdropHistoryNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: list || {},
    ids: ids || []
  }
}
