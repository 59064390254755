import { Typography } from 'antd'

import { useAdaptiveLayout } from 'App/hooks'

export const FullName: TAnt.TableColumnRender<string> = (displayUserName) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <Typography.Text
      ellipsis={{ tooltip: displayUserName }}
      style={{ width: isDesktop ? 264 : 180 }}
    >
      {displayUserName}
    </Typography.Text>
  )
}
