import { TAdminManagementAction, TAdminManagementState } from './adminManagement.types'
import * as actionTypes from './adminManagement.actionTypes'

export const initialAdminManagementState: TAdminManagementState = {
  addAdmin: {
    resetForm: false
  }
}

export const adminManagementReducer = (
  state: TAdminManagementState = initialAdminManagementState,
  action: TAdminManagementAction
): TAdminManagementState => {
  switch (action.type) {
    case actionTypes.RESET_ADMIN_FORM_ACTION: {
      return {
        ...state,
        addAdmin: action.payload
      }
    }

    default:
      return state
  }
}
