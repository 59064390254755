import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Card, Typography } from 'antd'
import { capitalize } from 'lodash'
import { ROUTES } from 'routes/constants'

import {
  createLoadingSelector,
  getUserProfileRequest,
  GET_USER_PROFILE_BALANCE,
  GET_USER_PROFILE
} from 'store'
import { useAppSelector } from 'App'
import { Spinner } from 'App/common'

import { Breadcrumb, UserProfileBalanceHistory, UserProfileBalance } from '../components'

import styles from './UserProfileBalance.module.scss'

const loadingSelector = createLoadingSelector([GET_USER_PROFILE_BALANCE, GET_USER_PROFILE])

export const BusinessAccountBalance: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const { data, balance } = useAppSelector((state) => state.userManagement.userProfile)
  const isLoading = useAppSelector(loadingSelector)

  useEffect(() => {
    if (data?.userId !== id) {
      dispatch(getUserProfileRequest(id))
    }
  }, [data, dispatch, id])

  if (!data) {
    return <Spinner />
  }

  return (
    <Row gutter={[24, 24]} className={styles.root}>
      <Col xs={24}>
        <Card bordered={false}>
          <Row gutter={[0, 16]}>
            <Col xs={24}>
              <Breadcrumb
                items={[
                  {
                    text: 'Home'
                  },
                  { text: 'User management' },
                  { text: '...' },
                  { text: data?.name, link: `${ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS}/${id}` },
                  { text: 'Balance' }
                ]}
              />
            </Col>
            <Col xs={24}>
              <Typography.Title level={4}>Balance</Typography.Title>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card bordered={false}>
          <dl>
            <dt>Company name:</dt>
            <dd>{data.name}</dd>
            <dt>Account type:</dt>
            <dd>{capitalize(data.type.name)}</dd>
            <dt>Email:</dt>
            <dd>{data.email}</dd>
          </dl>
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <UserProfileBalance data={balance} />
      </Col>
      <UserProfileBalanceHistory id={id} loading={isLoading} />
    </Row>
  )
}
