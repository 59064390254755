import { FieldMetaState } from 'react-final-form'

import { hasError } from 'utils'

type TInputErrorProps = FieldMetaState<string> & { custom?: string | null }

export const InputError = ({ custom, ...meta }: TInputErrorProps) => {
  if (custom) {
    return custom
  }

  if (hasError(meta)) {
    return meta.error
  }

  return null
}
