import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { Result, Button } from 'antd'
import { DEFAULT_ROUTE, routes } from 'routes'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  getFocusInBrowserAction,
  GET_INITIALIZE_APP,
  initializeApp,
  lostFocusInBrowserAction
} from 'store'
import { setPageVisibilityFocusCallbacks, getKeyForRoute } from 'utils'
import { Private, Login, ModalRoot } from 'App'
import { Spinner } from 'App/common'

import styles from './App.module.scss'

export type TAppContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TAppViewProps = TAppContainerProps

const NotFound = () => {
  const { push } = useHistory()

  const handleNotFoundBackHome = useCallback(() => {
    push('/')
  }, [push])

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleNotFoundBackHome}>
          Back Home
        </Button>
      }
    />
  )
}

export const AppView = ({
  loading,
  initApp,
  getFocusInBrowser,
  lostFocusInBrowser
}: TAppViewProps) => {
  useEffect(() => {
    initApp()
    setPageVisibilityFocusCallbacks(getFocusInBrowser, lostFocusInBrowser)
  }, [getFocusInBrowser, initApp, lostFocusInBrowser])

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} exact={true} />

        {routes.map((props, index) => (
          <Private key={getKeyForRoute(props.path, index)} {...props} />
        ))}

        <Redirect from="/" to={DEFAULT_ROUTE} />
        <Private component={NotFound} />
      </Switch>

      <ModalRoot />
    </>
  )
}

const loadingSelector = createLoadingSelector([GET_INITIALIZE_APP])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  accountData: state.global.accountData
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initApp: initializeApp,
      getFocusInBrowser: getFocusInBrowserAction,
      lostFocusInBrowser: lostFocusInBrowserAction
    },
    dispatch
  )

export const App = connect(mapStateToProps, mapDispatchToProps)(AppView)
