import { Card, Col, Row, Typography, Skeleton } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Search, TSearchByOption, useAppSelector } from 'App'
import {
  createLoadingSelector,
  getBusinessAccountsDetailsRequest,
  getBusinessAccountsRequest,
  GET_BUSINESS_ACCOUNTS_DETAILS
} from 'store'

import styles from './BusinessAccountsFilter.module.scss'

const { Text } = Typography

const loadingSelector = createLoadingSelector([GET_BUSINESS_ACCOUNTS_DETAILS])

const searchByOptions: TSearchByOption[] = [
  {
    label: 'Company name',
    value: 'companyName'
  },
  {
    label: 'Business Email',
    value: 'email'
  },
  {
    label: 'Superadmin name',
    value: 'superadminName'
  },
  {
    label: 'Location',
    value: 'location'
  }
]

export const BusinessAccountsFilter = ({ className = '' }: PropsWithClassName) => {
  const dispatch = useDispatch()
  const loading = useAppSelector(loadingSelector)
  const search = useAppSelector((state) => state.userManagement.businessAccounts.filters.search)
  const { businessCount } = useAppSelector((state) => state.userManagement.businessAccounts.details)

  useEffect(() => {
    dispatch(getBusinessAccountsDetailsRequest())
  }, [dispatch])

  const handleSearch = useCallback(
    (query: string, by: string) =>
      dispatch(
        getBusinessAccountsRequest({
          current: 0,
          search: { query, by }
        })
      ),
    [dispatch]
  )

  return (
    <Card bordered={false} className={className}>
      <Row justify="center" className={styles.info}>
        <Col md={8} sm={24} xs={24}>
          <Skeleton active title={false} loading={loading} paragraph={{ rows: 1, width: '100%' }}>
            <Text>Total business accounts:</Text>
            <Text strong> {businessCount}</Text>
          </Skeleton>
        </Col>
      </Row>

      <Row justify="center">
        <Col md={18} sm={24} xs={24}>
          <Search
            onSearch={handleSearch}
            searchByOptions={searchByOptions}
            searchByDefaultValue={search?.by ?? searchByOptions[0].value}
          />
        </Col>
      </Row>
    </Card>
  )
}
