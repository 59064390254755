import { PAGINATION_DEFAULT } from 'globalConstants'
import { TPersonalAccountsAction, TPersonalAccountsState } from 'store'

import * as actionTypes from './personalAccounts.actionTypes'

export const initialPersonalAccountsState: TPersonalAccountsState = {
  details: {
    professionalCount: 0,
    total: 0
  },
  filters: {
    ...PAGINATION_DEFAULT,
    from: '',
    to: ''
  },
  data: []
}

export const personalAccountsReducer = (
  state: TPersonalAccountsState = initialPersonalAccountsState,
  action: TPersonalAccountsAction
): TPersonalAccountsState => {
  switch (action.type) {
    case actionTypes.GET_PERSONAL_ACCOUNTS_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload
      }
    }

    case actionTypes.GET_PERSONAL_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.CLEAR_PERSONAL_ACCOUNTS_FILTER: {
      return {
        ...state,
        filters: initialPersonalAccountsState.filters
      }
    }

    default:
      return state
  }
}
