import Typography from 'antd/lib/typography'

import { ConfirmationModal } from 'App/common/ConfirmationModal'
import { useModal } from 'App/hooks'

export enum EVisibilityDataType {
  COMPANY_TYPE = 'company type',
  PROFESSION = 'profession',
  CONNECTOR_PURPOSE = 'connector`s purpose'
}

type TVisibilityDialogProps = {
  type: EVisibilityDataType
  isHidden: boolean
  onConfirm: () => void
}

export const VisibilityDialog = ({ isHidden, type, onConfirm }: TVisibilityDialogProps) => {
  const { hideModal } = useModal()

  const handleOk = () => {
    onConfirm()
    hideModal()
  }

  const hideText = `Are you sure you want to hide ${type} from site? Note: for already existed entities it will keep showing`
  const showText = `Are you sure you want to make visible ${type} on site?`

  return (
    <ConfirmationModal
      onOk={handleOk}
      onCancel={hideModal}
      content={<Typography.Text strong>{isHidden ? showText : hideText}</Typography.Text>}
    />
  )
}
