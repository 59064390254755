import { useCallback, useEffect, useState } from 'react'
import cls from 'classnames'
import { Button, Typography, DatePicker } from 'antd'
import moment, { Moment } from 'moment'

import styles from './DateRange.module.scss'

const { Text } = Typography

export type DateType = {
  date: Moment | null
  dateString: string
}

export type TDateRangeApply = (from: DateType, to: DateType) => void
export type TDateRange = {
  onApply: TDateRangeApply

  loading?: boolean
  defaultFrom?: string
  defaultTo?: string
}

export const DateRange = ({
  className = '',
  loading = false,
  onApply,
  defaultFrom,
  defaultTo
}: PropsWithClassName<TDateRange>) => {
  const [from, setFrom] = useState<DateType>({ date: null, dateString: '' })
  const [to, setTo] = useState<DateType>({ date: null, dateString: '' })

  const handleFromChange = useCallback((date: Moment | null) => {
    const correctDate = date ? moment(date).startOf('day') : date

    setFrom({
      date: correctDate,
      dateString: correctDate?.toISOString() ?? ''
    })
  }, [])

  const handleToChange = useCallback((date: Moment | null) => {
    const correctDate = date ? moment(date).endOf('day') : date

    setTo({
      date: correctDate,
      dateString: correctDate?.toISOString() ?? ''
    })
  }, [])

  useEffect(() => {
    if (defaultFrom) {
      setFrom({
        date: moment(defaultFrom),
        dateString: moment(defaultFrom).toISOString()
      })
    }

    if (defaultTo) {
      setTo({
        date: moment(defaultTo),
        dateString: moment(defaultTo).toISOString()
      })
    }
  }, [defaultFrom, defaultTo])

  const handleApplyFilter = useCallback(() => {
    if (to && from) {
      onApply(from, to)
    }
  }, [onApply, from, to])

  return (
    <div className={cls(styles.root, className)}>
      <div className={styles.rootContainer}>
        <div className={styles.rootItem}>
          <Text className={styles.rootText}>From</Text>
          <DatePicker
            placeholder="Please select"
            className={styles.rootDate}
            value={from.date}
            onChange={handleFromChange}
            disabledDate={(current) => current > (to?.date ?? Infinity)}
          />
        </div>

        <div className={styles.rootItem}>
          <Text className={styles.rootText}>to</Text>
          <DatePicker
            placeholder="Please select"
            className={styles.rootDate}
            value={to.date}
            onChange={handleToChange}
            disabledDate={(current) => current < (from?.date ?? -Infinity)}
          />
        </div>
      </div>

      <Button onClick={handleApplyFilter} type="primary" loading={loading}>
        Apply
      </Button>
    </div>
  )
}
