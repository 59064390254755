import * as actionTypes from './subscriptionPlan.actionTypes'
import {
  TDefaultSubscriptionPlanState,
  TDefaultSubscriptionPlanAction
} from './subscriptionPlans.types'

const initialState: TDefaultSubscriptionPlanState = {
  list: {},
  ids: []
}

export const defaultSubscriptionPlanReducer = (
  state = initialState,
  action: TDefaultSubscriptionPlanAction
) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.RESET_SUBSCRIPTION_PLAN: {
      return initialState
    }

    case actionTypes.UPDATE_SUBSCRIPTION_PLAN: {
      return initialState
    }

    default:
      return state
  }
}
