import {
  actionCreator,
  TError,
  TGetProfessionsSuccessPayload,
  TInviteNewUserRequestPayload,
  TSetUserExistsErrorPayload
} from 'store'

import * as actionTypes from './inviteNewUser.actionTypes'

export const getProfessionsRequest = () => actionCreator(actionTypes.GET_PROFESSIONS_REQUEST)
export const getProfessionsSuccess = (payload: TGetProfessionsSuccessPayload) =>
  actionCreator(actionTypes.GET_PROFESSIONS_SUCCESS, payload)
export const getProfessionsError = (payload: TError) =>
  actionCreator(actionTypes.GET_PROFESSIONS_ERROR, payload)

export const inviteNewUserRequest = (payload: TInviteNewUserRequestPayload) =>
  actionCreator(actionTypes.INVITE_NEW_USER_REQUEST, payload)
export const inviteNewUserSuccess = () => actionCreator(actionTypes.INVITE_NEW_USER_SUCCESS)
export const inviteNewUserError = (payload: TError) =>
  actionCreator(actionTypes.INVITE_NEW_USER_ERROR, payload)

export const setUserExistsError = (payload: TSetUserExistsErrorPayload) =>
  actionCreator(actionTypes.SET_USER_EXISTS_ERROR, payload)
