import {
  Home,
  AdminManagement,
  PersonalAccountInfo,
  BusinessAccountInfo,
  PersonalAccounts,
  MDTBalancePage,
  DefaultVariables,
  PersonalAccountBalance,
  BusinessAccountBalance,
  BusinessAccounts,
  SubscriptionPlanPage,
  CompanyTypeList,
  ProfessionList,
  ConnectorPurposesList
} from 'App/screens'
import { MDTBalance, SubscriptionPlanList } from 'App/components'
import { MDTAirdrop } from 'App/components/MDTAirdrop'

import { TRouts } from './routes.types'
import { ROUTES } from './constants'

export const DEFAULT_ROUTE = ROUTES.VARIABLES.DEFAULT_VARIABLES

export const routes: TRouts[] = [
  {
    exact: true,
    path: ROUTES.DASHBOARD,
    component: () => <Home />
  },
  {
    path: ROUTES.MDT.DEFAULT,
    component: () => <MDTBalancePage />
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS,
    component: () => <PersonalAccounts />
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS,
    component: () => <BusinessAccounts />
  },
  {
    exact: true,
    path: ROUTES.VARIABLES.DEFAULT_VARIABLES,
    component: DefaultVariables
  },
  {
    path: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.DEFAULT,
    component: SubscriptionPlanPage
  },
  {
    exact: true,
    path: ROUTES.VARIABLES.COMPANY_TYPES,
    component: CompanyTypeList
  },
  {
    exact: true,
    path: ROUTES.VARIABLES.CONNECTOR_PURPOSES,
    component: ConnectorPurposesList
  },
  {
    exact: true,
    path: ROUTES.VARIABLES.PROFESSION_LIST,
    component: ProfessionList
  },
  {
    exact: true,
    path: ROUTES.ADMIN_MANAGEMENT,
    component: () => <AdminManagement />
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNT,
    component: PersonalAccountInfo
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNT_BALANCE,
    component: PersonalAccountBalance
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNT,
    component: BusinessAccountInfo
  },
  {
    exact: true,
    path: ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNT_BALANCE,
    component: BusinessAccountBalance
  }
]

export const subscriptionPlansNestedRoutes: TRouts[] = [
  {
    exact: true,
    path: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.PROFESSIONAL,
    component: () => <SubscriptionPlanList />
  },
  {
    exact: true,
    path: ROUTES.VARIABLES.SUBSCRIPTION_PLANS.BUSINESS,
    component: () => <SubscriptionPlanList />
  }
]

export const balanceNestedRoutes: TRouts[] = [
  {
    exact: true,
    path: ROUTES.MDT.BALANCE,
    component: () => <MDTBalance />
  },
  {
    exact: true,
    path: ROUTES.MDT.AIRDROP,
    component: () => <MDTAirdrop />
  }
]
