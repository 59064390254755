import {
  GET_DEFAULT_VARIABLES_SUCCESS,
  TDefaultVariablesAction,
  TDefaultVariablesState
} from 'store'

const initialState: TDefaultVariablesState = {}

export const defaultVariablesReducer = (state = initialState, action: TDefaultVariablesAction) => {
  switch (action.type) {
    case GET_DEFAULT_VARIABLES_SUCCESS: {
      return {
        ...state,
        ...action.payload.reduce((res, item) => ({ ...res, [item.key]: item }), {})
      }
    }
    default:
      return state
  }
}
