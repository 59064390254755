import { InfoCircleOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'routes/constants'

import { IBusinessAccountsItemDTO } from 'interfaces'

export const Info: TAnt.TableColumnRender<IBusinessAccountsItemDTO> = ({ id }) => (
  <NavLink to={`${ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS}/${id ?? ''}`}>
    <InfoCircleOutlined />
  </NavLink>
)
