import React, { FC, useCallback, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'

import { Spinner, THandleFinish } from 'App/common'
import { useAppSelector } from 'App'
import {
  createLoadingSelector,
  getDefaultVariablesRequest,
  GET_DEFAULT_VARIABLES,
  TDefaultVariable,
  updateDefaultVariablesRequest
} from 'store'

import {
  DefaultVariablesMDT,
  DefaultVariablesUserAccounts,
  DefaultVariablesInitialTopUp,
  DefaultVariablesBusinessAccounts
} from './components'

export type TCommonDefaultVariablesProps = {
  onFormSubmit: THandleFinish
}

const loadingSelector = createLoadingSelector([GET_DEFAULT_VARIABLES])

export const DefaultVariables: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useAppSelector(loadingSelector)

  useEffect(() => {
    dispatch(getDefaultVariablesRequest())
  }, [dispatch])

  const handleDefaultVariablesUpdate = useCallback<THandleFinish>(
    (values) => {
      dispatch(
        updateDefaultVariablesRequest({
          variables: Object.entries(values).map(([key, value]) => ({
            key,
            value
          })) as TDefaultVariable[]
        })
      )
    },
    [dispatch]
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Row gutter={[0, 24]}>
      <Col xs={24}>
        <DefaultVariablesUserAccounts onFormSubmit={handleDefaultVariablesUpdate} />
      </Col>
      <Col xs={24}>
        <DefaultVariablesBusinessAccounts onFormSubmit={handleDefaultVariablesUpdate} />
      </Col>
      <Col xs={24}>
        <DefaultVariablesInitialTopUp onFormSubmit={handleDefaultVariablesUpdate} />
      </Col>
      <Col xs={24}>
        <DefaultVariablesMDT onFormSubmit={handleDefaultVariablesUpdate} />
      </Col>
    </Row>
  )
}
