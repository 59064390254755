import { useState } from 'react'

export const useDialog = (defaultState = false) => {
  const [state, setState] = useState(defaultState)
  const toggle = () => setState((prev) => !prev)
  const toggleOff = () => setState(false)
  const toggleOn = () => setState(true)

  return { state, toggle, toggleOff, toggleOn }
}
