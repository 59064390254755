import { format, isAfter, isBefore } from 'date-fns'

import { REGEXP_RULES } from 'utils'

import { longDateTime } from '../formats'

import { messages } from './validationMessages'

type TValidatorResponse = string | null

class Validation {
  public rules = REGEXP_RULES

  public required =
    (errMessage = messages.required) =>
    (value: any): TValidatorResponse => {
      if (Array.isArray(value)) {
        return value.length ? null : errMessage
      }

      let isOnlySpace = false

      if (typeof value === 'string') {
        isOnlySpace = this.rules.onlySpaces.test(value)
      }

      if (value === '' || isOnlySpace) {
        return errMessage
      }

      return value || value === 0 ? null : errMessage
    }

  public isNumber =
    (errMessage?: string) =>
    (value: number): TValidatorResponse => {
      if (value === undefined || !isNaN(value)) {
        return null
      }

      return errMessage ? errMessage : messages.isNumber
    }

  public isZip =
    (errMessage?: string) =>
    (value: string): TValidatorResponse => {
      if (this.rules.zip.test(value) || value === '') {
        return null
      }

      return errMessage ? errMessage : messages.zip
    }

  public isEmail =
    (errMessage?: string) =>
    (value: string): TValidatorResponse => {
      if (this.rules.email.test(value)) {
        return null
      }

      return errMessage ? errMessage : messages.email
    }

  public isPassword =
    (errMessage?: string) =>
    (value: string): TValidatorResponse => {
      if (this.rules.password.test(value)) {
        return null
      }

      return errMessage ? errMessage : messages.password
    }

  public isPersonName =
    (errMessage?: string) =>
    (value: string): TValidatorResponse => {
      if (this.rules.personName.test(value)) {
        return null
      }

      return errMessage ? errMessage : messages.name
    }

  public isPasswordConfirm =
    (errMessage?: string) =>
    (value: string, values: Record<string, unknown>): TValidatorResponse => {
      if (values.password !== value) {
        return errMessage ? errMessage : messages.confirmPassword
      }

      return null
    }

  public minLength =
    (min: number) =>
    (value: string | []): TValidatorResponse =>
      value == null || value === '' || value.length >= min ? null : `${messages.minText} ${min}`

  public maxLength =
    (max: number, message?: string) =>
    (value: string | []): TValidatorResponse =>
      value == null || value === '' || value.length <= max
        ? null
        : message || `${messages.maxText} ${max}`

  public min =
    (min: number, message?: string) =>
    (value: number): TValidatorResponse =>
      value === undefined || value >= min ? null : message || `${messages.min} ${min}`

  public max =
    (max: number, message?: string) =>
    (value?: number): TValidatorResponse =>
      value === undefined || value <= max ? null : message || `${messages.max} ${max}`

  public natural =
    (message?: string) =>
    (value?: number): TValidatorResponse =>
      value === undefined || (value >= 0 && Math.floor(value) === +value)
        ? null
        : message || messages.natural

  public minDate = (min: number | string | Date) => (value: string | number | Date | null) =>
    !value || isAfter(new Date(value), new Date(min))
      ? null
      : `${messages.minDate} ${format(new Date(min), longDateTime)}`

  public maxDate = (max: number | string | Date) => (value: string | number | Date | null) =>
    !value || isBefore(new Date(value), new Date(max))
      ? null
      : `${messages.maxDate} ${format(new Date(max), longDateTime)}`

  public digits = (errMessage?: string) => (value: string | number) =>
    value != null && value !== '' && !this.rules.digits.test(String(value))
      ? errMessage || messages.onlyDigits
      : null

  public price = (errMessage?: string) => (value: string | number) =>
    value != null && value !== '' && !this.rules.price.test(String(value))
      ? errMessage || messages.price
      : null

  public onlySpaces = (errMessage?: string) => (value: string) =>
    this.rules.onlySpaces.test(value) ? errMessage || messages.onlySpaces : null

  public composeValidators =
    (...validators: any[]) =>
    (value: any, values: any) =>
      validators.reduce(
        (error, validator) =>
          error || (typeof validator === 'function' && validator(value, values)),
        null
      )
}

export const validation = new Validation()
