export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR'
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'

export const GET_USER_PROFILE_BALANCE = 'GET_USER_PROFILE_BALANCE'
export const GET_USER_PROFILE_BALANCE_SUCCESS = 'GET_USER_PROFILE_BALANCE_SUCCESS'
export const GET_USER_PROFILE_BALANCE_ERROR = 'GET_USER_PROFILE_BALANCE_ERROR'
export const GET_USER_PROFILE_BALANCE_REQUEST = 'GET_USER_PROFILE_BALANCE_REQUEST'

export const CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION = 'CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR'
