import { Action } from 'store/store.types'

import { REMOVE_ERROR_NOTIFICATION } from './errors.actions'

export type TErrorState = { [key: string]: boolean }

export const errorRegExp = /(.*)_(ERROR)$/
export const metaWithoutError = 'withoutError'

export const errorsReducer = (state: TErrorState = {}, action: Action) => {
  if (action.meta && typeof action.meta === 'object' && action.meta[metaWithoutError]) {
    return state
  }

  const matches = errorRegExp.exec(action.type)
  if (matches) {
    const [, requestName, requestState] = matches

    return {
      ...state,
      [requestName]: requestState !== 'ERROR' ? null : action.payload
    }
  }

  if (action.type === REMOVE_ERROR_NOTIFICATION) {
    return {
      ...state,
      [action.payload]: null
    }
  }

  return state
}
