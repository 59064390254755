import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import isArray from 'lodash/isArray'

import { useAdaptiveLayout, useAppSelector } from 'App'
import { IPersonalAccountsItemDTO } from 'interfaces'
import { showPaginationTotal, sorterConverter } from 'utils'
import { createLoadingSelector, getPersonalAccountsRequest, GET_PERSONAL_ACCOUNTS } from 'store'

import { FullName, RegistrationDate, Location, Professional, BusinessCount } from './components'

const loadingSelector = createLoadingSelector([GET_PERSONAL_ACCOUNTS])

const columns: ColumnsType<IPersonalAccountsItemDTO> = [
  {
    title: 'First, Last name',
    key: 'fullName',
    render: FullName
  },
  {
    title: 'Registration date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: RegistrationDate
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: Location
  },
  {
    title: 'Professional',
    dataIndex: 'professionalId',
    key: 'professional',
    render: Professional
  },
  {
    title: 'BA',
    dataIndex: 'businessSuperadminAccCount',
    key: 'businessSuperadminAccCount',
    render: BusinessCount
  },
  {
    title: 'Admin',
    dataIndex: 'businessAdminAccCount',
    key: 'businessAdminAccCount',
    render: BusinessCount
  }
]

export const PersonalAccountsList = () => {
  const dispatch = useDispatch()
  const { isMobile } = useAdaptiveLayout()
  const loading = useAppSelector(loadingSelector)
  const {
    data,
    filters: { current, showBy, total }
  } = useAppSelector((state) => state.userManagement.personalAccounts)

  const showTotal = useCallback(showPaginationTotal, [])

  const handleTableChange: TAnt.TableOnChange<IPersonalAccountsItemDTO> = useCallback(
    ({ current: currentPage, pageSize }, _, sorter, { action }) => {
      const page = currentPage && currentPage > 0 ? currentPage - 1 : 0
      let sorting

      if (!isArray(sorter) && sorter.columnKey && sorter.order) {
        sorting = {
          by: String(sorter.columnKey),
          type: sorterConverter(sorter.order)
        }
      }

      dispatch(
        getPersonalAccountsRequest({
          current: action !== 'paginate' ? 0 : page,
          showBy: pageSize,
          sorting
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getPersonalAccountsRequest({ current: 0 }))
  }, [dispatch])

  return (
    <Card bordered={false}>
      <Table
        rowKey={({ createdAt, email }) => createdAt + email}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          responsive: true,
          total,
          defaultPageSize: showBy,
          current: current + 1,
          showTotal: isMobile ? undefined : showTotal
        }}
      />
    </Card>
  )
}
