import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { EditableEnvConfig } from '@medentee/enums'

import { useAppSelector } from 'App'
import { getIntegerFormValue } from 'utils/form'
import { DefaultFormCard } from 'App/common'
import { UPDATE_DEFAULT_VARIABLES } from 'store'

import { TCommonDefaultVariablesProps } from '../../'

export const DefaultVariablesBusinessAccounts = ({
  onFormSubmit
}: TCommonDefaultVariablesProps) => {
  const defaultVariables = useAppSelector((state) => state.defaultVariables)

  return (
    <DefaultFormCard
      title="Business accounts"
      onFormSubmit={onFormSubmit}
      loadingActionType={UPDATE_DEFAULT_VARIABLES}
      initialValues={{
        [EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]:
          defaultVariables[EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]?.value
      }}
    >
      {(disabled) => (
        <Row gutter={[20, 0]} align="bottom">
          <Col xs={24} md={12}>
            <Form.Item
              name={EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS}
              label="Professional can be a admin or superadmin of business accounts, max"
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: defaultVariables[EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]?.min,
                  max: defaultVariables[EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]?.max,
                  message: `Must be between ${
                    defaultVariables[EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]?.min
                  } and ${defaultVariables[EditableEnvConfig.MAX_NUMBER_OF_PARTICIPATIONS]?.max}`
                }
              ]}
              getValueFromEvent={getIntegerFormValue}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
