import { put, takeLatest, call } from 'redux-saga/effects'
import { AccountTypeIdEnum } from '@medentee/enums'

import config from 'config'
import { API, api, APIData, GENERAL_API } from 'services/api'
import { IProfessionDTO } from 'interfaces'
import { inviteNewUserError, normalizeList } from 'store'
import { hideModalAction } from 'store/modal'
import { QueryBuilder } from 'utils'

import {
  getProfessionsError,
  getProfessionsSuccess,
  inviteNewUserRequest,
  inviteNewUserSuccess
} from './inviteNewUser.actions'
import * as actionTypes from './inviteNewUser.actionTypes'

function* getProfessionsSaga() {
  try {
    const url = new QueryBuilder(GENERAL_API.PROFESSIONS)
      .custom('types', AccountTypeIdEnum.PROFESSIONAL)
      .build()

    const { data }: APIData<IProfessionDTO[]> = yield api.get(url, {
      baseURL: config.generalApiUrl
    })

    yield put(getProfessionsSuccess(normalizeList<IProfessionDTO>(data)))
  } catch (error) {
    yield put(getProfessionsError(error))
  }
}

function* inviteNewUserSaga({ payload }: ReturnType<typeof inviteNewUserRequest>) {
  try {
    yield call(api.post, API.INVITE_NEW_USER, payload)

    yield put(inviteNewUserSuccess())
    yield put(hideModalAction())
  } catch (error) {
    yield put(inviteNewUserError(error))
  }
}

export function* inviteNewUserRootSaga() {
  yield takeLatest(actionTypes.INVITE_NEW_USER_REQUEST, inviteNewUserSaga)
  yield takeLatest(actionTypes.GET_PROFESSIONS_REQUEST, getProfessionsSaga)
}
