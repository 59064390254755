export const GET_PERSONAL_ACCOUNTS_DETAILS = 'GET_PERSONAL_ACCOUNTS_DETAILS'
export const GET_PERSONAL_ACCOUNTS_DETAILS_REQUEST = 'GET_PERSONAL_ACCOUNTS_DETAILS_REQUEST'
export const GET_PERSONAL_ACCOUNTS_DETAILS_SUCCESS = 'GET_PERSONAL_ACCOUNTS_DETAILS_SUCCESS'
export const GET_PERSONAL_ACCOUNTS_DETAILS_ERROR = 'GET_PERSONAL_ACCOUNTS_DETAILS_ERROR'

export const GET_PERSONAL_ACCOUNTS = 'GET_PERSONAL_ACCOUNTS'
export const GET_PERSONAL_ACCOUNTS_REQUEST = 'GET_PERSONAL_ACCOUNTS_REQUEST'
export const GET_PERSONAL_ACCOUNTS_SUCCESS = 'GET_PERSONAL_ACCOUNTS_SUCCESS'
export const GET_PERSONAL_ACCOUNTS_ERROR = 'GET_PERSONAL_ACCOUNTS_ERROR'

export const CLEAR_PERSONAL_ACCOUNTS_FILTER = 'CLEAR_PERSONAL_ACCOUNTS_FILTER'
