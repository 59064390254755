import { Typography } from 'antd'
import { useMemo } from 'react'

import { IPersonalAccountsItemDTO } from 'interfaces'

export const FullName: TAnt.TableColumnRender<string, IPersonalAccountsItemDTO> = (
  _,
  { lastName, firstName }
) => {
  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName])

  return (
    <Typography.Text ellipsis={{ tooltip: fullName }} style={{ width: 200 }}>
      {fullName}
    </Typography.Text>
  )
}
