export const ROUTES = {
  DASHBOARD: '/dashboard',
  MDT: {
    DEFAULT: '/mdt',
    BALANCE: '/mdt/balance',
    AIRDROP: '/mdt/airdrop'
  },
  VARIABLES: {
    DEFAULT_VARIABLES: '/variables/default-variables',
    SUBSCRIPTION_PLANS: {
      DEFAULT: '/variables/subscription-plans',
      PROFESSIONAL: '/variables/subscription-plans/professional',
      BUSINESS: '/variables/subscription-plans/business'
    },
    COMPANY_TYPES: '/variables/company-types',
    CONNECTOR_PURPOSES: '/variables/connector-purposes',
    PROFESSION_LIST: '/variables/profession-list'
  },
  ADMIN_MANAGEMENT: '/admin-management',
  USER_MANAGEMENT: {
    PERSONAL_ACCOUNTS: '/user-management/personal-accounts',
    PERSONAL_ACCOUNT: '/user-management/personal-accounts/:id',
    PERSONAL_ACCOUNT_BALANCE: '/user-management/personal-accounts/:id/balance',
    BUSINESS_ACCOUNTS: '/user-management/business-accounts',
    BUSINESS_ACCOUNT: '/user-management/business-accounts/:id',
    BUSINESS_ACCOUNT_BALANCE: '/user-management/business-accounts/:id/balance'
  }
}
