import { BalanceDetails, BalanceHistory, BalanceHistoryFilter } from 'App'

import styles from './MDTBalance.module.scss'

export const MDTBalance = () => (
  <div className={styles.root}>
    <BalanceDetails className={styles.details} />
    <BalanceHistoryFilter className={styles.filter} />
    <BalanceHistory />
  </div>
)
