import { put, takeLatest, call } from 'redux-saga/effects'
import { ItemsListNamesEnum } from '@medentee/enums'

import { IEntityDTO } from 'interfaces/common'
import { API, api, APIData } from 'services/api'

import { TPatchConnectorPurposesRequest } from './connectorPurposes.types'
import * as actionTypes from './connectorPurposes.actionTypes'
import * as actions from './connectorPurposes.actions'

function* getConnectorPurposesSaga() {
  try {
    const { data }: APIData<IEntityDTO[]> = yield api.get(
      API.LIST_ITEMS(ItemsListNamesEnum.CONNECTOR_PURPOSES)
    )

    yield put(actions.getConnectorPurposesSuccess({ list: data }))
  } catch (e) {
    yield put(actions.getConnectorPurposesError(e))
  }
}

function* patchConnectorPurposesSaga({ payload }: TPatchConnectorPurposesRequest) {
  try {
    yield call(api.patch, API.LIST_ITEMS(ItemsListNamesEnum.CONNECTOR_PURPOSES), payload)

    yield put(actions.patchConnectorPurposesSuccess())
    yield put(actions.getConnectorPurposesRequest())
  } catch (e: any) {
    yield put(actions.patchConnectorPurposesError(e))
  }
}

export function* connectorPurposesRootSaga() {
  yield takeLatest(actionTypes.GET_CONNECTOR_PURPOSES_REQUEST, getConnectorPurposesSaga)
  yield takeLatest(actionTypes.PATCH_CONNECTOR_PURPOSES_REQUEST, patchConnectorPurposesSaga)
}
