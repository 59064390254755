import { AccountTypeIdEnum } from '@medentee/enums'

import { deleteAccountRequest } from 'store'
import { useAppDispatch, useModal } from 'App/hooks'
import { ConfirmationModal } from 'App/common/ConfirmationModal'

type DeleteAccountDialogProps = {
  id: string
  accountType: AccountTypeIdEnum
}

export const DeleteAccountDialog = ({ id, accountType }: DeleteAccountDialogProps) => {
  const { hideModal } = useModal()
  const dispatch = useAppDispatch()

  const handleOk = () => {
    hideModal()
    dispatch(
      deleteAccountRequest({
        id,
        accountType
      })
    )
  }

  return (
    <ConfirmationModal
      title="Delete Account"
      onOk={handleOk}
      onCancel={hideModal}
      content={
        accountType === AccountTypeIdEnum.PROFESSIONAL
          ? 'This account may be connected with Business accounts. Note, that they all will be deleted too. Are you sure you want to delete the Account?'
          : 'Are you sure you want to delete the Account?'
      }
    />
  )
}
