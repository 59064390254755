import { AccountTypeIdEnum } from '@medentee/enums'

import {
  actionCreator,
  TError,
  TUpdatePersonalUserProfilePayload,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS
} from 'store'

import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_BALANCE_ERROR,
  GET_USER_PROFILE_BALANCE_REQUEST,
  GET_USER_PROFILE_BALANCE_SUCCESS,
  CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR
} from './userProfile.actionTypes'
import {
  TPersonalUserProfilePayload,
  TUserProfileBalancePayload,
  TUserProfileBalanceRequestPayload
} from './userProfile.types'

export const getUserProfileRequest = (id: string) => actionCreator(GET_USER_PROFILE_REQUEST, id)
export const getUserProfileSuccess = (payload: TPersonalUserProfilePayload) =>
  actionCreator(GET_USER_PROFILE_SUCCESS, payload)
export const getUserProfileError = (error: TError) => actionCreator(GET_USER_PROFILE_ERROR, error)

export const updateUserProfileRequest = (payload: TUpdatePersonalUserProfilePayload) =>
  actionCreator(UPDATE_USER_PROFILE_REQUEST, payload)
export const updateUserProfileSuccess = (payload: TUpdatePersonalUserProfilePayload) =>
  actionCreator(UPDATE_USER_PROFILE_SUCCESS, payload)
export const updateUserProfileError = (error: TError) =>
  actionCreator(UPDATE_USER_PROFILE_ERROR, error)

export const getUserProfileBalanceRequest = (payload: TUserProfileBalanceRequestPayload) =>
  actionCreator(GET_USER_PROFILE_BALANCE_REQUEST, payload)
export const getUserProfileBalanceSuccess = (payload: TUserProfileBalancePayload) =>
  actionCreator(GET_USER_PROFILE_BALANCE_SUCCESS, payload)
export const getUserProfileBalanceError = (error: TError) =>
  actionCreator(GET_USER_PROFILE_BALANCE_ERROR, error)

export const clearUserProfileBalanceFilterAction = () =>
  actionCreator(CLEAR_USER_PROFILE_BALANCE_FILTER_ACTION)

export const deleteAccountRequest = (payload: { id: string; accountType: AccountTypeIdEnum }) =>
  actionCreator(DELETE_ACCOUNT_REQUEST, payload)
export const deleteAccountSuccess = () => actionCreator(DELETE_ACCOUNT_SUCCESS)
export const deleteAccountError = (error: TError) => actionCreator(DELETE_ACCOUNT_ERROR, error)
