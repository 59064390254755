export const GET_MDT_BALANCE_DETAILS = 'GET_MDT_BALANCE_DETAILS'
export const GET_MDT_BALANCE_DETAILS_REQUEST = 'GET_MDT_BALANCE_DETAILS_REQUEST'
export const GET_MDT_BALANCE_DETAILS_SUCCESS = 'GET_MDT_BALANCE_DETAILS_SUCCESS'
export const GET_MDT_BALANCE_DETAILS_ERROR = 'GET_MDT_BALANCE_DETAILS_ERROR'

export const GENERATE_MDT = 'GENERATE_MDT'
export const GENERATE_MDT_REQUEST = 'GENERATE_MDT_REQUEST'
export const GENERATE_MDT_SUCCESS = 'GENERATE_MDT_SUCCESS'
export const GENERATE_MDT_ERROR = 'GENERATE_MDT_ERROR'

export const AIRDROP_TO_USER = 'AIRDROP_TO_USER'
export const AIRDROP_TO_USER_REQUEST = 'AIRDROP_TO_USER_REQUEST'
export const AIRDROP_TO_USER_SUCCESS = 'AIRDROP_TO_USER_SUCCESS'
export const AIRDROP_TO_USER_ERROR = 'AIRDROP_TO_USER_ERROR'

export const GET_MDT_BALANCE_HISTORY = 'GET_MDT_BALANCE_HISTORY'
export const GET_MDT_BALANCE_HISTORY_REQUEST = 'GET_MDT_BALANCE_HISTORY_REQUEST'
export const GET_MDT_BALANCE_HISTORY_SUCCESS = 'GET_MDT_BALANCE_HISTORY_SUCCESS'
export const GET_MDT_BALANCE_HISTORY_ERROR = 'GET_MDT_BALANCE_HISTORY_ERROR'

export const GET_MDT_AIRDROP_HISTORY = 'GET_MDT_AIRDROP_HISTORY'
export const GET_MDT_AIRDROP_HISTORY_REQUEST = 'GET_MDT_AIRDROP_HISTORY_REQUEST'
export const GET_MDT_AIRDROP_HISTORY_SUCCESS = 'GET_MDT_AIRDROP_HISTORY_SUCCESS'
export const GET_MDT_AIRDROP_HISTORY_ERROR = 'GET_MDT_AIRDROP_HISTORY_ERROR'

export const SEND_AIRDROP = 'SEND_AIRDROP'
export const SEND_AIRDROP_REQUEST = 'SEND_AIRDROP_REQUEST'
export const SEND_AIRDROP_SUCCESS = 'SEND_AIRDROP_SUCCESS'
export const SEND_AIRDROP_ERROR = 'SEND_AIRDROP_ERROR'
