import React, { useCallback } from 'react'
import { Form, Input, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { createProcessingSelector, loginRequest, LOGIN } from 'store'

import styles from './LoginForm.module.scss'

const processingSelector = createProcessingSelector([LOGIN])

export const LoginForm = () => {
  const dispatch = useDispatch()
  const loading = useSelector(processingSelector)
  const onFinish = useCallback(
    (values: any) => {
      dispatch(loginRequest(values))
    },
    [dispatch]
  )

  return (
    <Form name="login" layout="vertical" onFinish={onFinish} className={styles.root}>
      <Form.Item
        name="email"
        hasFeedback
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Please input your email!'
          }
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please input your password!'
          }
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}
