import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Layout } from 'App'
import { isAuthorizedSelector } from 'store'

export type TPrivateProps = RouteProps

export const Private = (props: TPrivateProps) => {
  const isAuth = useSelector(isAuthorizedSelector)

  if (isAuth) {
    return (
      <Layout>
        <Route {...props} />
      </Layout>
    )
  }

  //redirect if there is no user
  return <Redirect to="/login" />
}
