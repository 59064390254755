import { AccountTypeIdEnum } from '@medentee/enums'
import { ROUTES } from 'routes/constants'

export const getUserProfileUrl = ({ id, typeId }: { id: string; typeId: AccountTypeIdEnum }) => {
  if (typeId === AccountTypeIdEnum.BUSINESS) {
    return `${ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS}/${id}`
  }

  return `${ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS}/${id}`
}

export const getUsersUrl = (accountType?: AccountTypeIdEnum) =>
  accountType === AccountTypeIdEnum.BUSINESS
    ? ROUTES.USER_MANAGEMENT.BUSINESS_ACCOUNTS
    : ROUTES.USER_MANAGEMENT.PERSONAL_ACCOUNTS
